import {
  Heading,
  Text,
  Box,
  Button,
  Image,
  useMultiStyleConfig,
} from '@chakra-ui/react';

type RequestPageBuildCardProps = {
  variant?: 'horizontal' | 'vertical';
};

const RequestPageBuildCard = ({ variant }: RequestPageBuildCardProps) => {
  const styles = useMultiStyleConfig('RequestPageBuildCard', {
    variant,
  });
  const isFirstTimeUser = variant === 'horizontal';

  return (
    <Box sx={styles.container}>
      <Heading sx={styles.title}>
        Request a{' '}
        <Text as="span" sx={styles.highlight}>
          FREE
        </Text>{' '}
        page build
      </Heading>
      <Text sx={styles.description}>We'll do the work for you</Text>
      <Button
        sx={styles.button}
        variant="outline"
        as="a"
        target="_blank"
        data-track-event="click_request_free_page_build"
        data-track-parameter-name="user_type"
        data-track-parameter-value={
          isFirstTimeUser ? 'first_time_user' : 'afterwards_user'
        }
        href="https://www.layoutbase.com/build-my-page"
      >
        Request
      </Button>
      <Image
        sx={styles.image}
        src={`${process.env.PUBLIC_URL || '/'}image/img_page_build.png`}
      />
    </Box>
  );
};

export default RequestPageBuildCard;
