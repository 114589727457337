import * as R from 'ramda';

export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || 'http://localhost:4000';
export const CHAT_INTERVIEW_SUBMISSIONS_WEBHOOK_URL =
  process.env.REACT_APP_CHAT_INTERVIEW_SUBMISSIONS_WEBHOOK_URL ||
  'https://chat.googleapis.com/v1/spaces/AAAAwEuX6ms/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=EqsIlhzuNNSFjC_XOZQ4wZk-IGXPn8gYUtzI5WUNLX4%3D';

export const DEFAULT_DATE = new Date();

export enum DATE_FORMAT {
  DATE = 'yyyy-MM-dd',
  DATETIME = 'yyyy-MM-dd HH:mm:ss',
  DATETIME_WITHOUT_SECOND = 'yyyy-MM-dd HH:mm',
  DATE_WITH_DAY = 'yyyy-MM-dd (EEEE)',
  DATETIME_WITH_TIMEZONE_WITHOUT_SECOND = 'yyyy-MM-dd HH:mm (OOO)',
}

export const HK_TIME_ZONE = 'Asia/Hong_Kong';

export enum HOUR {
  '00:00' = '0',
  '01:00' = '1',
  '02:00' = '2',
  '03:00' = '3',
  '04:00' = '4',
  '05:00' = '5',
  '06:00' = '6',
  '07:00' = '7',
  '08:00' = '8',
  '09:00' = '9',
  '10:00' = '10',
  '11:00' = '11',
  '12:00' = '12',
  '13:00' = '13',
  '14:00' = '14',
  '15:00' = '15',
  '16:00' = '16',
  '17:00' = '17',
  '18:00' = '18',
  '19:00' = '19',
  '20:00' = '20',
  '21:00' = '21',
  '22:00' = '22',
  '23:00' = '23',
}

export const HOUR_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(HOUR));

/**
 * Minimum eight characters, at least one letter, one number and one special character
 */
export const passwordReg =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
/**
 * Only supports lowercase letters (a-z), numbers (0-9), dashes (-), underscores (_), Mandarin, Korean or Japanese characters
 */
export const SPECIFIC_URL_REGEX = /"|'|\(|\)|\[|\]+/g;
export const INVALID_URL_REGEX =
  /[^a-z0-9_-\u4e00-\u9fa5\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3130-\u318F\uAC00-\uD7AF]+/g;
export const MORE_THAN_ONE_DASH_REGEX = /-+/g;
export const BOTH_ENDS_DASH_REGEX = /^-+|-+$/g;
export const SHOPIFY_ORGANIZATION_ID = '2105841';
export const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-KDHJXS7';
export const GTM_AUTH =
  process.env.REACT_APP_GTM_AUTH || 'psrHLNrfnmQin7_XDKOtEA';
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW || 'env-5';
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || 3034024;
export const MIXPANEL_TOKEN =
  process.env.REACT_APP_MIXPANEL_TOKEN || '3d29c5cb0727b836dca7f5aecab21ac4';
