import axios from 'axios';

export const sendMessageToGoogleChat = (url: string, message: string) => {
  axios({
    method: 'POST',
    url,
    data: {
      text: message,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
