import { Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { GiftCardIcon } from './Icons';

type TeaserTriggerProps = {
  show: boolean;
  onClick: () => void;
};

const TeaserTrigger = ({ show, onClick }: TeaserTriggerProps) => {
  if (!show) return null;
  return (
    <Center
      zIndex="modal"
      pos="fixed"
      bottom="120px"
      right="0"
      w="38px"
      h="196px"
      flexDirection="column"
      bgColor="black"
      cursor="pointer"
      borderLeftRadius="4px"
      data-track-event="click_gift_card_teaser"
      onClick={onClick}
    >
      <Text
        maxH="full"
        fontSize="12px"
        fontWeight={600}
        color="white"
        transform="rotate(180deg)"
        style={{
          writingMode: 'vertical-lr',
          textOrientation: 'mixed',
        }}
      >
        US$ 20 Amazon Gift Card
      </Text>
      <Icon mt="4px" as={GiftCardIcon} boxSize="30px" />
    </Center>
  );
};

export default TeaserTrigger;
