import { Box, Button, Flex, Icon, HStack } from '@chakra-ui/react';
import { CloseButton } from './CloseButton';
import { GiftCardIcon } from './Icons';

type PromoGiftCardProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const PromoGiftCard = ({ show, onClose, onSubmit }: PromoGiftCardProps) => {
  if (!show) return null;
  return (
    <Box
      w="320px"
      h="267px"
      pos="relative"
      borderRadius="8px"
      boxShadow=" 0 2px 16px 0 rgba(0, 0, 0, 0.1)"
      color="white"
      backgroundImage="linear-gradient(to bottom, #7773d9, #3d37ca)"
    >
      <CloseButton
        color="white"
        top="6px"
        right="6px"
        data-track-event="click_close_icon"
        data-track-parameter-name="placement"
        data-track-parameter-value="dashboard_promo_card"
        onClick={onClose}
      />
      <Box m="24px 0 16px 24px">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" fontWeight="bold">
            <Box fontSize="24px">Get</Box>
            <HStack
              spacing="8px"
              h="54px"
              lineHeight="54px"
              alignItems="baseline"
            >
              <Box fontSize="30px">US$</Box>
              <Box fontSize="60px">20</Box>
            </HStack>
            <Box mt="10px" fontWeight={600}>
              Amazon Gift Card
            </Box>
          </Flex>
          <Icon as={GiftCardIcon} boxSize="140px" />
        </Flex>
        <Box fontSize="14px">Participate in a 10-min user interview</Box>
      </Box>
      <Box mx="24px">
        <Button
          variant="outline"
          minW="100%"
          color="white"
          borderColor="white"
          data-track-event="click_sign_up_now"
          data-track-parameter-name="placement"
          data-track-parameter-value="dashboard_promo_card"
          _hover={{
            bgColor: 'rgba(255, 255, 255, 0.1)',
          }}
          _active={{
            bgColor: 'rgba(255, 255, 255, 0.3)',
          }}
          onClick={onSubmit}
        >
          Sign Up Now
        </Button>
      </Box>
    </Box>
  );
};

export default PromoGiftCard;
