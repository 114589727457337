import { createMachine, ActorRefFrom, send, assign, spawn } from 'xstate';
import * as R from 'ramda';
import {
  Collection,
  PageFrom,
  PageType,
  Product,
  Blog,
} from '../apollo/types.generate';
import { pagesMachines, PAGE_EVENTS } from './pages/pagesMachine';
import { sdk } from '../apollo/client';
import { history } from '../history';

enum E {
  BACK = 'BACK',
  NEXT = 'NEXT',
  CHOOSE_FROM = 'CHOOSE_FROM',
  CHOOSE_EXISTING_PAGE = 'CHOOSE_EXISTING_PAGE',
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
  CHOOSE_SHOPIFY_ITEM = 'CHOOSE_SHOPIFY_ITEM',
}
enum S {
  CHOOSING_TYPE = 'CHOOSING_TYPE',
  FILLING_INFO = 'FILLING_INFO',
  FILLING_POST_INFO = 'FILLING_POST_INFO',
  CHOOSING_SHOPIFY_ITEM = 'CHOOSING_SHOPIFY_ITEM',
  CHOOSING_FROM = 'CHOOSING_FROM',
  CHOOSING_EXISTING_PAGE = 'CHOOSING_EXISTING_PAGE',
  IDLE = 'IDLE',
  PENDING = 'PENDING',
}

export type CreatePageContext = {
  pagesRef: ActorRefFrom<typeof pagesMachines>;
  type?: PageType;
  title?: string;
  handle?: string;
  from?: PageFrom;
  pageId?: string;
  itemsCount?: number;
  items: Array<Product> | Array<Collection> | Array<Blog>;
  item?: Product | Collection | Blog;
};

export type CreatePageEvent =
  | { type: E.BACK }
  | { type: E.NEXT }
  | { type: E.NEXT; data: { type: PageType } }
  | { type: E.NEXT; data: { title: string; handle: string } }
  | { type: E.NEXT; data: { title: string; handle: string; item: Blog } }
  | { type: E.CHOOSE_FROM; data: { from: PageFrom } }
  | { type: E.CHOOSE_SHOPIFY_ITEM; data: { item: Product | Collection } }
  | { type: E.CHOOSE_EXISTING_PAGE; data: { pageId: string } }
  | { type: E.SUBMIT }
  | { type: E.RESET };

export type CreatePageState =
  | {
      value: { values: S.CHOOSING_TYPE };
      context: CreatePageContext;
    }
  | {
      value: { values: S.FILLING_INFO };
      context: CreatePageContext & Required<Pick<CreatePageContext, 'type'>>;
    }
  | {
      value: { values: S.CHOOSING_SHOPIFY_ITEM };
      context: CreatePageContext & Required<Pick<CreatePageContext, 'type'>>;
    }
  | {
      value: { values: S.FILLING_POST_INFO };
      context: CreatePageContext & Required<Pick<CreatePageContext, 'type'>>;
    }
  | {
      value: { values: S.CHOOSING_FROM };
      context: CreatePageContext &
        Required<Pick<CreatePageContext, 'type' | 'title' | 'handle'>>;
    }
  | {
      value: { values: S.CHOOSING_FROM; submit: S.PENDING };
      context: CreatePageContext &
        Required<Pick<CreatePageContext, 'type' | 'title' | 'handle' | 'from'>>;
    }
  | {
      value: { values: S.CHOOSING_EXISTING_PAGE };
      context: CreatePageContext &
        Required<
          Pick<
            CreatePageContext,
            'type' | 'title' | 'handle' | 'from' | 'pagesRef'
          >
        >;
    }
  | {
      value: { values: S.CHOOSING_EXISTING_PAGE; submit: S.PENDING };
      context: CreatePageContext & Required<CreatePageContext>;
    };

const createPageMachine = createMachine<
  CreatePageContext,
  CreatePageEvent,
  CreatePageState
>(
  {
    id: 'createTemplate',
    type: 'parallel',
    states: {
      values: {
        initial: S.CHOOSING_TYPE,
        entry: ['spawnPagesMachine'],
        states: {
          [S.CHOOSING_TYPE]: {
            on: {
              [E.NEXT]: [
                {
                  cond: 'isAssignRegularType',
                  target: S.FILLING_INFO,
                  actions: ['assignType'],
                },
                {
                  cond: 'isAssignPostType',
                  target: S.FILLING_POST_INFO,
                  actions: ['assignType'],
                },
                {
                  target: S.CHOOSING_SHOPIFY_ITEM,
                  actions: ['assignType'],
                },
              ],
            },
          },
          [S.FILLING_INFO]: {
            on: {
              [E.BACK]: {
                target: S.CHOOSING_TYPE,
                actions: ['clearInfo'],
              },
              [E.NEXT]: {
                target: S.CHOOSING_FROM,
                actions: ['assignInfo'],
              },
            },
          },
          [S.CHOOSING_SHOPIFY_ITEM]: {
            id: S.CHOOSING_SHOPIFY_ITEM,
            on: {
              [E.BACK]: {
                target: S.CHOOSING_TYPE,
                actions: ['clearShopifyItem'],
              },
              [E.CHOOSE_SHOPIFY_ITEM]: {
                actions: ['assignShopifyItem'],
                target: S.CHOOSING_FROM,
              },
            },
          },
          [S.FILLING_POST_INFO]: {
            invoke: {
              src: 'getShopifyBlogs',
              onDone: {
                actions: ['assignShopifyItems'],
              },
            },
            on: {
              [E.BACK]: {
                target: S.CHOOSING_TYPE,
                actions: ['clearInfo', 'clearItem', 'clearItems'],
              },
              [E.CHOOSE_SHOPIFY_ITEM]: {
                actions: ['assignShopifyItem'],
              },
              [E.NEXT]: {
                target: S.CHOOSING_FROM,
                actions: ['assignInfo'],
              },
            },
          },
          [S.CHOOSING_FROM]: {
            id: S.CHOOSING_FROM,
            on: {
              [E.BACK]: [
                {
                  cond: 'isRegularType',
                  target: S.FILLING_INFO,
                  actions: ['clearFrom'],
                },
                {
                  cond: 'isPostType',
                  target: S.FILLING_POST_INFO,
                  actions: ['clearFrom'],
                },
                {
                  target: S.CHOOSING_SHOPIFY_ITEM,
                  actions: ['clearFrom'],
                },
              ],
              [E.CHOOSE_FROM]: { actions: ['assignFrom'] },
              [E.NEXT]: [
                { cond: 'isFromExtend', target: S.CHOOSING_EXISTING_PAGE },
                { cond: 'hasFrom', actions: [send({ type: E.SUBMIT })] },
              ],
            },
          },
          [S.CHOOSING_EXISTING_PAGE]: {
            entry: ['refetchPages'],
            on: {
              [E.BACK]: S.CHOOSING_FROM,
              [E.CHOOSE_EXISTING_PAGE]: {
                actions: ['assignPageId', send({ type: E.SUBMIT })],
              },
            },
          },
        },
        on: {
          [E.RESET]: {
            actions: ['reset'],
            target: `.${S.CHOOSING_TYPE}`,
          },
        },
      },
      submit: {
        initial: S.IDLE,
        states: {
          [S.IDLE]: {
            on: {
              [E.SUBMIT]: S.PENDING,
            },
          },
          [S.PENDING]: {
            invoke: {
              src: 'submit',
              onDone: {
                target: S.IDLE,
                actions: ['closeModal'],
              },
              onError: {
                target: S.IDLE,
                actions: ['notifySubmitError'],
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      hasFrom: (ctx) => !!ctx.from,
      isFromExtend: (ctx) => ctx.from === PageFrom.Extend,
      isFromBlank: (ctx) => ctx.from === PageFrom.Blank,
      isRegularType: (ctx) => ctx.type === PageType.Regular,
      isPostType: (ctx) => ctx.type === PageType.Post,
      isAssignRegularType: (ctx, event) =>
        R.pathEq(['data', 'type'], PageType.Regular)(event),
      isAssignPostType: (ctx, event) =>
        R.pathEq(['data', 'type'], PageType.Post)(event),
    },
    actions: {
      refetchPages: send(
        (ctx) => ({
          type: PAGE_EVENTS.SEARCH,
          disabledFromShopify:
            ctx.type === PageType.Regular || ctx.type === PageType.Post,
        }),
        { to: (ctx) => ctx.pagesRef }
      ),
      assignType: assign({
        type: (ctx, event) => R.pathOr(undefined, ['data', 'type'])(event),
      }),
      assignFrom: assign({
        from: (ctx, event) => R.pathOr(undefined, ['data', 'from'])(event),
      }),
      assignInfo: assign({
        title: (ctx, event) => R.pathOr('', ['data', 'title'])(event),
        handle: (ctx, event) => R.pathOr(undefined, ['data', 'handle'])(event),
      }),
      assignPageId: assign({
        pageId: (ctx, event) => R.pathOr(undefined, ['data', 'pageId'])(event),
      }),
      assignShopifyItems: assign({
        items: (ctx, event) => R.pathOr([], ['data'])(event),
      }),
      assignShopifyItem: assign({
        item: (ctx, event) => R.pathOr(undefined, ['data', 'item'])(event),
      }),
      reset: assign<CreatePageContext>({
        type: undefined,
        title: undefined,
        handle: undefined,
        from: undefined,
        pageId: undefined,
        items: [],
        item: undefined,
        itemsCount: 0,
      }),
      clearInfo: assign<CreatePageContext>({
        title: undefined,
        handle: undefined,
      }),
      clearItems: assign({
        items: (_) => [],
        itemsCount: (_) => 0,
      }),
      clearItem: assign<CreatePageContext>({
        item: undefined,
      }),
      clearFrom: assign<CreatePageContext>({
        from: undefined,
      }),
      clearShopifyItem: assign<CreatePageContext>({
        item: undefined,
      }),
      spawnPagesMachine: assign({
        pagesRef: () =>
          spawn(
            pagesMachines.withConfig({
              services: {
                getPages: (ctx) => {
                  return sdk
                    .pages({
                      keyword: ctx.search,
                      take: 12,
                      pageType: ctx.pageType,
                      cursorId: ctx.cursorId,
                      disabledFromShopify: ctx.disabledFromShopify,
                    })
                    .then((res) => res.pages);
                },
              },
            })
          ),
      }),
    },
    services: {
      getShopifyBlogs: (ctx) =>
        sdk.shopifyBlogs().then((res) => res.shopifyBlogs),
      submit: async (ctx) => {
        const { type, from, title, item, handle, pageId } = ctx;
        const params = {
          from,
          title:
            item && R.includes(type, [PageType.Collection, PageType.Product])
              ? item.title
              : title,
          type,
          handle: R.includes(type, [PageType.Regular, PageType.Post])
            ? handle
            : item?.handle,
          pageId,
        } as any;

        if (params.title === undefined || params.handle === undefined) {
          return Promise.reject('params error');
        }

        const paramsByType =
          type === PageType.Product
            ? {
                shopifyProductId: item?.id,
              }
            : type === PageType.Collection
            ? {
                shopifyCollectionId: item?.id,
              }
            : type === PageType.Post
            ? {
                shopifyBlogId: item?.id,
              }
            : {};

        return sdk
          .createPage({
            input: {
              ...params,
              ...paramsByType,
            },
          })
          .then(({ createPage }) => {
            history.push(`/pages/${createPage?.id}`);
          });
      },
    },
  }
);

export { createPageMachine, S as CREATE_PAGE_STATES, E as CREATE_PAGE_EVENTS };
