import * as R from 'ramda';
import { Link as RouteLink, useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  IconButton,
  Avatar,
  Text,
  useDisclosure,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { RiQuestionLine, RiSettingsLine } from 'react-icons/ri';
import { useLogoutMutation, useUserQuery } from '../apollo/apollo.generate';
import SwitchSiteDrawer from '../components/SwitchSiteDrawer';
import { LIVE_SUPPORT_LINK } from '../pages/editor/constants/links';
import { FullLogo } from '../components/Logo';

const Header = () => {
  const history = useHistory();
  const { data: userData } = useUserQuery({
    fetchPolicy: 'network-only',
  });
  const [logout] = useLogoutMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box w="full" pos="sticky" top="0" zIndex="sticky" bgColor="white">
        <Flex
          w="full"
          h="74px"
          px="24px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Link as={RouteLink} to="/">
              <FullLogo />
            </Link>
            {/* {restTrialDays !== 0 && (
              <>
                <Text ml="32px">{restTrialDays} days trial left</Text>
                {!isActive && (
                  <Button ml="16px" variant="outline" h="32px" p="0 16px">
                    <RouteLink to="/shopify-charge">Choose Plan</RouteLink>
                  </Button>
                )}
              </>
            )} */}
          </Flex>

          <Flex alignItems="center">
            <Flex gap="16px">
              <Button
                as={Link}
                variant="solidSecondary"
                size="sm"
                href={LIVE_SUPPORT_LINK}
                data-track-event="click_get_demo"
                isExternal
              >
                Get a Demo
              </Button>
              <Tooltip label="Support Center">
                <Link
                  rel="noreferrer noopener"
                  href="https://layoutbase.helpdocs.io/"
                  isExternal
                >
                  <IconButton
                    variant="icon"
                    size="icon-md"
                    icon={<RiQuestionLine />}
                    aria-label="link"
                  />
                </Link>
              </Tooltip>
              <Menu isLazy>
                <MenuButton
                  as={IconButton}
                  variant="icon"
                  size="icon-md"
                  icon={<RiSettingsLine />}
                  aria-label="setting"
                />
                <MenuList>
                  <MenuItem as={RouteLink} to="/account">
                    Account
                  </MenuItem>
                  <MenuItem onClick={onOpen}>Switch Site</MenuItem>
                  {/* <MenuItem as={RouteLink} to="/shopify-charge">
                    Pricing
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      logout().then(() => {
                        history.push('/login');
                      });
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>

            <Flex ml="16px" direction="column" align="flex-end">
              <Text maxW="150px" color="#000" isTruncated>
                {userData?.user.name}
              </Text>
              <Text maxW="150px" fontSize="12px" isTruncated>
                {userData?.user.currentStore}
              </Text>
            </Flex>
            <Avatar
              display="flex"
              ml="8px"
              w="36px"
              h="36px"
              name={R.head(R.pathOr('', ['user', 'firstName'], userData))}
              color="text"
              bgColor="gray.200"
            />
          </Flex>
        </Flex>
      </Box>
      <SwitchSiteDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Header;
