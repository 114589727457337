// THIS IS A GENERATED FILE
import * as Operations from './operations.generate';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}


/**
 * __useInterviewFormQuery__
 *
 * To run a query within a React component, call `useInterviewFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewFormQuery(baseOptions?: Apollo.QueryHookOptions<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>(Operations.InterviewFormDocument, options);
      }
export function useInterviewFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>(Operations.InterviewFormDocument, options);
        }
export type InterviewFormQueryHookResult = ReturnType<typeof useInterviewFormQuery>;
export type InterviewFormLazyQueryHookResult = ReturnType<typeof useInterviewFormLazyQuery>;
export type InterviewFormQueryResult = Apollo.QueryResult<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>;
export type ShowGiftTeaserMutationFn = Apollo.MutationFunction<Operations.ShowGiftTeaserMutation, Operations.ShowGiftTeaserMutationVariables>;

/**
 * __useShowGiftTeaserMutation__
 *
 * To run a mutation, you first call `useShowGiftTeaserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShowGiftTeaserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [showGiftTeaserMutation, { data, loading, error }] = useShowGiftTeaserMutation({
 *   variables: {
 *   },
 * });
 */
export function useShowGiftTeaserMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ShowGiftTeaserMutation, Operations.ShowGiftTeaserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ShowGiftTeaserMutation, Operations.ShowGiftTeaserMutationVariables>(Operations.ShowGiftTeaserDocument, options);
      }
export type ShowGiftTeaserMutationHookResult = ReturnType<typeof useShowGiftTeaserMutation>;
export type ShowGiftTeaserMutationResult = Apollo.MutationResult<Operations.ShowGiftTeaserMutation>;
export type ShowGiftTeaserMutationOptions = Apollo.BaseMutationOptions<Operations.ShowGiftTeaserMutation, Operations.ShowGiftTeaserMutationVariables>;
export type SaveInterviewFormMutationFn = Apollo.MutationFunction<Operations.SaveInterviewFormMutation, Operations.SaveInterviewFormMutationVariables>;

/**
 * __useSaveInterviewFormMutation__
 *
 * To run a mutation, you first call `useSaveInterviewFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInterviewFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInterviewFormMutation, { data, loading, error }] = useSaveInterviewFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveInterviewFormMutation(baseOptions?: Apollo.MutationHookOptions<Operations.SaveInterviewFormMutation, Operations.SaveInterviewFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.SaveInterviewFormMutation, Operations.SaveInterviewFormMutationVariables>(Operations.SaveInterviewFormDocument, options);
      }
export type SaveInterviewFormMutationHookResult = ReturnType<typeof useSaveInterviewFormMutation>;
export type SaveInterviewFormMutationResult = Apollo.MutationResult<Operations.SaveInterviewFormMutation>;
export type SaveInterviewFormMutationOptions = Apollo.BaseMutationOptions<Operations.SaveInterviewFormMutation, Operations.SaveInterviewFormMutationVariables>;

/**
 * __useGetImagesQuery__
 *
 * To run a query within a React component, call `useGetImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImagesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      cursorId: // value for 'cursorId'
 *   },
 * });
 */
export function useGetImagesQuery(baseOptions?: Apollo.QueryHookOptions<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>(Operations.GetImagesDocument, options);
      }
export function useGetImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>(Operations.GetImagesDocument, options);
        }
export type GetImagesQueryHookResult = ReturnType<typeof useGetImagesQuery>;
export type GetImagesLazyQueryHookResult = ReturnType<typeof useGetImagesLazyQuery>;
export type GetImagesQueryResult = Apollo.QueryResult<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>;
export type UploadImageMutationFn = Apollo.MutationFunction<Operations.UploadImageMutation, Operations.UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.UploadImageMutation, Operations.UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.UploadImageMutation, Operations.UploadImageMutationVariables>(Operations.UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<Operations.UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<Operations.UploadImageMutation, Operations.UploadImageMutationVariables>;
export type ShopifyLoginMutationFn = Apollo.MutationFunction<Operations.ShopifyLoginMutation, Operations.ShopifyLoginMutationVariables>;

/**
 * __useShopifyLoginMutation__
 *
 * To run a mutation, you first call `useShopifyLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyLoginMutation, { data, loading, error }] = useShopifyLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyLoginMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ShopifyLoginMutation, Operations.ShopifyLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ShopifyLoginMutation, Operations.ShopifyLoginMutationVariables>(Operations.ShopifyLoginDocument, options);
      }
export type ShopifyLoginMutationHookResult = ReturnType<typeof useShopifyLoginMutation>;
export type ShopifyLoginMutationResult = Apollo.MutationResult<Operations.ShopifyLoginMutation>;
export type ShopifyLoginMutationOptions = Apollo.BaseMutationOptions<Operations.ShopifyLoginMutation, Operations.ShopifyLoginMutationVariables>;
export type ShopifyInstallMutationFn = Apollo.MutationFunction<Operations.ShopifyInstallMutation, Operations.ShopifyInstallMutationVariables>;

/**
 * __useShopifyInstallMutation__
 *
 * To run a mutation, you first call `useShopifyInstallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyInstallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyInstallMutation, { data, loading, error }] = useShopifyInstallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyInstallMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ShopifyInstallMutation, Operations.ShopifyInstallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ShopifyInstallMutation, Operations.ShopifyInstallMutationVariables>(Operations.ShopifyInstallDocument, options);
      }
export type ShopifyInstallMutationHookResult = ReturnType<typeof useShopifyInstallMutation>;
export type ShopifyInstallMutationResult = Apollo.MutationResult<Operations.ShopifyInstallMutation>;
export type ShopifyInstallMutationOptions = Apollo.BaseMutationOptions<Operations.ShopifyInstallMutation, Operations.ShopifyInstallMutationVariables>;
export type LoginMutationFn = Apollo.MutationFunction<Operations.LoginMutation, Operations.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Operations.LoginMutation, Operations.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.LoginMutation, Operations.LoginMutationVariables>(Operations.LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Operations.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Operations.LoginMutation, Operations.LoginMutationVariables>;
export type SetPasswordMutationFn = Apollo.MutationFunction<Operations.SetPasswordMutation, Operations.SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operations.SetPasswordMutation, Operations.SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.SetPasswordMutation, Operations.SetPasswordMutationVariables>(Operations.SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<Operations.SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<Operations.SetPasswordMutation, Operations.SetPasswordMutationVariables>;
export type LogoutMutationFn = Apollo.MutationFunction<Operations.LogoutMutation, Operations.LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<Operations.LogoutMutation, Operations.LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.LogoutMutation, Operations.LogoutMutationVariables>(Operations.LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<Operations.LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<Operations.LogoutMutation, Operations.LogoutMutationVariables>;
export type CreateShopifyWebhookMutationFn = Apollo.MutationFunction<Operations.CreateShopifyWebhookMutation, Operations.CreateShopifyWebhookMutationVariables>;

/**
 * __useCreateShopifyWebhookMutation__
 *
 * To run a mutation, you first call `useCreateShopifyWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopifyWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopifyWebhookMutation, { data, loading, error }] = useCreateShopifyWebhookMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateShopifyWebhookMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CreateShopifyWebhookMutation, Operations.CreateShopifyWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CreateShopifyWebhookMutation, Operations.CreateShopifyWebhookMutationVariables>(Operations.CreateShopifyWebhookDocument, options);
      }
export type CreateShopifyWebhookMutationHookResult = ReturnType<typeof useCreateShopifyWebhookMutation>;
export type CreateShopifyWebhookMutationResult = Apollo.MutationResult<Operations.CreateShopifyWebhookMutation>;
export type CreateShopifyWebhookMutationOptions = Apollo.BaseMutationOptions<Operations.CreateShopifyWebhookMutation, Operations.CreateShopifyWebhookMutationVariables>;

/**
 * __useVerifyEmailQuery__
 *
 * To run a query within a React component, call `useVerifyEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyEmailQuery(baseOptions: Apollo.QueryHookOptions<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>(Operations.VerifyEmailDocument, options);
      }
export function useVerifyEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>(Operations.VerifyEmailDocument, options);
        }
export type VerifyEmailQueryHookResult = ReturnType<typeof useVerifyEmailQuery>;
export type VerifyEmailLazyQueryHookResult = ReturnType<typeof useVerifyEmailLazyQuery>;
export type VerifyEmailQueryResult = Apollo.QueryResult<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>;

/**
 * __useVerifyResetPasswordTokenQuery__
 *
 * To run a query within a React component, call `useVerifyResetPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyResetPasswordTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyResetPasswordTokenQuery(baseOptions: Apollo.QueryHookOptions<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>(Operations.VerifyResetPasswordTokenDocument, options);
      }
export function useVerifyResetPasswordTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>(Operations.VerifyResetPasswordTokenDocument, options);
        }
export type VerifyResetPasswordTokenQueryHookResult = ReturnType<typeof useVerifyResetPasswordTokenQuery>;
export type VerifyResetPasswordTokenLazyQueryHookResult = ReturnType<typeof useVerifyResetPasswordTokenLazyQuery>;
export type VerifyResetPasswordTokenQueryResult = Apollo.QueryResult<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<Operations.ForgotPasswordMutation, Operations.ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ForgotPasswordMutation, Operations.ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ForgotPasswordMutation, Operations.ForgotPasswordMutationVariables>(Operations.ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<Operations.ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<Operations.ForgotPasswordMutation, Operations.ForgotPasswordMutationVariables>;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>(Operations.ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Operations.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>;
export type CreatePageMutationFn = Apollo.MutationFunction<Operations.CreatePageMutation, Operations.CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CreatePageMutation, Operations.CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CreatePageMutation, Operations.CreatePageMutationVariables>(Operations.CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<Operations.CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<Operations.CreatePageMutation, Operations.CreatePageMutationVariables>;
export type DeletePageMutationFn = Apollo.MutationFunction<Operations.DeletePageMutation, Operations.DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.DeletePageMutation, Operations.DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.DeletePageMutation, Operations.DeletePageMutationVariables>(Operations.DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<Operations.DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<Operations.DeletePageMutation, Operations.DeletePageMutationVariables>;
export type SavePageMutationFn = Apollo.MutationFunction<Operations.SavePageMutation, Operations.SavePageMutationVariables>;

/**
 * __useSavePageMutation__
 *
 * To run a mutation, you first call `useSavePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePageMutation, { data, loading, error }] = useSavePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.SavePageMutation, Operations.SavePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.SavePageMutation, Operations.SavePageMutationVariables>(Operations.SavePageDocument, options);
      }
export type SavePageMutationHookResult = ReturnType<typeof useSavePageMutation>;
export type SavePageMutationResult = Apollo.MutationResult<Operations.SavePageMutation>;
export type SavePageMutationOptions = Apollo.BaseMutationOptions<Operations.SavePageMutation, Operations.SavePageMutationVariables>;
export type RevertPageMutationFn = Apollo.MutationFunction<Operations.RevertPageMutation, Operations.RevertPageMutationVariables>;

/**
 * __useRevertPageMutation__
 *
 * To run a mutation, you first call `useRevertPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertPageMutation, { data, loading, error }] = useRevertPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertPageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.RevertPageMutation, Operations.RevertPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.RevertPageMutation, Operations.RevertPageMutationVariables>(Operations.RevertPageDocument, options);
      }
export type RevertPageMutationHookResult = ReturnType<typeof useRevertPageMutation>;
export type RevertPageMutationResult = Apollo.MutationResult<Operations.RevertPageMutation>;
export type RevertPageMutationOptions = Apollo.BaseMutationOptions<Operations.RevertPageMutation, Operations.RevertPageMutationVariables>;

/**
 * __useVerifyHandleQuery__
 *
 * To run a query within a React component, call `useVerifyHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyHandleQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useVerifyHandleQuery(baseOptions: Apollo.QueryHookOptions<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>(Operations.VerifyHandleDocument, options);
      }
export function useVerifyHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>(Operations.VerifyHandleDocument, options);
        }
export type VerifyHandleQueryHookResult = ReturnType<typeof useVerifyHandleQuery>;
export type VerifyHandleLazyQueryHookResult = ReturnType<typeof useVerifyHandleLazyQuery>;
export type VerifyHandleQueryResult = Apollo.QueryResult<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>;
export type UpdateHomepageMutationFn = Apollo.MutationFunction<Operations.UpdateHomepageMutation, Operations.UpdateHomepageMutationVariables>;

/**
 * __useUpdateHomepageMutation__
 *
 * To run a mutation, you first call `useUpdateHomepageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHomepageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHomepageMutation, { data, loading, error }] = useUpdateHomepageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHomepageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.UpdateHomepageMutation, Operations.UpdateHomepageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.UpdateHomepageMutation, Operations.UpdateHomepageMutationVariables>(Operations.UpdateHomepageDocument, options);
      }
export type UpdateHomepageMutationHookResult = ReturnType<typeof useUpdateHomepageMutation>;
export type UpdateHomepageMutationResult = Apollo.MutationResult<Operations.UpdateHomepageMutation>;
export type UpdateHomepageMutationOptions = Apollo.BaseMutationOptions<Operations.UpdateHomepageMutation, Operations.UpdateHomepageMutationVariables>;
export type UpdatePageSettingsMutationFn = Apollo.MutationFunction<Operations.UpdatePageSettingsMutation, Operations.UpdatePageSettingsMutationVariables>;

/**
 * __useUpdatePageSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePageSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageSettingsMutation, { data, loading, error }] = useUpdatePageSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Operations.UpdatePageSettingsMutation, Operations.UpdatePageSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.UpdatePageSettingsMutation, Operations.UpdatePageSettingsMutationVariables>(Operations.UpdatePageSettingsDocument, options);
      }
export type UpdatePageSettingsMutationHookResult = ReturnType<typeof useUpdatePageSettingsMutation>;
export type UpdatePageSettingsMutationResult = Apollo.MutationResult<Operations.UpdatePageSettingsMutation>;
export type UpdatePageSettingsMutationOptions = Apollo.BaseMutationOptions<Operations.UpdatePageSettingsMutation, Operations.UpdatePageSettingsMutationVariables>;

/**
 * __usePagesQuery__
 *
 * To run a query within a React component, call `usePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      take: // value for 'take'
 *      pageType: // value for 'pageType'
 *      cursorId: // value for 'cursorId'
 *      disabledFromShopify: // value for 'disabledFromShopify'
 *   },
 * });
 */
export function usePagesQuery(baseOptions?: Apollo.QueryHookOptions<Operations.PagesQuery, Operations.PagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.PagesQuery, Operations.PagesQueryVariables>(Operations.PagesDocument, options);
      }
export function usePagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.PagesQuery, Operations.PagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.PagesQuery, Operations.PagesQueryVariables>(Operations.PagesDocument, options);
        }
export type PagesQueryHookResult = ReturnType<typeof usePagesQuery>;
export type PagesLazyQueryHookResult = ReturnType<typeof usePagesLazyQuery>;
export type PagesQueryResult = Apollo.QueryResult<Operations.PagesQuery, Operations.PagesQueryVariables>;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<Operations.PageQuery, Operations.PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.PageQuery, Operations.PageQueryVariables>(Operations.PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.PageQuery, Operations.PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.PageQuery, Operations.PageQueryVariables>(Operations.PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<Operations.PageQuery, Operations.PageQueryVariables>;

/**
 * __usePageVersionsQuery__
 *
 * To run a query within a React component, call `usePageVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageVersionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageVersionsQuery(baseOptions: Apollo.QueryHookOptions<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>(Operations.PageVersionsDocument, options);
      }
export function usePageVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>(Operations.PageVersionsDocument, options);
        }
export type PageVersionsQueryHookResult = ReturnType<typeof usePageVersionsQuery>;
export type PageVersionsLazyQueryHookResult = ReturnType<typeof usePageVersionsLazyQuery>;
export type PageVersionsQueryResult = Apollo.QueryResult<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>;

/**
 * __usePageVersionQuery__
 *
 * To run a query within a React component, call `usePageVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageVersionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageVersionQuery(baseOptions: Apollo.QueryHookOptions<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>(Operations.PageVersionDocument, options);
      }
export function usePageVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>(Operations.PageVersionDocument, options);
        }
export type PageVersionQueryHookResult = ReturnType<typeof usePageVersionQuery>;
export type PageVersionLazyQueryHookResult = ReturnType<typeof usePageVersionLazyQuery>;
export type PageVersionQueryResult = Apollo.QueryResult<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>;

/**
 * __useDamQuery__
 *
 * To run a query within a React component, call `useDamQuery` and pass it any options that fit your needs.
 * When your component renders, `useDamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDamQuery({
 *   variables: {
 *   },
 * });
 */
export function useDamQuery(baseOptions?: Apollo.QueryHookOptions<Operations.DamQuery, Operations.DamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.DamQuery, Operations.DamQueryVariables>(Operations.DamDocument, options);
      }
export function useDamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.DamQuery, Operations.DamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.DamQuery, Operations.DamQueryVariables>(Operations.DamDocument, options);
        }
export type DamQueryHookResult = ReturnType<typeof useDamQuery>;
export type DamLazyQueryHookResult = ReturnType<typeof useDamLazyQuery>;
export type DamQueryResult = Apollo.QueryResult<Operations.DamQuery, Operations.DamQueryVariables>;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<Operations.UserQuery, Operations.UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.UserQuery, Operations.UserQueryVariables>(Operations.UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.UserQuery, Operations.UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.UserQuery, Operations.UserQueryVariables>(Operations.UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<Operations.UserQuery, Operations.UserQueryVariables>;

/**
 * __useShopifyProductsQuery__
 *
 * To run a query within a React component, call `useShopifyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyProductsQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>(Operations.ShopifyProductsDocument, options);
      }
export function useShopifyProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>(Operations.ShopifyProductsDocument, options);
        }
export type ShopifyProductsQueryHookResult = ReturnType<typeof useShopifyProductsQuery>;
export type ShopifyProductsLazyQueryHookResult = ReturnType<typeof useShopifyProductsLazyQuery>;
export type ShopifyProductsQueryResult = Apollo.QueryResult<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>;

/**
 * __useShopifyProductQuery__
 *
 * To run a query within a React component, call `useShopifyProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopifyProductQuery(baseOptions: Apollo.QueryHookOptions<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>(Operations.ShopifyProductDocument, options);
      }
export function useShopifyProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>(Operations.ShopifyProductDocument, options);
        }
export type ShopifyProductQueryHookResult = ReturnType<typeof useShopifyProductQuery>;
export type ShopifyProductLazyQueryHookResult = ReturnType<typeof useShopifyProductLazyQuery>;
export type ShopifyProductQueryResult = Apollo.QueryResult<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>;

/**
 * __useLatestShopifyProductQuery__
 *
 * To run a query within a React component, call `useLatestShopifyProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestShopifyProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestShopifyProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestShopifyProductQuery(baseOptions?: Apollo.QueryHookOptions<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>(Operations.LatestShopifyProductDocument, options);
      }
export function useLatestShopifyProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>(Operations.LatestShopifyProductDocument, options);
        }
export type LatestShopifyProductQueryHookResult = ReturnType<typeof useLatestShopifyProductQuery>;
export type LatestShopifyProductLazyQueryHookResult = ReturnType<typeof useLatestShopifyProductLazyQuery>;
export type LatestShopifyProductQueryResult = Apollo.QueryResult<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>;

/**
 * __useShopifyCollectionsQuery__
 *
 * To run a query within a React component, call `useShopifyCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyCollectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifyCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>(Operations.ShopifyCollectionsDocument, options);
      }
export function useShopifyCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>(Operations.ShopifyCollectionsDocument, options);
        }
export type ShopifyCollectionsQueryHookResult = ReturnType<typeof useShopifyCollectionsQuery>;
export type ShopifyCollectionsLazyQueryHookResult = ReturnType<typeof useShopifyCollectionsLazyQuery>;
export type ShopifyCollectionsQueryResult = Apollo.QueryResult<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>;

/**
 * __useShopifyCollectionQuery__
 *
 * To run a query within a React component, call `useShopifyCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopifyCollectionQuery(baseOptions: Apollo.QueryHookOptions<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>(Operations.ShopifyCollectionDocument, options);
      }
export function useShopifyCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>(Operations.ShopifyCollectionDocument, options);
        }
export type ShopifyCollectionQueryHookResult = ReturnType<typeof useShopifyCollectionQuery>;
export type ShopifyCollectionLazyQueryHookResult = ReturnType<typeof useShopifyCollectionLazyQuery>;
export type ShopifyCollectionQueryResult = Apollo.QueryResult<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>;

/**
 * __useLatestShopifyCollectionQuery__
 *
 * To run a query within a React component, call `useLatestShopifyCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestShopifyCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestShopifyCollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestShopifyCollectionQuery(baseOptions?: Apollo.QueryHookOptions<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>(Operations.LatestShopifyCollectionDocument, options);
      }
export function useLatestShopifyCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>(Operations.LatestShopifyCollectionDocument, options);
        }
export type LatestShopifyCollectionQueryHookResult = ReturnType<typeof useLatestShopifyCollectionQuery>;
export type LatestShopifyCollectionLazyQueryHookResult = ReturnType<typeof useLatestShopifyCollectionLazyQuery>;
export type LatestShopifyCollectionQueryResult = Apollo.QueryResult<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>;

/**
 * __useShopifyBlogsQuery__
 *
 * To run a query within a React component, call `useShopifyBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyBlogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyBlogsQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>(Operations.ShopifyBlogsDocument, options);
      }
export function useShopifyBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>(Operations.ShopifyBlogsDocument, options);
        }
export type ShopifyBlogsQueryHookResult = ReturnType<typeof useShopifyBlogsQuery>;
export type ShopifyBlogsLazyQueryHookResult = ReturnType<typeof useShopifyBlogsLazyQuery>;
export type ShopifyBlogsQueryResult = Apollo.QueryResult<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>;

/**
 * __useShopifyBlogQuery__
 *
 * To run a query within a React component, call `useShopifyBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopifyBlogQuery(baseOptions: Apollo.QueryHookOptions<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>(Operations.ShopifyBlogDocument, options);
      }
export function useShopifyBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>(Operations.ShopifyBlogDocument, options);
        }
export type ShopifyBlogQueryHookResult = ReturnType<typeof useShopifyBlogQuery>;
export type ShopifyBlogLazyQueryHookResult = ReturnType<typeof useShopifyBlogLazyQuery>;
export type ShopifyBlogQueryResult = Apollo.QueryResult<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>;

/**
 * __useShopifyProductsCountQuery__
 *
 * To run a query within a React component, call `useShopifyProductsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyProductsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyProductsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyProductsCountQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>(Operations.ShopifyProductsCountDocument, options);
      }
export function useShopifyProductsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>(Operations.ShopifyProductsCountDocument, options);
        }
export type ShopifyProductsCountQueryHookResult = ReturnType<typeof useShopifyProductsCountQuery>;
export type ShopifyProductsCountLazyQueryHookResult = ReturnType<typeof useShopifyProductsCountLazyQuery>;
export type ShopifyProductsCountQueryResult = Apollo.QueryResult<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>;

/**
 * __useShopifyCollectionsCountQuery__
 *
 * To run a query within a React component, call `useShopifyCollectionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyCollectionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyCollectionsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyCollectionsCountQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>(Operations.ShopifyCollectionsCountDocument, options);
      }
export function useShopifyCollectionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>(Operations.ShopifyCollectionsCountDocument, options);
        }
export type ShopifyCollectionsCountQueryHookResult = ReturnType<typeof useShopifyCollectionsCountQuery>;
export type ShopifyCollectionsCountLazyQueryHookResult = ReturnType<typeof useShopifyCollectionsCountLazyQuery>;
export type ShopifyCollectionsCountQueryResult = Apollo.QueryResult<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>;

/**
 * __useShopifyShopQuery__
 *
 * To run a query within a React component, call `useShopifyShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyShopQuery(baseOptions?: Apollo.QueryHookOptions<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>(Operations.ShopifyShopDocument, options);
      }
export function useShopifyShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>(Operations.ShopifyShopDocument, options);
        }
export type ShopifyShopQueryHookResult = ReturnType<typeof useShopifyShopQuery>;
export type ShopifyShopLazyQueryHookResult = ReturnType<typeof useShopifyShopLazyQuery>;
export type ShopifyShopQueryResult = Apollo.QueryResult<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>;

/**
 * __useSchedulesQuery__
 *
 * To run a query within a React component, call `useSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulesQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useSchedulesQuery(baseOptions: Apollo.QueryHookOptions<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>(Operations.SchedulesDocument, options);
      }
export function useSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>(Operations.SchedulesDocument, options);
        }
export type SchedulesQueryHookResult = ReturnType<typeof useSchedulesQuery>;
export type SchedulesLazyQueryHookResult = ReturnType<typeof useSchedulesLazyQuery>;
export type SchedulesQueryResult = Apollo.QueryResult<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>;
export type CreateScheduleMutationFn = Apollo.MutationFunction<Operations.CreateScheduleMutation, Operations.CreateScheduleMutationVariables>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CreateScheduleMutation, Operations.CreateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CreateScheduleMutation, Operations.CreateScheduleMutationVariables>(Operations.CreateScheduleDocument, options);
      }
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = Apollo.MutationResult<Operations.CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<Operations.CreateScheduleMutation, Operations.CreateScheduleMutationVariables>;
export type DeleteScheduleMutationFn = Apollo.MutationFunction<Operations.DeleteScheduleMutation, Operations.DeleteScheduleMutationVariables>;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleMutation, { data, loading, error }] = useDeleteScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleMutation(baseOptions?: Apollo.MutationHookOptions<Operations.DeleteScheduleMutation, Operations.DeleteScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.DeleteScheduleMutation, Operations.DeleteScheduleMutationVariables>(Operations.DeleteScheduleDocument, options);
      }
export type DeleteScheduleMutationHookResult = ReturnType<typeof useDeleteScheduleMutation>;
export type DeleteScheduleMutationResult = Apollo.MutationResult<Operations.DeleteScheduleMutation>;
export type DeleteScheduleMutationOptions = Apollo.BaseMutationOptions<Operations.DeleteScheduleMutation, Operations.DeleteScheduleMutationVariables>;
export type ShopifyPublishPageMutationFn = Apollo.MutationFunction<Operations.ShopifyPublishPageMutation, Operations.ShopifyPublishPageMutationVariables>;

/**
 * __useShopifyPublishPageMutation__
 *
 * To run a mutation, you first call `useShopifyPublishPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyPublishPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyPublishPageMutation, { data, loading, error }] = useShopifyPublishPageMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useShopifyPublishPageMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ShopifyPublishPageMutation, Operations.ShopifyPublishPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ShopifyPublishPageMutation, Operations.ShopifyPublishPageMutationVariables>(Operations.ShopifyPublishPageDocument, options);
      }
export type ShopifyPublishPageMutationHookResult = ReturnType<typeof useShopifyPublishPageMutation>;
export type ShopifyPublishPageMutationResult = Apollo.MutationResult<Operations.ShopifyPublishPageMutation>;
export type ShopifyPublishPageMutationOptions = Apollo.BaseMutationOptions<Operations.ShopifyPublishPageMutation, Operations.ShopifyPublishPageMutationVariables>;

/**
 * __useVerifyCategoryQuery__
 *
 * To run a query within a React component, call `useVerifyCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCategoryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useVerifyCategoryQuery(baseOptions: Apollo.QueryHookOptions<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>(Operations.VerifyCategoryDocument, options);
      }
export function useVerifyCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>(Operations.VerifyCategoryDocument, options);
        }
export type VerifyCategoryQueryHookResult = ReturnType<typeof useVerifyCategoryQuery>;
export type VerifyCategoryLazyQueryHookResult = ReturnType<typeof useVerifyCategoryLazyQuery>;
export type VerifyCategoryQueryResult = Apollo.QueryResult<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>;

/**
 * __useSnippetsQuery__
 *
 * To run a query within a React component, call `useSnippetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnippetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnippetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSnippetsQuery(baseOptions?: Apollo.QueryHookOptions<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>(Operations.SnippetsDocument, options);
      }
export function useSnippetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>(Operations.SnippetsDocument, options);
        }
export type SnippetsQueryHookResult = ReturnType<typeof useSnippetsQuery>;
export type SnippetsLazyQueryHookResult = ReturnType<typeof useSnippetsLazyQuery>;
export type SnippetsQueryResult = Apollo.QueryResult<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>;

/**
 * __useLibrarySnippetsQuery__
 *
 * To run a query within a React component, call `useLibrarySnippetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibrarySnippetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibrarySnippetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibrarySnippetsQuery(baseOptions: Apollo.QueryHookOptions<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>(Operations.LibrarySnippetsDocument, options);
      }
export function useLibrarySnippetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>(Operations.LibrarySnippetsDocument, options);
        }
export type LibrarySnippetsQueryHookResult = ReturnType<typeof useLibrarySnippetsQuery>;
export type LibrarySnippetsLazyQueryHookResult = ReturnType<typeof useLibrarySnippetsLazyQuery>;
export type LibrarySnippetsQueryResult = Apollo.QueryResult<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>;

/**
 * __useLibrarySnippetCategoriesQuery__
 *
 * To run a query within a React component, call `useLibrarySnippetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibrarySnippetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibrarySnippetCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLibrarySnippetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>(Operations.LibrarySnippetCategoriesDocument, options);
      }
export function useLibrarySnippetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>(Operations.LibrarySnippetCategoriesDocument, options);
        }
export type LibrarySnippetCategoriesQueryHookResult = ReturnType<typeof useLibrarySnippetCategoriesQuery>;
export type LibrarySnippetCategoriesLazyQueryHookResult = ReturnType<typeof useLibrarySnippetCategoriesLazyQuery>;
export type LibrarySnippetCategoriesQueryResult = Apollo.QueryResult<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>;

/**
 * __useSnippetCategoriesQuery__
 *
 * To run a query within a React component, call `useSnippetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnippetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnippetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSnippetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>(Operations.SnippetCategoriesDocument, options);
      }
export function useSnippetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>(Operations.SnippetCategoriesDocument, options);
        }
export type SnippetCategoriesQueryHookResult = ReturnType<typeof useSnippetCategoriesQuery>;
export type SnippetCategoriesLazyQueryHookResult = ReturnType<typeof useSnippetCategoriesLazyQuery>;
export type SnippetCategoriesQueryResult = Apollo.QueryResult<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>;
export type CreateSnippetMutationFn = Apollo.MutationFunction<Operations.CreateSnippetMutation, Operations.CreateSnippetMutationVariables>;

/**
 * __useCreateSnippetMutation__
 *
 * To run a mutation, you first call `useCreateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnippetMutation, { data, loading, error }] = useCreateSnippetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CreateSnippetMutation, Operations.CreateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CreateSnippetMutation, Operations.CreateSnippetMutationVariables>(Operations.CreateSnippetDocument, options);
      }
export type CreateSnippetMutationHookResult = ReturnType<typeof useCreateSnippetMutation>;
export type CreateSnippetMutationResult = Apollo.MutationResult<Operations.CreateSnippetMutation>;
export type CreateSnippetMutationOptions = Apollo.BaseMutationOptions<Operations.CreateSnippetMutation, Operations.CreateSnippetMutationVariables>;
export type DeleteSnippetMutationFn = Apollo.MutationFunction<Operations.DeleteSnippetMutation, Operations.DeleteSnippetMutationVariables>;

/**
 * __useDeleteSnippetMutation__
 *
 * To run a mutation, you first call `useDeleteSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnippetMutation, { data, loading, error }] = useDeleteSnippetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operations.DeleteSnippetMutation, Operations.DeleteSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.DeleteSnippetMutation, Operations.DeleteSnippetMutationVariables>(Operations.DeleteSnippetDocument, options);
      }
export type DeleteSnippetMutationHookResult = ReturnType<typeof useDeleteSnippetMutation>;
export type DeleteSnippetMutationResult = Apollo.MutationResult<Operations.DeleteSnippetMutation>;
export type DeleteSnippetMutationOptions = Apollo.BaseMutationOptions<Operations.DeleteSnippetMutation, Operations.DeleteSnippetMutationVariables>;
export type DeleteSnippetCategoryMutationFn = Apollo.MutationFunction<Operations.DeleteSnippetCategoryMutation, Operations.DeleteSnippetCategoryMutationVariables>;

/**
 * __useDeleteSnippetCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteSnippetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnippetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnippetCategoryMutation, { data, loading, error }] = useDeleteSnippetCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSnippetCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Operations.DeleteSnippetCategoryMutation, Operations.DeleteSnippetCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.DeleteSnippetCategoryMutation, Operations.DeleteSnippetCategoryMutationVariables>(Operations.DeleteSnippetCategoryDocument, options);
      }
export type DeleteSnippetCategoryMutationHookResult = ReturnType<typeof useDeleteSnippetCategoryMutation>;
export type DeleteSnippetCategoryMutationResult = Apollo.MutationResult<Operations.DeleteSnippetCategoryMutation>;
export type DeleteSnippetCategoryMutationOptions = Apollo.BaseMutationOptions<Operations.DeleteSnippetCategoryMutation, Operations.DeleteSnippetCategoryMutationVariables>;
export type CreateLibrarySnippetMutationFn = Apollo.MutationFunction<Operations.CreateLibrarySnippetMutation, Operations.CreateLibrarySnippetMutationVariables>;

/**
 * __useCreateLibrarySnippetMutation__
 *
 * To run a mutation, you first call `useCreateLibrarySnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibrarySnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibrarySnippetMutation, { data, loading, error }] = useCreateLibrarySnippetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLibrarySnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CreateLibrarySnippetMutation, Operations.CreateLibrarySnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CreateLibrarySnippetMutation, Operations.CreateLibrarySnippetMutationVariables>(Operations.CreateLibrarySnippetDocument, options);
      }
export type CreateLibrarySnippetMutationHookResult = ReturnType<typeof useCreateLibrarySnippetMutation>;
export type CreateLibrarySnippetMutationResult = Apollo.MutationResult<Operations.CreateLibrarySnippetMutation>;
export type CreateLibrarySnippetMutationOptions = Apollo.BaseMutationOptions<Operations.CreateLibrarySnippetMutation, Operations.CreateLibrarySnippetMutationVariables>;
export type DeleteLibrarySnippetMutationFn = Apollo.MutationFunction<Operations.DeleteLibrarySnippetMutation, Operations.DeleteLibrarySnippetMutationVariables>;

/**
 * __useDeleteLibrarySnippetMutation__
 *
 * To run a mutation, you first call `useDeleteLibrarySnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLibrarySnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLibrarySnippetMutation, { data, loading, error }] = useDeleteLibrarySnippetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLibrarySnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operations.DeleteLibrarySnippetMutation, Operations.DeleteLibrarySnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.DeleteLibrarySnippetMutation, Operations.DeleteLibrarySnippetMutationVariables>(Operations.DeleteLibrarySnippetDocument, options);
      }
export type DeleteLibrarySnippetMutationHookResult = ReturnType<typeof useDeleteLibrarySnippetMutation>;
export type DeleteLibrarySnippetMutationResult = Apollo.MutationResult<Operations.DeleteLibrarySnippetMutation>;
export type DeleteLibrarySnippetMutationOptions = Apollo.BaseMutationOptions<Operations.DeleteLibrarySnippetMutation, Operations.DeleteLibrarySnippetMutationVariables>;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions?: Apollo.QueryHookOptions<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>(Operations.GetStoresDocument, options);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>(Operations.GetStoresDocument, options);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>;
export type SwitchStoreMutationFn = Apollo.MutationFunction<Operations.SwitchStoreMutation, Operations.SwitchStoreMutationVariables>;

/**
 * __useSwitchStoreMutation__
 *
 * To run a mutation, you first call `useSwitchStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchStoreMutation, { data, loading, error }] = useSwitchStoreMutation({
 *   variables: {
 *      storeName: // value for 'storeName'
 *   },
 * });
 */
export function useSwitchStoreMutation(baseOptions?: Apollo.MutationHookOptions<Operations.SwitchStoreMutation, Operations.SwitchStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.SwitchStoreMutation, Operations.SwitchStoreMutationVariables>(Operations.SwitchStoreDocument, options);
      }
export type SwitchStoreMutationHookResult = ReturnType<typeof useSwitchStoreMutation>;
export type SwitchStoreMutationResult = Apollo.MutationResult<Operations.SwitchStoreMutation>;
export type SwitchStoreMutationOptions = Apollo.BaseMutationOptions<Operations.SwitchStoreMutation, Operations.SwitchStoreMutationVariables>;
export type ChargeMutationFn = Apollo.MutationFunction<Operations.ChargeMutation, Operations.ChargeMutationVariables>;

/**
 * __useChargeMutation__
 *
 * To run a mutation, you first call `useChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeMutation, { data, loading, error }] = useChargeMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useChargeMutation(baseOptions?: Apollo.MutationHookOptions<Operations.ChargeMutation, Operations.ChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.ChargeMutation, Operations.ChargeMutationVariables>(Operations.ChargeDocument, options);
      }
export type ChargeMutationHookResult = ReturnType<typeof useChargeMutation>;
export type ChargeMutationResult = Apollo.MutationResult<Operations.ChargeMutation>;
export type ChargeMutationOptions = Apollo.BaseMutationOptions<Operations.ChargeMutation, Operations.ChargeMutationVariables>;
export type CheckChargeMutationFn = Apollo.MutationFunction<Operations.CheckChargeMutation, Operations.CheckChargeMutationVariables>;

/**
 * __useCheckChargeMutation__
 *
 * To run a mutation, you first call `useCheckChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkChargeMutation, { data, loading, error }] = useCheckChargeMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckChargeMutation(baseOptions?: Apollo.MutationHookOptions<Operations.CheckChargeMutation, Operations.CheckChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.CheckChargeMutation, Operations.CheckChargeMutationVariables>(Operations.CheckChargeDocument, options);
      }
export type CheckChargeMutationHookResult = ReturnType<typeof useCheckChargeMutation>;
export type CheckChargeMutationResult = Apollo.MutationResult<Operations.CheckChargeMutation>;
export type CheckChargeMutationOptions = Apollo.BaseMutationOptions<Operations.CheckChargeMutation, Operations.CheckChargeMutationVariables>;
export type UpdateUserMutationFn = Apollo.MutationFunction<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>(Operations.UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Operations.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>;

/**
 * __usePermissionQuery__
 *
 * To run a query within a React component, call `usePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionQuery(baseOptions?: Apollo.QueryHookOptions<Operations.PermissionQuery, Operations.PermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operations.PermissionQuery, Operations.PermissionQueryVariables>(Operations.PermissionDocument, options);
      }
export function usePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operations.PermissionQuery, Operations.PermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operations.PermissionQuery, Operations.PermissionQueryVariables>(Operations.PermissionDocument, options);
        }
export type PermissionQueryHookResult = ReturnType<typeof usePermissionQuery>;
export type PermissionLazyQueryHookResult = ReturnType<typeof usePermissionLazyQuery>;
export type PermissionQueryResult = Apollo.QueryResult<Operations.PermissionQuery, Operations.PermissionQueryVariables>;