import { Heading, Text, Button, Link, Flex, Icon } from '@chakra-ui/react';
import { PageType } from '../apollo/types.generate';
import { CONTENTS_BY_TYPE } from '../constants/page';

const INSTRUCTION_CONTENTS_BY_TYPE = {
  [PageType.Regular]: {
    ...CONTENTS_BY_TYPE[PageType.Regular],
    description:
      'Create custom pages to house static content for your customers such as a homepage, About Us page, customer form, or campaign landing page.',
  },
  [PageType.Product]: {
    ...CONTENTS_BY_TYPE[PageType.Product],
    description:
      'Build a Product Page to display product information as listed on Shopify. You can either add content to an existing product page or build one from scratch.',
  },
  [PageType.Collection]: {
    ...CONTENTS_BY_TYPE[PageType.Collection],
    description:
      'Build a Collection Page to display collection information as listed on Shopify. Add content to existing Collection Pages or create a new one from scratch.',
  },
  [PageType.Post]: {
    ...CONTENTS_BY_TYPE[PageType.Post],
    description:
      'Build a blog post from scratch. Customize how you display text and visuals with maximum flexibility.',
  },
};

type InstructionByPageTypeProps = {
  pageType: PageType;
  createPageByType: (pageType: PageType) => void;
};

const InstructionByPageType = ({
  pageType,
  createPageByType,
}: InstructionByPageTypeProps) => {
  const { icon, typeName, description, documentUrl, typeNameForTracking } =
    INSTRUCTION_CONTENTS_BY_TYPE[pageType];

  return (
    <Flex
      direction="column"
      maxWidth="520px"
      alignItems="center"
      marginX="auto"
      mt="120px"
    >
      <Icon as={icon} w="100px" h="60px" />
      <Heading fontSize="18px" mt="32px">
        {typeName}
      </Heading>
      <Text fontWeight={600} mt="8px" textAlign="center">
        {description}
      </Text>
      <Link
        fontSize="14px"
        color="primary"
        textDecoration="underline"
        data-track-event={`click_learn_more_${typeNameForTracking}`}
        data-track-parameter-name="placement"
        data-track-parameter-value={`${typeNameForTracking}_tab`}
        _hover={{ textDecoration: 'underline' }}
        isExternal
        href={documentUrl}
      >
        Learn more
      </Link>
      <Button
        mt="32px"
        onClick={() => createPageByType(pageType)}
        data-track-event={`click_build_${typeNameForTracking}`}
        data-track-parameter-name="placement"
        data-track-parameter-value={`${typeNameForTracking}_tab`}
      >
        {`Build a ${typeName}${pageType === PageType.Post ? '' : ' Page'}`}
      </Button>
    </Flex>
  );
};

export default InstructionByPageType;
