import * as R from 'ramda';
import styled from '@emotion/styled';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { IconLogo } from './Logo';

export enum LOADING_TYPE {
  Logo = 'logo',
  Spinner = 'spinner',
}

type LoadingProps = {
  type?: LOADING_TYPE;
};

const Wrapper = styled.div`
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({ type }: LoadingProps) => {
  if (R.equals(type, LOADING_TYPE.Spinner)) {
    return (
      <Center h="full" flexDirection="column">
        <Spinner color="primary" />
        <Text mt="8px">Loading...</Text>
      </Center>
    );
  } else {
    return (
      <Wrapper>
        <IconLogo />
      </Wrapper>
    );
  }
};

export default Loading;
