import {
  ActorRefFrom,
  AnyEventObject,
  assign,
  createMachine,
  send,
  spawn,
} from 'xstate';
import { modalMachine, MODAL_EVENTS } from './modalMachine';
import { pagesMachines } from './pages/pagesMachine';
import { createPageMachine } from './createPageMachine';
import { PageType } from '../apollo/types.generate';

enum S {
  IDLE = 'IDLE',
  CREATING_PAGE = 'CREATING_PAGE',
  OPEN_SCHEDULE = 'OPEN_SCHEDULE',
}

enum E {
  CREATE_PAGE = 'CREATE_PAGE',
  OPEN_DELETE_PAGE = 'OPEN_DELETE_PAGE',
  OPEN_HOMEPAGE_DIALOG = 'OPEN_HOMEPAGE_DIALOG',
  CLOSE_HOMEPAGE_DIALOG = 'CLOSE_HOMEPAGE_DIALOG',
  OPEN_SCHEDULE = 'OPEN_SCHEDULE',
  OPEN_INTERVIEW_FORM = 'OPEN_INTERVIEW_FORM',
}

export type HomeContext = {
  createPageModalRef: ActorRefFrom<typeof modalMachine>;
  deletePageDialogRef: ActorRefFrom<typeof modalMachine>;
  homepageDialogRef: ActorRefFrom<typeof modalMachine>;
  scheduleModalRef: ActorRefFrom<typeof modalMachine>;
  interviewFormModalRef: ActorRefFrom<typeof modalMachine>;
  pagesRef: ActorRefFrom<typeof pagesMachines>;
  createPageMachineRef: ActorRefFrom<typeof createPageMachine>;
  schedulePageId: string;
  deletePageId: string;
  confirmingHomepageId: string;
  selectedPageType: PageType;
};

export type HomeEvent =
  | { type: E.CREATE_PAGE; pageType?: PageType }
  | { type: E.OPEN_DELETE_PAGE; pageId: string }
  | { type: E.OPEN_HOMEPAGE_DIALOG; pageId: string }
  | { type: E.CLOSE_HOMEPAGE_DIALOG }
  | { type: E.OPEN_SCHEDULE; pageId: string }
  | { type: E.OPEN_INTERVIEW_FORM };

export type HomeState =
  | { value: S.IDLE; context: Required<HomeContext> }
  | { value: S.CREATING_PAGE; context: Required<HomeContext> }
  | { value: S.OPEN_SCHEDULE; context: Required<HomeContext> };

const homeMachine = createMachine<HomeContext, HomeEvent, HomeState>(
  {
    id: 'home',
    initial: S.IDLE,
    entry: [
      'spawnCreatePageModalMachine',
      'spawnDeletePageDialogMachine',
      'spawnHomepageDialogMachine',
      'spawnScheduleModalMachine',
      'spawnInterviewFormModalMachine',
      'spawnPagesMachine',
    ],
    states: {
      [S.IDLE]: {
        on: {
          [E.CREATE_PAGE]: {
            actions: ['openCreatePageModal', 'assignSelectedPageType'],
          },
          [E.OPEN_DELETE_PAGE]: {
            actions: ['openDeletePageDialog', 'assignDeletePageId'],
          },
          [E.OPEN_HOMEPAGE_DIALOG]: {
            actions: ['openHomepageDialog', 'assignConfirmingHomepageId'],
          },
          [E.CLOSE_HOMEPAGE_DIALOG]: {
            actions: ['closeHomepageDialog'],
          },
          [E.OPEN_SCHEDULE]: {
            actions: ['openScheduleModal', 'assignSchedulePageId'],
          },
          [E.OPEN_INTERVIEW_FORM]: {
            actions: ['openInterviewFormModal'],
          },
        },
      },
    },
  },
  {
    actions: {
      spawnCreatePageModalMachine: assign({
        createPageModalRef: () => spawn(modalMachine),
      }),
      spawnDeletePageDialogMachine: assign({
        deletePageDialogRef: () => spawn(modalMachine),
      }),
      spawnHomepageDialogMachine: assign({
        homepageDialogRef: () => spawn(modalMachine),
      }),
      spawnScheduleModalMachine: assign({
        scheduleModalRef: () => spawn(modalMachine),
      }),
      spawnInterviewFormModalMachine: assign({
        interviewFormModalRef: () => spawn(modalMachine),
      }),
      spawnPagesMachine: assign({
        pagesRef: () => spawn(pagesMachines),
      }),
      assignSelectedPageType: assign({
        selectedPageType: (_, event: AnyEventObject) => event.pageType,
      }),
      assignSchedulePageId: assign({
        schedulePageId: (_, event: AnyEventObject) => event.pageId,
      }),
      assignDeletePageId: assign({
        deletePageId: (_, event: AnyEventObject) => event.pageId,
      }),
      assignConfirmingHomepageId: assign({
        confirmingHomepageId: (_, event: AnyEventObject) => event.pageId,
      }),
      openCreatePageModal: send(
        { type: MODAL_EVENTS.OPEN },
        { to: (ctx) => ctx.createPageModalRef }
      ),
      openDeletePageDialog: send(
        { type: MODAL_EVENTS.OPEN },
        { to: (ctx) => ctx.deletePageDialogRef }
      ),
      openScheduleModal: send(
        { type: MODAL_EVENTS.OPEN },
        { to: (ctx) => ctx.scheduleModalRef }
      ),
      openInterviewFormModal: send(
        { type: MODAL_EVENTS.OPEN },
        { to: (ctx) => ctx.interviewFormModalRef }
      ),
      openHomepageDialog: send(
        { type: MODAL_EVENTS.OPEN },
        { to: (ctx) => ctx.homepageDialogRef }
      ),
      closeHomepageDialog: send(
        { type: MODAL_EVENTS.CLOSE_MODAL },
        { to: (ctx) => ctx.homepageDialogRef }
      ),
    },
  }
);

export { homeMachine, S as HOME_STATES, E as HOME_EVENTS };
