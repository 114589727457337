import {
  Heading,
  Text,
  Box,
  Flex,
  Stack,
  Link,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { LIVE_SUPPORT_LINK } from '../pages/editor/constants/links';
import { FiveMinutesIcon, BookingIcon, QuestionIcon } from './Icons';

type SupportCardProps = {
  variant?: 'horizontal' | 'vertical';
};

const SupportCard = ({ variant }: SupportCardProps) => {
  const styles = useMultiStyleConfig('SupportCard', {
    variant,
  });
  const isFirstTimeUser = variant === 'horizontal';

  return (
    <Box sx={styles.container}>
      <Heading sx={styles.title}>Support</Heading>
      <Stack
        direction={variant === 'horizontal' ? 'row' : 'column'}
        spacing={variant === 'horizontal' ? '32px' : '24px'}
      >
        <Flex sx={styles.itemContainer}>
          {variant === 'horizontal' ? (
            <>
              <Box sx={styles.icon}>
                <FiveMinutesIcon />
              </Box>
              <Flex sx={styles.textContainer}>
                <Text sx={styles.text}>Start building in 5 minutes</Text>
                <Link
                  sx={styles.link}
                  isExternal
                  data-track-event="click_learn_more_5minutes"
                  href="https://layoutbase.helpdocs.io/article/l6a4c9gt23-get-started-in-5-mins"
                >
                  Learn more
                </Link>
              </Flex>
            </>
          ) : (
            <>
              <Box sx={styles.icon}>
                <QuestionIcon />
              </Box>
              <Flex sx={styles.textContainer}>
                <Link
                  sx={styles.link}
                  isExternal
                  data-track-event="click_visit_help_center"
                  href="https://layoutbase.helpdocs.io/"
                >
                  Visit Help Center
                </Link>
                <Text maxW="226px">
                  An answer to your question might already exist.
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <Flex sx={styles.itemContainer}>
          <Box sx={styles.icon}>
            <BookingIcon />
          </Box>
          <Flex sx={styles.textContainer}>
            <Text sx={styles.text}>Book live support or product demo</Text>
            <Link
              sx={styles.link}
              isExternal
              data-track-event="click_book_now"
              data-track-parameter-name="user_type"
              data-track-parameter-value={
                isFirstTimeUser ? 'first_time_user' : 'afterwards_user'
              }
              href={LIVE_SUPPORT_LINK}
            >
              Book now
            </Link>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

export default SupportCard;
