import { createMachine, sendParent, assign } from 'xstate';
import * as R from 'ramda';
import { Page, PageType } from '../../apollo/types.generate';
import { HOME_EVENTS } from '../homeMachine';
import { sdk } from '../../apollo/client';
import { createStandaloneToast } from '../../components/Toast';

const toast = createStandaloneToast();

enum S {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EMPTY = 'EMPTY',
  SEARCH_EMPTY = 'SEARCH_EMPTY',
  SEARCHING = 'SEARCHING',
  MORE_PAGES_LOADING = 'MORE_PAGES_LOADING',
  PUBLISHING_PAGE = 'PUBLISHING_PAGE',
  DELETING_PAGE = 'DELETING_PAGE',
  UPDATING_HOMEPAGE = 'UPDATING_HOMEPAGE',
}

enum E {
  CHANGE_PAGE_TYPE = 'CHANGE_PAGE_TYPE',
  SEARCH = 'SEARCH',
  MORE_PAGES = 'MORE_PAGES',
  PUBLISH_PAGE = 'PUBLISH_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',
  UPDATE_HOMEPAGE = 'UPDATE_HOMEPAGE',
}

export type PagesContext = {
  pages: Page[];
  search: string;
  pageType?: PageType | null;
  cursorId?: string | null;
  deletePageId: string;
  homepage?: Page | null;
  disabledFromShopify: boolean;
};

const pagesMachines = createMachine<PagesContext>(
  {
    id: 'pages',
    context: {
      pages: [],
      search: '',
      pageType: null,
      cursorId: null,
      deletePageId: '',
      homepage: null as Page | null,
      disabledFromShopify: false,
    },
    initial: S.LOADING,
    states: {
      [S.EMPTY]: {},
      [S.SEARCH_EMPTY]: {},
      [S.LOADING]: {
        id: S.LOADING,
        invoke: {
          src: 'getPages',
          onDone: [
            { cond: 'isEmpty', target: S.EMPTY },
            {
              actions: ['assignPages', 'assignHomepage', 'assignCursorId'],
              target: S.LOADED,
            },
          ],
        },
      },
      [S.SEARCHING]: {
        id: S.SEARCHING,
        invoke: {
          src: 'getPages',
          onDone: [
            { cond: 'isSearchEmpty', target: S.SEARCH_EMPTY },
            {
              actions: ['assignPages', 'assignHomepage', 'assignCursorId'],
              target: S.LOADED,
            },
          ],
        },
      },
      [S.LOADED]: {
        initial: S.IDLE,
        states: {
          [S.IDLE]: {
            on: {
              [E.PUBLISH_PAGE]: { target: S.PUBLISHING_PAGE },
              [E.UPDATE_HOMEPAGE]: S.UPDATING_HOMEPAGE,
              [E.DELETE_PAGE]: {
                target: S.DELETING_PAGE,
                actions: ['assignDeletePageId'],
              },
            },
          },
          [S.PUBLISHING_PAGE]: {
            invoke: {
              src: 'publishPage',
              onDone: {
                target: S.IDLE,
                actions: ['notifyPublishPageSuccess', 'updatePublishedStatus'],
              },
              onError: {
                target: S.IDLE,
                actions: ['notifyPublishPageError'],
              },
            },
          },
          [S.UPDATING_HOMEPAGE]: {
            invoke: {
              src: 'updateHomepage',
              onDone: {
                target: S.IDLE,
                actions: [
                  'notifyUpdateHomepageSuccess',
                  'closeHomepageDialog',
                  'setPagesAfterUpdateHompage',
                  'assignHomepage',
                ],
              },
              onError: {
                target: S.IDLE,
                actions: [
                  'notifyUpdateHomepageError',
                  (ctx, event) => {
                    console.log({ ctx, event });
                  },
                ],
              },
            },
          },
          [S.DELETING_PAGE]: {
            invoke: {
              src: 'deletePage',
              onDone: {
                target: S.IDLE,
                actions: ['removePage', 'notifyDeletePageSuccess'],
              },
              onError: {
                target: S.IDLE,
                actions: ['notifyDeletePageError'],
              },
            },
          },
          [S.MORE_PAGES_LOADING]: {
            invoke: {
              src: 'getPages',
              onDone: {
                actions: ['concatPages', 'assignCursorId'],
                target: S.IDLE,
              },
            },
          },
        },
        on: {
          [E.MORE_PAGES]: {
            target: `.${S.MORE_PAGES_LOADING}`,
          },
        },
      },
    },
    on: {
      [E.CHANGE_PAGE_TYPE]: {
        target: S.LOADING,
        actions: ['clearCursorId', 'assignPageType'],
      },
      [E.SEARCH]: {
        target: S.SEARCHING,
        actions: [
          'assignSearchText',
          'clearCursorId',
          'assignDisabledFromShopify',
        ],
      },
    },
  },
  {
    actions: {
      closeHomepageDialog: sendParent({
        type: HOME_EVENTS.CLOSE_HOMEPAGE_DIALOG,
      }),
      assignPages: assign({
        pages: (_, event) => event.data.nodes,
      }),
      concatPages: assign({
        pages: (ctx, event) => ctx.pages.concat(event.data.nodes),
      }),
      removePage: assign({
        pages: (ctx) => ctx.pages.filter((p) => p.id !== ctx.deletePageId),
      }),
      updatePublishedStatus: assign({
        pages: ({ pages }, event) => {
          const index = R.findIndex(R.propEq('id', event.data.id))(pages);
          return R.adjust(index, R.assoc('status', event.data.status), pages);
        },
      }),
      assignDisabledFromShopify: assign({
        disabledFromShopify: (_, event) => event.disabledFromShopify,
      }),
      assignDeletePageId: assign({
        deletePageId: (_, event) => event.pageId,
      }),
      assignCursorId: assign({
        cursorId: (_, event) => event.data.cursorId,
      }),
      assignSearchText: assign({
        search: (_, event) => event.search,
      }),
      assignPageType: assign({
        pageType: (_, event) => event.pageType,
      }),
      clearCursorId: assign({
        cursorId: (_, event) => null,
      }),
      assignHomepage: assign({
        homepage: ({ pages }) =>
          R.find<Page>(R.propEq('isHomepage', true), pages) || null,
      }),
      setPagesAfterUpdateHompage: assign({
        pages: ({ pages }, event) => {
          if (event.data) {
            const index = R.findIndex(R.propEq('id', event.data.id))(pages);
            return R.adjust(index, R.assoc('isHomepage', true), pages);
          } else {
            const index = R.findIndex(R.propEq('isHomepage', true))(pages);
            return R.adjust(index, R.assoc('isHomepage', false), pages);
          }
        },
      }),
      notifyPublishPageSuccess: () =>
        toast({
          title: 'Page published.',
          status: 'success',
        }),
      notifyPublishPageError: () =>
        toast({
          title: 'Publish page failed.',
        }),
      notifyDeletePageSuccess: () =>
        toast({
          title: 'Page deleted.',
          status: 'success',
        }),
      notifyUpdateHomepageSuccess: () =>
        toast({
          title: 'Homepage updated.',
          status: 'success',
        }),
      notifyUpdateHomepageError: () =>
        toast({
          title: 'Failed to update Homepage.',
        }),
      notifyDeletePageError: () =>
        toast({
          title: 'Failed to delete page.',
        }),
    },
    services: {
      getPages: (ctx) =>
        sdk
          .pages(
            {
              keyword: ctx.search,
              take: 12,
              pageType: ctx.pageType,
              cursorId: ctx.cursorId,
            },
            { fetchPolicy: 'network-only' }
          )
          .then((res) => res.pages),
      publishPage: (_, { pageId }) =>
        sdk
          .shopifyPublishPage({ pageId })
          .then((res) => res.shopifyPublishPage),
      deletePage: (_, { pageId }) => sdk.deletePage({ id: pageId }),
      updateHomepage: (_, event) =>
        sdk
          .updateHomepage({
            input: {
              id: event.pageId,
              isHomepage: event.isHomepage,
            },
          })
          .then((res) => res.updateHomepage),
    },
    guards: {
      isEmpty: (_, event) => R.isEmpty(event.data.nodes),
      isSearchEmpty: (ctx, event) =>
        !!ctx.search && R.isEmpty(event.data.nodes),
    },
  }
);

export { pagesMachines, S as PAGE_STATES, E as PAGE_EVENTS };
