import { Image, Center } from '@chakra-ui/react';

export const NotFoundImage = () => (
  <Center boxSize="60px" borderRadius="4px" bgColor="border" mr="16px">
    <Image
      boxSize="24px"
      src={`${process.env.PUBLIC_URL || '/'}image/img_not_found_default.png`}
    />
  </Center>
);
