import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      /* Regular */
      @font-face {
        font-family: 'VelaSans';
        font-weight: normal;
        font-style: normal;
        src: url('/fonts/VelaSans-Regular.ttf') format('truetype'), url('/fonts/VelaSans-Regular.otf') format('opentype');
      }
      /* Semi Bold */
      @font-face {
        font-family: 'VelaSans';
        font-weight: 600;
        font-style: normal;
        src: url('/fonts/VelaSans-SemiBold.ttf') format('truetype'), url('/fonts/VelaSans-SemiBold.otf') format('opentype');
      }
      /* Bold */
      @font-face {
        font-family: 'VelaSans';
        font-weight: 700;
        font-style: normal;
        src: url('/fonts/VelaSans-Bold.ttf') format('truetype'), url('/fonts/VelaSans-Bold.otf') format('opentype');
      }
      `}
  />
);

export default Fonts;
