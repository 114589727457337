import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { AUTH_STATE, useAuth } from '../machines/authMachine';
import { CloseButton } from './CloseButton';

const SunsetMessageModal = () => {
  const [state] = useAuth();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const hasLoggedIn =
    state.matches([AUTH_STATE.LOGGED_IN, AUTH_STATE.ENABLED]) ||
    state.matches([AUTH_STATE.LOGGED_IN, AUTH_STATE.DISABLED]);

  if (!hasLoggedIn) {
    return null;
  }

  return (
    <Modal variant="editor" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800px" fontSize="14px">
        <ModalHeader>
          Layoutbase Sunset Notice
          <CloseButton pos="initial" onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <Text>
            Dear Customer,
            <br />
            <br />
            This is the Layoutbase Team. We thank you sincerely for downloading
            Layoutbase.
            <br />
            Please also accept our deepest apologies that we have decided to
            sunset Layoutbase and the app{' '}
            <Text as="span" color="primary" fontWeight="semibold">
              will be scheduled to be disabled by the end of 2023-01-26
              (Thursday) UTC.
            </Text>
          </Text>

          <Text fontWeight="semibold" color="black">
            <br />
            Please be informed of the below:
          </Text>
          <UnorderedList fontSize="inherit">
            <ListItem>Published pages won't be affected</ListItem>
            <ListItem>
              Editing publishing pages will not be supported, please search for
              alternatives
            </ListItem>
          </UnorderedList>

          <Text>
            <br />
            We thank you again for downloading Layoutbase and apologies for the
            inconvenience this may have caused you.
            <br />
            <br />
            Sincerely,
            <br />
            Layoutbase Team
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default SunsetMessageModal;
