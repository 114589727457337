import {
  useToast as useChakraToast,
  createStandaloneToast as createStandaloneChakraToast,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  IconButton,
  UseToastOptions,
  RenderProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import {
  RiInformationFill,
  RiCheckboxCircleFill,
  RiAlertFill,
  RiCloseCircleFill,
  RiCloseFill,
} from 'react-icons/ri';
import theme from '../theme';

const TOAST_ICON_MAP = {
  info: RiInformationFill,
  success: RiCheckboxCircleFill,
  warning: RiAlertFill,
  error: RiCloseCircleFill,
};

const Toast = ({
  title,
  status = 'error',
  isClosable = true,
  onClose,
}: UseToastOptions & RenderProps) => {
  const styles = useMultiStyleConfig('Toast', { variant: status });

  return (
    <Alert sx={styles.container}>
      <HStack>
        <AlertIcon as={TOAST_ICON_MAP[status]} sx={styles.icon} />
        {title && <AlertTitle sx={styles.title}>{title}</AlertTitle>}
        {isClosable && (
          <IconButton
            variant="close"
            icon={<RiCloseFill />}
            aria-label="close"
            sx={styles.closeButton}
            onClick={onClose}
          />
        )}
      </HStack>
    </Alert>
  );
};

const customToast = (toast, options: UseToastOptions) => {
  return toast({
    duration: 3000,
    render: (renderProps: RenderProps) => {
      return <Toast {...renderProps} {...options} />;
    },
    ...options,
  });
};

export const useToast = () => {
  const toast = useChakraToast();
  const toastImpl = (options: UseToastOptions) =>
    customToast(toast, {
      containerStyle: { maxWidth: '700px' },
      ...options,
    });
  toastImpl.close = toast.close;
  toastImpl.closeAll = toast.closeAll;
  toastImpl.update = toast.update;
  toastImpl.isActive = toast.isActive;

  return toastImpl;
};

export const createStandaloneToast = () => {
  const toast = createStandaloneChakraToast({ theme });
  const toastImpl = (options: UseToastOptions) =>
    customToast(toast, {
      containerStyle: { maxWidth: '700px' },
      ...options,
    });
  toastImpl.close = toast.close;
  toastImpl.closeAll = toast.closeAll;
  toastImpl.update = toast.update;
  toastImpl.isActive = toast.isActive;

  return toastImpl;
};
