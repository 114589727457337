import * as R from 'ramda';
import {
  BREAKPOINT_INDEX_BY_DEVICE,
  DEFAULT_RWD_VALUES,
  DEVICE_TYPE,
} from '../pages/editor/constants';

type RWDValueProps = undefined | string | (string | null)[];

export const convertRWDValue = (
  value: RWDValueProps,
  key?: keyof typeof DEFAULT_RWD_VALUES
) => {
  if (Array.isArray(value)) return value;

  return key ? DEFAULT_RWD_VALUES[key] : [null, null, null];
};

/**
 * This function is to migrate style props value for those components that used a single string props before instead of an RWD array.
 *
 * Please use `R.repeat(value, 3)` instead if you just want to convert a single value to an RWD array.
 *
 * @param value the string that needs to be converted to RWD array, if pass an array will just return the same array.
 *
 * @example
 * const getButtonDefaultProps = ({ fontSize, ...restProps }) => ({
 *   fontSize: migrateStringToRwdValue(fontSize),
 *   ...restProps,
 *  });
 */
export const migrateStringToRwdValue = (value) => {
  return Array.isArray(value) ? value : R.repeat(value, 3);
};

export const mergeRWDToStringValue = (value, defaultValue) => {
  return value
    ? Array.isArray(value)
      ? R.findLast((x) => !R.isNil(x))(value) || defaultValue
      : value
    : defaultValue;
};

export const updateRWDValue = (
  currentDeviceType: DEVICE_TYPE = DEVICE_TYPE.Desktop,
  value: RWDValueProps,
  defaultValue
) => {
  return R.equals(defaultValue, DEFAULT_RWD_VALUES)
    ? [value, value, value]
    : R.update(
        BREAKPOINT_INDEX_BY_DEVICE[currentDeviceType],
        value,
        defaultValue
      );
};

export const getValueByDevice = (
  device: DEVICE_TYPE,
  RWDValue: RWDValueProps
) =>
  Array.isArray(RWDValue)
    ? R.nth(BREAKPOINT_INDEX_BY_DEVICE[device])(RWDValue)
    : RWDValue;
