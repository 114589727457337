import {
  AccordionElementIcon,
  AddToCartElementIcon,
  ButtonElementIcon,
  CollectionBoxElementIcon,
  CollectionDescriptionElementIcon,
  CollectionDetailsElementIcon,
  CollectionImageElementIcon,
  CollectionProductsElementIcon,
  CollectionTitleElementIcon,
  // CollectionViewMoreElementIcon,
  ColumnsElementIcon,
  ContactFormButtonElementIcon,
  ContactFormElementIcon,
  ContactFormFieldElementIcon,
  CustomerFormButtonElementIcon,
  CustomerFormElementIcon,
  CustomerFormFieldElementIcon,
  GridElementIcon,
  HeadingElementIcon,
  HtmlElementIcon,
  IconElementIcon,
  ImageElementIcon,
  PopupElementIcon,
  ProductBoxElementIcon,
  ProductDesciptionElementIcon,
  ProductDetailsElementIcon,
  ProductImageElementIcon,
  ProductPriceElementIcon,
  ProductQuantityElementIcon,
  ProductTitleElementIcon,
  ProductVariantElementIcon,
  ProductVendorElementIcon,
  // ProductViewMoreElementIcon,
  SectionElementIcon,
  SeparatorElementIcon,
  SlideshowElementIcon,
  TablesElementIcon,
  TabsElementIcon,
  TextElementIcon,
  VideoElementIcon,
} from './../../components/Icons';
import {
  ComponentType,
  Element,
  AdditionalTooltipType,
  AdditionalTooltipAction,
} from './types';
import * as R from 'ramda';
import {
  RiAddLine,
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiSubtractLine,
} from 'react-icons/ri';
import getNumberText from '../../utils/getNumberText';
import { DROP_ELEMENT_RESULT } from '../../utils/editorValidation';
import { BORDER_STYLE } from './styleSettings/Border';
import { CUSTOMER_FORM_DOCUMENT_URL } from './components/form/customerForm/share';
import { FORM_ELEMENT_SPACING } from './components/form/constants';
import { CONTACT_FORM_DOCUMENT_URL } from './components/form/contactForm/share';

export const DEFAULT_RWD_VALUES = {
  display: R.repeat('block', 3),
  margin: R.repeat('0px', 3),
  marginTop: R.repeat('0px', 3),
  marginRight: R.repeat('0px', 3),
  marginBottom: R.repeat('0px', 3),
  marginLeft: R.repeat('0px', 3),
  padding: R.repeat('0px', 3),
  paddingTop: R.repeat('0px', 3),
  paddingRight: R.repeat('0px', 3),
  paddingBottom: R.repeat('0px', 3),
  paddingLeft: R.repeat('0px', 3),
  borderColor: R.repeat('#000000', 3),
  borderWidth: R.repeat('0px', 3),
  borderStyle: R.repeat(BORDER_STYLE.SOLID, 3),
  borderRadius: R.repeat('0px', 3),
  boxShadow: R.repeat('unset', 3),
  opacity: R.repeat('1', 3),
  className: R.repeat('', 3),
};

export enum ELEMENT_CATEGORY {
  Structure = 'Structure',
  Content = 'Content',
  Conditional = 'Conditional Element',
  ShopifyProductElement = 'Shopify Product Element',
  ShopifyCollectionElement = 'Shopify Collection Element',
  ShopifyFormElement = 'Shopify Form Element',
}

export enum ELEMENT_TOOLTIP {
  DEFAULT = 'Drag & drop to add element',
  DISABLED_PRODUCT_ELEMENT = 'This element can only be added as child element of Product Details or Product element.',
  DISABLED_COLLECTION_ELEMENT = 'This element can only be added as child element of Collection Details or Collection element.',
  DISABLED_CUSTOMER_FORM_ELEMENT = 'This element can only be added as child element of the Customer Form element.',
  DISABLED_CONTACT_FORM_ELEMENT = 'This element can only be added as child element of the Contact Form element.',
  CUSTOMER_FORM_FIELD = '"Customer Form Field" can only be used in Customer Forms.',
  CUSTOMER_FORM_BUTTON = '"Customer Form Button" can only be used in Customer Forms.',
  CONTACT_FORM_FIELD = '"Contact Form Field" can only be used in Contact Forms.',
  CONTACT_FORM_BUTTON = '"Contact Form Button" can only be used in Contact Forms.',
  VIEW_DOCUMENT = 'View help documentation',
}

export const ELEMENT_BY_COMPONENT_ID: { [x: string]: Element } = {
  section: {
    title: 'Section',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'section',
    defaultProps: {
      marginLeft: R.repeat('auto', 3),
      marginRight: R.repeat('auto', 3),
    },
    icon: SectionElementIcon,
    componentType: ComponentType.component,
  },
  row: {
    title: 'Columns',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'row',
    icon: ColumnsElementIcon,
    componentType: ComponentType.component,
    hidden: true,
  },
  grid: {
    title: 'Grid',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'grid',
    icon: GridElementIcon,
    componentType: ComponentType.component,
  },
  tabs: {
    title: 'Tabs',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'tabs',
    icon: TabsElementIcon,
    componentType: ComponentType.component,
  },
  tables: {
    title: 'Tables',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'tables',
    icon: TablesElementIcon,
    componentType: ComponentType.component,
  },
  slider: {
    title: 'Slider',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'slider',
    defaultProps: {
      marginLeft: R.repeat('auto', 3),
      marginRight: R.repeat('auto', 3),
    },
    icon: SlideshowElementIcon,
    componentType: ComponentType.component,
  },
  accordion: {
    title: 'Accordion',
    category: ELEMENT_CATEGORY.Structure,
    componentId: 'accordion',
    icon: AccordionElementIcon,
    componentType: ComponentType.component,
  },
  heading: {
    title: 'Heading',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'heading',
    icon: HeadingElementIcon,
    componentType: ComponentType.component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  text: {
    title: 'Text',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'text',
    icon: TextElementIcon,
    componentType: ComponentType.component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  button: {
    title: 'Button',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'button',
    icon: ButtonElementIcon,
    componentType: ComponentType.component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  image: {
    title: 'Image',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'image',
    icon: ImageElementIcon,
    componentType: ComponentType.component,
    defaultProps: {
      borderStyle: DEFAULT_RWD_VALUES.borderStyle,
      borderColor: DEFAULT_RWD_VALUES.borderColor,
    },
  },
  video: {
    title: 'Video',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'video',
    icon: VideoElementIcon,
    componentType: ComponentType.component,
  },
  icon: {
    title: 'Icon',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'icon',
    icon: IconElementIcon,
    componentType: ComponentType.component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  separator: {
    title: 'Separator',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'separator',
    defaultProps: {
      marginTop: ['20px', '20px', '20px'],
      marginBottom: ['20px', '20px', '20px'],
    },
    icon: SeparatorElementIcon,
    componentType: ComponentType.component,
  },
  html: {
    title: 'HTML',
    category: ELEMENT_CATEGORY.Content,
    componentId: 'html',
    icon: HtmlElementIcon,
    componentType: ComponentType.component,
  },

  popup: {
    title: 'Pop-up',
    category: ELEMENT_CATEGORY.Conditional,
    componentId: 'popup',
    defaultProps: {
      padding: R.repeat('2%', 3),
      paddingTop: R.repeat('2%', 3),
      paddingRight: R.repeat('2%', 3),
      paddingBottom: R.repeat('2%', 3),
      paddingLeft: R.repeat('2%', 3),
    },
    icon: PopupElementIcon,
    componentType: ComponentType.component,
    isGlobal: true,
  },

  productDetails: {
    title: 'Product Details',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productDetails',
    icon: ProductDetailsElementIcon,
    componentType: ComponentType.component,
  },
  productDefaultDetails: {
    title: 'Product Default Details',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productDefaultDetails',
    icon: ProductDetailsElementIcon,
    componentType: ComponentType.component,
    hidden: true,
  },
  productBox: {
    title: 'Product',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productBox',
    icon: ProductBoxElementIcon,
    componentType: ComponentType.component,
  },
  productTitle: {
    title: 'Product Title',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productTitle',
    icon: ProductTitleElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productDescription: {
    title: 'Product Description',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productDescription',
    icon: ProductDesciptionElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productImage: {
    title: 'Product Image',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productImage',
    icon: ProductImageElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      borderStyle: DEFAULT_RWD_VALUES.borderStyle,
      borderColor: DEFAULT_RWD_VALUES.borderColor,
    },
  },
  productPrice: {
    title: 'Product Price',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productPrice',
    icon: ProductPriceElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productQuantity: {
    title: 'Product Quantity',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productQuantity',
    icon: ProductQuantityElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productVariant: {
    title: 'Product Variant',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productVariant',
    icon: ProductVariantElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productVendor: {
    title: 'Product Vendor',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productVendor',
    icon: ProductVendorElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  productAddToCart: {
    title: 'Product Add To Cart',
    category: ELEMENT_CATEGORY.ShopifyProductElement,
    componentId: 'productAddToCart',
    icon: AddToCartElementIcon,
    componentType: ComponentType.shopify_product_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  // productViewMore: {
  //   title: 'Product View More',
  //   category: ELEMENT_CATEGORY.ShopifyProductElement,
  //   componentId: 'text',
  //   defaultProps: {
  //     text: 'Product View More',
  //   },
  //   icon: ProductViewMoreElementIcon,
  //   componentType: ComponentType.shopify_product_component,
  // },
  collectionDetails: {
    title: 'Collection Details',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionDetails',
    icon: CollectionDetailsElementIcon,
    componentType: ComponentType.component,
  },
  collectionDefaultDetails: {
    title: 'Collection Default Details',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionDefaultDetails',
    icon: CollectionDetailsElementIcon,
    componentType: ComponentType.component,
    hidden: true,
  },
  collectionBox: {
    title: 'Collection',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionBox',
    icon: CollectionBoxElementIcon,
    componentType: ComponentType.component,
  },
  collectionTitle: {
    title: 'Collection Title',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionTitle',
    icon: CollectionTitleElementIcon,
    componentType: ComponentType.shopify_collection_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  collectionDescription: {
    title: 'Collection Description',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionDescription',
    icon: CollectionDescriptionElementIcon,
    componentType: ComponentType.shopify_collection_component,
    defaultProps: {
      marginTop: ['10px', '10px', '10px'],
      marginBottom: ['10px', '10px', '10px'],
    },
  },
  collectionProducts: {
    title: 'Collection Products',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionProducts',
    icon: CollectionProductsElementIcon,
    componentType: ComponentType.shopify_collection_component,
  },
  collectionImage: {
    title: 'Collection Image',
    category: ELEMENT_CATEGORY.ShopifyCollectionElement,
    componentId: 'collectionImage',
    icon: CollectionImageElementIcon,
    componentType: ComponentType.shopify_collection_component,
    defaultProps: {
      borderStyle: DEFAULT_RWD_VALUES.borderStyle,
      borderColor: DEFAULT_RWD_VALUES.borderColor,
    },
  },
  // collectionViewMore: {
  //   title: 'Collection View More',
  //   category: ELEMENT_CATEGORY.ShopifyCollectionElement,
  //   componentId: 'text',
  //   defaultProps: {
  //     text: 'Collection View More',
  //   },
  //   icon: CollectionViewMoreElementIcon,
  //   componentType: ComponentType.shopify_collection_component,
  // },

  customerForm: {
    title: 'Customer Form',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'customerForm',
    icon: CustomerFormElementIcon,
    componentType: ComponentType.component,
    additionalTooltip: {
      type: AdditionalTooltipType.QUESTION,
      label: ELEMENT_TOOLTIP.VIEW_DOCUMENT,
      action: AdditionalTooltipAction.LINK,
      link: CUSTOMER_FORM_DOCUMENT_URL,
    },
  },
  customerFormField: {
    title: 'Customer Form Field',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'customerFormField',
    icon: CustomerFormFieldElementIcon,
    componentType: ComponentType.shopify_customer_form_component,
    defaultProps: {
      padding: FORM_ELEMENT_SPACING,
      paddingTop: FORM_ELEMENT_SPACING,
      paddingRight: FORM_ELEMENT_SPACING,
      paddingBottom: FORM_ELEMENT_SPACING,
      paddingLeft: FORM_ELEMENT_SPACING,
    },
    additionalTooltip: {
      type: AdditionalTooltipType.INFO,
      label: ELEMENT_TOOLTIP.CUSTOMER_FORM_FIELD,
    },
  },
  customerFormButton: {
    title: 'Customer Form Button',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'customerFormButton',
    icon: CustomerFormButtonElementIcon,
    componentType: ComponentType.shopify_customer_form_component,
    defaultProps: {
      marginTop: FORM_ELEMENT_SPACING,
      marginBottom: FORM_ELEMENT_SPACING,
    },
    additionalTooltip: {
      type: AdditionalTooltipType.INFO,
      label: ELEMENT_TOOLTIP.CUSTOMER_FORM_BUTTON,
    },
  },
  contactForm: {
    title: 'Contact Form',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'contactForm',
    icon: ContactFormElementIcon,
    componentType: ComponentType.component,
    additionalTooltip: {
      type: AdditionalTooltipType.QUESTION,
      label: ELEMENT_TOOLTIP.VIEW_DOCUMENT,
      action: AdditionalTooltipAction.LINK,
      link: CONTACT_FORM_DOCUMENT_URL,
    },
  },
  contactFormField: {
    title: 'Contact Form Field',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'contactFormField',
    icon: ContactFormFieldElementIcon,
    componentType: ComponentType.shopify_contact_form_component,
    defaultProps: {
      padding: FORM_ELEMENT_SPACING,
      paddingTop: FORM_ELEMENT_SPACING,
      paddingRight: FORM_ELEMENT_SPACING,
      paddingBottom: FORM_ELEMENT_SPACING,
      paddingLeft: FORM_ELEMENT_SPACING,
    },
    additionalTooltip: {
      type: AdditionalTooltipType.INFO,
      label: ELEMENT_TOOLTIP.CONTACT_FORM_FIELD,
    },
  },
  contactFormButton: {
    title: 'Contact Form Button',
    category: ELEMENT_CATEGORY.ShopifyFormElement,
    componentId: 'contactFormButton',
    icon: ContactFormButtonElementIcon,
    componentType: ComponentType.shopify_contact_form_component,
    defaultProps: {
      marginTop: FORM_ELEMENT_SPACING,
      marginBottom: FORM_ELEMENT_SPACING,
    },
    additionalTooltip: {
      type: AdditionalTooltipType.INFO,
      label: ELEMENT_TOOLTIP.CONTACT_FORM_BUTTON,
    },
  },
};

const ELEMENTS = R.values(ELEMENT_BY_COMPONENT_ID);
export const ELEMENTS_BY_CATEGORY: { [x: string]: Element[] } = R.pipe(
  R.filter<Element, any>((element) => !element.hidden),
  R.groupBy(R.prop('category'))
)(ELEMENTS);
const GLOBAL_ELEMENTS = R.filter((x: Element) =>
  R.propOr(false, 'isGlobal', x)
)(ELEMENTS);
export const GLOBAL_ELEMENT_IDS = R.pluck('componentId')(GLOBAL_ELEMENTS);

export enum HEADING_TAGS {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}
export const HEADING_TAG_FONT_SIZES: Record<HEADING_TAGS, string> = {
  [HEADING_TAGS.h1]: '32px',
  [HEADING_TAGS.h2]: '24px',
  [HEADING_TAGS.h3]: '18px',
  [HEADING_TAGS.h4]: '16px',
  [HEADING_TAGS.h5]: '14px',
  [HEADING_TAGS.h6]: '12px',
};
export const HEADING_TAG_OPTIONS = R.keys(HEADING_TAGS);

export enum ALIGNMENT {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
export const ALIGNMENT_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(ALIGNMENT));

export enum FONT {
  Arial = 'Arial',
  'Courier New' = 'Courier New',
  Helvetica = 'Helvetica',
  Impact = 'Impact',
  'Times New Roman' = 'Times New Roman',
  'Trebuchet MS' = 'Trebuchet MS',
  Verdana = 'Verdana',
}
export const FONT_OPTIONS = R.keys(FONT);

export enum FONT_WEIGHT {
  'Thin 100' = '100',
  'Extra Light 200' = '200',
  'Light 300' = '300',
  'Regular 400' = '400',
  'Medium 500' = '500',
  'Semi Bold 600' = '600',
  'Bold 700' = '700',
  'Extra Bold 800' = '800',
  'Heavy 900' = '900',
}
export const FONT_WEIGHT_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(FONT_WEIGHT));
export const FONT_WEIGHT_OPTION_BY_VALUE = {
  ...R.indexBy(R.prop('value'), FONT_WEIGHT_OPTIONS),
  // migration of original font weight:
  Light: { name: 'Light 300', value: '300' },
  Normal: { name: 'Regular 400', value: '400' },
  Bold: { name: 'Bold 700', value: '700' },
};

export const DEFAULT_LINE_HEIGHT = 1.5;

export enum BUTTON_STYLE {
  Fill = 'Fill',
  Unfill = 'Unfill',
  'Rounded Fill' = 'Rounded Fill',
  'Rounded Unfill' = 'Rounded Unfill',
  Link = 'Link',
}

export const BUTTON_STYLE_OPTIONS = R.keys(BUTTON_STYLE);

export enum IMAGE_SIZE {
  Fill = 'Fill',
  Fit = 'Fit',
  Cover = 'Cover',
}
export const IMAGE_SIZE_OPTIONS = R.keys(IMAGE_SIZE);
export const IMAGE_SIZE_TO_OBJECT_FIT = {
  [IMAGE_SIZE.Fill]: 'unset',
  [IMAGE_SIZE.Fit]: 'unset',
  [IMAGE_SIZE.Cover]: 'cover',
};

export enum HORIZONTAL_ALIGNMENT {
  Left = 'flex-start',
  Center = 'center',
  Right = 'flex-end',
}
export const HORIZONTAL_ALIGNMENT_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(HORIZONTAL_ALIGNMENT));
export enum VERTICAL_ALIGNMENT {
  Top = 'flex-start',
  Middle = 'center',
  Bottom = 'flex-end',
}
export const VERTICAL_ALIGNMENT_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(VERTICAL_ALIGNMENT));
export enum PRICE_POSITION {
  'Left to Price' = 'LeftToPrice',
  'Right to Price' = 'RightToPrice',
  'Above Price' = 'AbovePrice',
  'Below Price' = 'BelowPrice',
}
export const PRICE_POSITION_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(PRICE_POSITION));
export const PRICE_POSITION_OPTION_BY_VALUE = R.indexBy(
  R.prop('value'),
  PRICE_POSITION_OPTIONS
);

export enum LABEL_POSITION {
  Top = 'column',
  Left = 'row',
}
export const LABEL_POSITION_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(LABEL_POSITION));

export enum ARRANGEMENT {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}
export const ARRANGEMENT_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(ARRANGEMENT));

export enum ACCORDION_ICON_TYPE {
  None = 'None',
  Arrow = 'Arrow',
  Plus = 'Plus',
}
export const ACCORDION_ICON_TYPE_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(ACCORDION_ICON_TYPE));
export const ACCORDION_ICON_PAIR_BY_TYPE = {
  [ACCORDION_ICON_TYPE.Arrow]: {
    opened: RiArrowDropUpLine,
    closed: RiArrowDropDownLine,
  },
  [ACCORDION_ICON_TYPE.Plus]: {
    opened: RiSubtractLine,
    closed: RiAddLine,
  },
};

export enum ASPECT_RATIO {
  HIGH = '56.25%',
  LOW = '75%',
}

export const ASPECT_RATIO_OPTIONS = R.map((x: string[]) => ({
  label: x[1] === ASPECT_RATIO.HIGH ? '16:9' : '4:3',
  value: x[1],
}))(R.toPairs(ASPECT_RATIO));

export enum BLOCK_TAB {
  Element,
  Library,
  Snippet,
}

export enum LIBRARY_CATEGORY_TYPE {
  Page = 'PAGE',
  Block = 'BLOCK',
}

export const LIBRARY_CATEGORY_TYPE_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(LIBRARY_CATEGORY_TYPE));

export enum DEVICE_TYPE {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}
export const DEFAULT_DEVICE_TYPE = DEVICE_TYPE.Desktop;

export enum BREAKPOINT {
  mobile = '576px',
  tablet = '768px',
  desktop = '992px',
}

export enum BREAKPOINT_INDEX_BY_DEVICE {
  mobile = 0,
  tablet = 1,
  desktop = 2,
}

export const DEFAULT_BREAKPOINT_INDEX = BREAKPOINT_INDEX_BY_DEVICE['mobile'];

export const RESPONSIVE_OBJECT = {
  base: DEFAULT_BREAKPOINT_INDEX,
  0: BREAKPOINT_INDEX_BY_DEVICE['mobile'],
  1: BREAKPOINT_INDEX_BY_DEVICE['tablet'],
  2: BREAKPOINT_INDEX_BY_DEVICE['desktop'],
};

export const MAX_WIDTH_BY_DEVICE = {
  [DEVICE_TYPE.Desktop]: 'none',
  [DEVICE_TYPE.Tablet]: `${
    +getNumberText(BREAKPOINT[DEVICE_TYPE.Desktop]) - 1
  }px`,
  [DEVICE_TYPE.Mobile]: BREAKPOINT[DEVICE_TYPE.Mobile],
};
export const MIN_WIDTH_BY_DEVICE = {
  [DEVICE_TYPE.Desktop]: BREAKPOINT[DEVICE_TYPE.Desktop],
  [DEVICE_TYPE.Tablet]: BREAKPOINT[DEVICE_TYPE.Tablet],
  [DEVICE_TYPE.Mobile]: 'none',
};

export enum STYLE {
  'Style A' = 'style-a',
  'Style B' = 'style-b',
  'Style C' = 'style-c',
  None = 'style-none',
}
export const STYLE_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(STYLE));
export const STYLE_OPTION_BY_VALUE = R.indexBy(R.prop('value'), STYLE_OPTIONS);

export enum POSITION {
  LEFT_TOP = 'Top Left',
  CENTER_TOP = 'Top Center',
  RIGHT_TOP = 'Top Right',
  LEFT_CENTER = 'Center Left',
  CENTER_CENTER = 'Center Center',
  RIGHT_CENTER = 'Center Right',
  LEFT_BOTTOM = 'Bottom Left',
  CENTER_BOTTOM = 'Bottom Center',
  RIGHT_BOTTOM = 'Bottom Right',
}
export const POSITION_VALUES = R.values(POSITION);
export enum POSITION_ALIGNMENT {
  Top = 'flex-start',
  Center = 'center',
  Bottom = 'flex-end',
}

export enum CLOSE_BUTTON_POSITION {
  Inside = 'inside',
  Outside = 'outside',
}
export const CLOSE_BUTTON_POSITION_OPTIONS = R.map((x: string[]) => ({
  label: x[0],
  value: x[1],
}))(R.toPairs(CLOSE_BUTTON_POSITION));

export enum DISPLAY_TIMING {
  'None' = 'none',
  'Show on page load' = 'page load',
  'Show after a period of time' = 'period',
  'Show when user scrolls through a % of the page' = 'scroll percentage',
  'Show when user reaches the bottom of the page' = 'page bottom',
}
export const DISPLAY_TIMING_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(DISPLAY_TIMING));
export const DISPLAY_TIMING_OPTION_BY_VALUE = R.indexBy(
  R.prop('value'),
  DISPLAY_TIMING_OPTIONS
);

export enum CUSTOMER_DETAILS {
  Email = 'Email',
  'First Name' = 'First Name',
  'Last Name' = 'Last Name',
}
export const CUSTOMER_DETAILS_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(CUSTOMER_DETAILS));
export const CUSTOMER_DETAILS_OPTION_BY_VALUE = R.indexBy(
  R.prop('value'),
  CUSTOMER_DETAILS_OPTIONS
);

export enum INPUT_TYPE {
  'Email' = 'Email',
  'Single Line Text' = 'Single Line Text',
  'Multiple Line Text' = 'Multiple Line Text',
  'Single Choice' = 'Single Choice',
  'Multiple Choices' = 'Multiple Choices',
  'Dropdown' = 'Dropdown',
}
export const INPUT_TYPE_OPTIONS = R.map((x: string[]) => ({
  name: x[0],
  value: x[1],
}))(R.toPairs(INPUT_TYPE));
export const INPUT_TYPE_OPTION_BY_VALUE = R.indexBy(
  R.prop('value'),
  INPUT_TYPE_OPTIONS
);

export const DEBOUNCE_UPDATE_TIME = 200;

export const CONTAINER_COMPONENT_IDS = [
  'slider',
  'row',
  'section',
  'collectionBox',
  'productBox',
  'grid',
  'customerForm',
  'contactForm',
];

export const IMAGE_URL = 'https://media.layoutbase.com/';
export const IMAGE_SIZE_LIMIT = 5;

export const IFRAME_ID = 'editorIFrame';

export const ROOT_PATH = [0];

export const POPUP_MAXIMUM = 5;

const OVERLAPPED_ERROR_MESSAGE = 'Elements cannot overlapped.';
export const ERROR_MESSAGE_BY_DROP_RESULT: {
  [key in keyof typeof DROP_ELEMENT_RESULT]: string;
} = {
  [DROP_ELEMENT_RESULT.SUCCESS]: '',
  [DROP_ELEMENT_RESULT.NOT_ACCEPTED_BY_IMAGE]:
    'Only Header, Text, Icon, and Button element are allowed.',
  [DROP_ELEMENT_RESULT.NOT_ACCEPTED_BY_PRODUCT_IMAGE]:
    'This element is not compatible with Product Image.',
  [DROP_ELEMENT_RESULT.NOT_ACCEPTED_BY_COLLECTION_IMAGE]:
    'This element is not compatible with Collection Image.',
  [DROP_ELEMENT_RESULT.EXCEED_MAXIMUM_OF_GRIDS_LAYERS]:
    'Maximum one Grid element can be dropped here.',
  [DROP_ELEMENT_RESULT.EXCEED_MAXIMUM_OF_POPUP]: `Maximum ${POPUP_MAXIMUM} pop-ups`,
  [DROP_ELEMENT_RESULT.PRODUCT_ELEMENT_OUTSIDE_PRODUCT_BOX]: '',
  [DROP_ELEMENT_RESULT.COLLECTION_ELEMENT_OUTSIDE_COLLECTION_BOX]: '',
  [DROP_ELEMENT_RESULT.OVERLAPPED_PRODUCT_BOX]: OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.PRODUCT_DETAILS_INCOMPATIBLE_WITH_PRODUCT_BOX]:
    OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.OVERLAPPED_COLLECTION_BOX]: OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.COLLECTION_DETAILS_INCOMPATIBLE_WITH_COLLECTION_BOX]:
    OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.PRODUCT_BOX_OVERLAP_WITH_COLLECTION_BOX]:
    OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.COLLECTION_BOX_OVERLAP_WITH_PRODUCT_BOX]:
    OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.OVERLAPPED_CUSTOMER_FORM]: OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.OVERLAPPED_CONTACT_FORM]: OVERLAPPED_ERROR_MESSAGE,
  [DROP_ELEMENT_RESULT.FORM_ELEMENT_OUTSIDE_FORM_BOX]: '',
};

export enum SETTING_TOOLTIP {
  FONT_WEIGHT = 'Effectiveness of font weight depends on the weight availability of the font chosen.',
  SLIDER_AUTOPLY = 'This feature only works on preview and live page.',
  PRODUCT_COMPARE_AT_PRICE = 'Show or hide Compare at Price when product is on sale.',
  POPUP_DISPLAY_FREQUENCY = 'Pop-up will be show all the time if set to zero.',
  VIDEO_MUTE_AUDIO = 'This feature only works on Saved Preview Link and Published Live Page.',
}

export const BORDER_STYLE_KEYS = [
  'borderColor',
  'borderStyle',
  'borderWidth',
  'borderRadius',
];
