import {
  Box,
  Button,
  Center,
  ChakraProps,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { RiInformationLine, RiSearch2Line } from 'react-icons/ri';
import { NotFoundImage } from '../FallbackImage';
import { useMachine } from '@xstate/react';
import {
  shopifyItemListMachine,
  SHOPIFY_ITEM_LIST_EVENT,
  SHOPIFY_ITEM_LIST_STATE,
} from './shopifyItemListMachine';
import {
  Collection,
  PageType,
  Product,
  ProductStatus,
} from '../../apollo/types.generate';
import * as R from 'ramda';
import { useUserQuery } from '../../apollo/apollo.generate';
import ProductStatusBadge from './ProductStatusBadge';
import ProductStatusAlert from './ProductStatusAlert';

type ShopifyItemBarProps = ChakraProps & {
  type?: PageType.Product | PageType.Collection;
  id: string;
  handle: string;
  imageSrc?: string | null;
  title: string;
  pageId?: string | null;
  status?: ProductStatus | null;
  publishedAt?: Date | null;
  renderItemButton?: (item: Product | Collection) => React.ReactNode;
};

export const ShopifyItemBar = (props: ShopifyItemBarProps) => {
  const {
    type,
    id,
    handle,
    imageSrc,
    title,
    pageId,
    status,
    publishedAt,
    renderItemButton,
    ...rest
  } = props;

  return (
    <Flex align="center" p="16px" {...rest}>
      <Image
        borderRadius="4px"
        boxSize="60px"
        mr="16px"
        src={imageSrc || ''}
        fallback={<NotFoundImage />}
        objectFit="cover"
      />
      <Heading as="h4" fontSize="14px" mr="auto" w="450px" noOfLines={2}>
        {title}
      </Heading>
      {type === PageType.Product && status && (
        <ProductStatusBadge
          mr="32px"
          status={status}
          publishedAt={publishedAt}
        />
      )}
      {renderItemButton &&
        renderItemButton(
          R.pick(['id', 'handle', 'imageSrc', 'title', 'pageId'], props)
        )}
    </Flex>
  );
};

type ShopifyItemListProps = ChakraProps & {
  type?: PageType.Product | PageType.Collection;
  renderItemButton: (item: Product | Collection) => React.ReactNode;
};
const ShopifyItemList = ({
  type,
  renderItemButton,
  ...rest
}: ShopifyItemListProps) => {
  const [state, send] = useMachine(shopifyItemListMachine, {
    context: {
      type,
    },
  });

  const { data: userData } = useUserQuery();

  return (
    <Flex h="full" direction="column" gap="16px" {...rest}>
      {state.context.type === PageType.Product && (
        <ProductStatusAlert h="48px" />
      )}
      <Flex
        flex="1"
        minH="0"
        flexDir="column"
        borderRadius="4px"
        border="1px solid"
        borderColor="border"
      >
        <HStack
          h="74px"
          p="16px"
          justify="space-between"
          borderBottom="1px solid"
          borderColor="border"
        >
          <Flex alignItems="center">
            {state.matches(SHOPIFY_ITEM_LIST_STATE.LOADED) && (
              <>
                <Text>
                  {`Showing ${state.context.items?.length} of ${
                    state.context.itemsCount
                  } ${state.context.type?.toLowerCase()}(s)`}
                </Text>
                {state.context.items?.length > 0 && (
                  <Tooltip
                    label={`This may not be the full list of your products, search for ${state.context.type?.toLowerCase()} if you couldn't find it below.`}
                    shouldWrapChildren
                    placement="top"
                  >
                    <Icon
                      display="inherit"
                      as={RiInformationLine}
                      boxSize="24px"
                      color="icon"
                    />
                  </Tooltip>
                )}
              </>
            )}
          </Flex>
          <Box w="258px" overflowY="auto">
            <InputGroup>
              <Input
                type="text"
                placeholder={`Search by ${state.context.type?.toLowerCase()} name`}
                onChange={(e) => {
                  send({
                    type: SHOPIFY_ITEM_LIST_EVENT.SEARCH,
                    data: { keyword: e.target.value },
                  });
                }}
              />
              <InputRightElement
                fontSize="20px"
                color="icon"
                pointerEvents="none"
                children={<RiSearch2Line />}
              />
            </InputGroup>
          </Box>
        </HStack>
        <Box flex="1" overflow="auto">
          {(state.matches(SHOPIFY_ITEM_LIST_STATE.LOADING_ITEMS_COUNT) ||
            state.matches(SHOPIFY_ITEM_LIST_STATE.LOADING)) && (
            <Center h="full">
              <Spinner color="primary" />
            </Center>
          )}
          {state.matches(SHOPIFY_ITEM_LIST_STATE.EMPTY) && (
            <Center h="full" flexDirection="column">
              {state.context.keyword ? (
                <>
                  <Text color="black">No matches found,</Text>
                  <Text color="black">
                    please try again with some different keywords.
                  </Text>
                </>
              ) : (
                <>
                  <Text color="black">
                    No {type?.toLowerCase()} found, please add in your Shopify
                    store.
                  </Text>
                  <Button
                    size="sm"
                    mt="16px"
                    textTransform="capitalize"
                    as={Link}
                    href={
                      userData
                        ? `//${
                            userData.user.currentStore
                          }/admin/${type?.toLowerCase()}s`
                        : ''
                    }
                    isExternal
                  >
                    Go to Shopify Store {type?.toLowerCase()} Page
                  </Button>
                </>
              )}
            </Center>
          )}
          {state.matches(SHOPIFY_ITEM_LIST_STATE.LOADED) && (
            <>
              {state.context.items?.map((item) => (
                <ShopifyItemBar
                  type={type}
                  key={item.id}
                  borderBottom="1px solid"
                  borderColor="border"
                  {...item}
                  renderItemButton={renderItemButton}
                />
              ))}
              <Flex py="16px" justifyContent="center">
                <Text>
                  {`End of list, try search for ${state.context.type?.toLowerCase()} if you couldn’t find it.`}
                </Text>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
export default ShopifyItemList;
