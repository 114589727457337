import { useContext } from 'react';
import { createContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { DEFAULT_RWD_VALUES } from '../../../constants';
import { SUBMIT_FAIL_MESSAGE, SUBMIT_SUCCESS_MESSAGE } from '../constants';

export const CONTACT_FORM_DOCUMENT_URL =
  'https://www.layoutbase.com/post/shopify-elements-contact-form';

export type ContactFormProps = {
  submitSuccessMessage?: string;
  submitFailMessage?: string;
  borderColor?: string[];
};

export const getContactFormDefaultProps = <T extends ContactFormProps>({
  submitSuccessMessage = SUBMIT_SUCCESS_MESSAGE,
  submitFailMessage = SUBMIT_FAIL_MESSAGE,
  borderColor = DEFAULT_RWD_VALUES.borderColor,
  ...restProps
}: T) => ({
  submitSuccessMessage,
  submitFailMessage,
  borderColor,
  ...restProps,
});

const ContactFormContext = createContext<UseFormReturn | undefined>(undefined);
export const ContactFormProvider = ({ children }) => {
  const form = useForm({ mode: 'onBlur' });

  return (
    <ContactFormContext.Provider value={{ ...form }}>
      {children}
    </ContactFormContext.Provider>
  );
};
export const useContactFormContext = () => useContext(ContactFormContext);
