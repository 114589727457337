import React from 'react';
import { Center, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Center
      zIndex="sticky"
      w="full"
      bgColor="white"
      h="50px"
      borderTop="1px solid"
      borderColor="line"
    >
      <Text fontSize="12px">
        Copyright© {new Date().getFullYear()} Layoutbase. All Rights Reserved.
      </Text>
    </Center>
  );
};

export default Footer;
