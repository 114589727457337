import { Box, Flex } from '@chakra-ui/layout';
import { useCallback, useState, useMemo, useEffect, memo } from 'react';
import { ColorPickerWithInput } from '../components/shared/colorPicker';
import { ColorSchemaType } from '../components/shared/colorPicker/types';
import * as R from 'ramda';
import { Slider, SliderFilledTrack, SliderTrack } from '@chakra-ui/slider';
import InputWithUnit from './InputWithUnit';
import { z } from 'zod';
import Subtitle from './Subtitle';
import { RadioCardGroup } from '../../../components/RadioCard';
import { convertRWDValue } from '../../../utils/getRWDValue';

export const BORDER_STYLE = {
  SOLID: 'solid',
  DASHED: 'dashed',
  DOTTED: 'dotted',
};
const BORDER_STYLE_OPTIONS = R.map((value: string) => ({
  value: value,
  renderComponent: (isChecked: boolean) => (
    <Box
      w={10}
      borderTop={`${value} 2px`}
      borderColor={isChecked ? 'white' : 'black'}
    />
  ),
}))(R.values(BORDER_STYLE));

const RADIUS_CORNER = {
  TOP_LEFT: 0,
  TOP_RIGHT: 1,
  BOTTOM_RIGHT: 2,
  BOTTOM_LEFT: 3,
  ALL: 4,
};

const DEFAULT_RADIUS_VALUE = '0px';
const DEFAULT_RADIUS_VALUES = ['', '', '', ''];

const BorderStyleSchema = z
  .string()
  .optional()
  .default(BORDER_STYLE.SOLID)
  .refine((x) => R.values(BORDER_STYLE).includes(x));

const BorderWidthSchema = z
  .string()
  .optional()
  .default('0px')
  .transform((x) => parseInt(x));

const BorderRadiusSchema = z
  .string()
  .optional()
  .default('none')
  .refine((x) => {
    return x === 'none' || x.split(' ').length === 4;
  })
  .transform((x) => {
    return x === 'none' ? DEFAULT_RADIUS_VALUES : x.split(' ');
  });

const Border = ({
  borderColor: color,
  borderWidth: width,
  borderStyle: style,
  borderRadius: radius,
  updateProps,
  deviceIndex,
  hideBorderSettings = false,
}) => {
  const [borderColor, borderWidth, borderStyle, borderRadius] = useMemo(
    () => [
      convertRWDValue(color, 'borderColor') as string[],
      convertRWDValue(width, 'borderWidth') as string[],
      convertRWDValue(style, 'borderStyle') as string[],
      convertRWDValue(radius, 'borderRadius') as string[],
    ],
    [color, radius, style, width]
  );

  const borderStyleResult = BorderStyleSchema.safeParse(
    borderStyle[deviceIndex]
  );
  const borderStyleData = borderStyleResult.success
    ? borderStyleResult.data
    : BORDER_STYLE.SOLID;

  const borderWidthResult = BorderWidthSchema.safeParse(
    borderWidth[deviceIndex]
  );
  const borderWidthData = borderWidthResult.success
    ? borderWidthResult.data
    : 0;

  const borderRadiusResult = BorderRadiusSchema.safeParse(
    borderRadius[deviceIndex]
  );
  const borderRadiusData = borderRadiusResult.success
    ? borderRadiusResult.data
    : DEFAULT_RADIUS_VALUES;

  const [allCornerRadius, setAllCornerRadius] = useState('');

  useEffect(() => {
    if (
      borderRadiusData !== DEFAULT_RADIUS_VALUES &&
      R.equals(R.length(R.uniq(borderRadiusData)), 1)
    ) {
      setAllCornerRadius(borderRadiusData[0]);
    } else {
      setAllCornerRadius('');
    }
  }, [borderRadius, borderRadiusData, borderRadiusResult, deviceIndex]);

  const updateColor = useCallback(
    (color: ColorSchemaType) => {
      updateProps(() => ({
        borderColor: R.update(deviceIndex, color, borderColor),
      }));
    },
    [borderColor, updateProps, deviceIndex]
  );

  const updateRadius = useCallback(
    (corner) => (value) => {
      let currentValue = value || DEFAULT_RADIUS_VALUE;
      let radius;
      if (R.equals(corner, RADIUS_CORNER.ALL)) {
        radius = R.map(() => currentValue, DEFAULT_RADIUS_VALUES).join(' ');
        setAllCornerRadius(currentValue);
      } else {
        const newValues = R.update(corner, currentValue)(borderRadiusData);
        radius = R.map((x) => x || allCornerRadius || DEFAULT_RADIUS_VALUE)(
          newValues
        ).join(' ');
        setAllCornerRadius('');
      }

      updateProps(() => ({
        borderRadius: R.update(deviceIndex, radius, borderRadius),
      }));
    },
    [allCornerRadius, borderRadius, borderRadiusData, updateProps, deviceIndex]
  );

  return (
    <Box>
      {!hideBorderSettings && (
        <>
          <Subtitle mb="8px">Border Width</Subtitle>
          <Flex align="center" mb="16px">
            <Slider
              aria-label="border-width-slider"
              value={borderWidthData}
              onChange={(width) =>
                updateProps(() => ({
                  borderWidth: R.update(deviceIndex, `${width}px`, borderWidth),
                }))
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Box
                position="absolute"
                zIndex="1"
                transform="translateY(-50%)"
                boxSize="24px"
                bg="white"
                borderRadius="full"
                boxShadow="base"
                left={`calc(${borderWidthData}% - 12px)`}
              />
            </Slider>
            <Box ml="16px">
              <InputWithUnit
                value={`${borderWidthData || ''}px`}
                onChange={(value) =>
                  updateProps(() => ({
                    borderWidth: R.update(deviceIndex, value, borderWidth),
                  }))
                }
                align="center"
                isFitted={false}
                disableUnit
              />
            </Box>
          </Flex>

          <Subtitle mb="8px">Border Color</Subtitle>
          <Flex mb="16px">
            <ColorPickerWithInput
              fallbackColor="unset"
              color={borderColor[deviceIndex] || undefined}
              onChange={updateColor}
              onInputChange={(value) => {
                updateProps(() => ({
                  borderColor: R.update(deviceIndex, value, borderColor),
                }));
              }}
            />
          </Flex>
        </>
      )}

      <Subtitle mb="8px">Border Style</Subtitle>

      <RadioCardGroup
        name="boreder-style"
        mb="16px"
        value={borderStyleData}
        options={BORDER_STYLE_OPTIONS}
        onChange={(style) =>
          updateProps(() => ({
            borderStyle: R.update(deviceIndex, style, borderStyle),
          }))
        }
      />

      <Subtitle mb="8px">Border Radius</Subtitle>
      <Box position="relative">
        <Box
          position="absolute"
          border="dashed 1px"
          borderColor="line"
          w="172px"
          h="116px"
          top="21px"
          left="42px"
          bg="background"
        />
        <Flex direction="column" position="relative">
          <Flex justify="space-between">
            <InputWithUnit
              value={borderRadiusData[RADIUS_CORNER.TOP_LEFT]}
              align="center"
              isFitted={false}
              onChange={updateRadius(RADIUS_CORNER.TOP_LEFT)}
            />
            <InputWithUnit
              value={borderRadiusData[RADIUS_CORNER.TOP_RIGHT]}
              align="center"
              isFitted={false}
              onChange={updateRadius(RADIUS_CORNER.TOP_RIGHT)}
            />
          </Flex>
          <Flex justify="center" my="16px">
            <InputWithUnit
              value={allCornerRadius}
              align="center"
              isFitted={false}
              onChange={updateRadius(RADIUS_CORNER.ALL)}
            />
          </Flex>
          <Flex justify="space-between">
            <InputWithUnit
              value={borderRadiusData[RADIUS_CORNER.BOTTOM_LEFT]}
              align="center"
              isFitted={false}
              onChange={updateRadius(RADIUS_CORNER.BOTTOM_LEFT)}
            />
            <InputWithUnit
              value={borderRadiusData[RADIUS_CORNER.BOTTOM_RIGHT]}
              align="center"
              isFitted={false}
              onChange={updateRadius(RADIUS_CORNER.BOTTOM_RIGHT)}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
export default memo(Border);
