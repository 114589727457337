import { createMachine } from 'xstate';

enum S {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  SUBMITTING = 'SUBMITTING',
}

enum E {
  OPEN = 'OPEN',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SUBMIT = 'SUBMIT',
}
export type ModalContext = {
  data: any;
};

export type ModalEvent =
  | { type: E.OPEN }
  | { type: E.CLOSE_MODAL }
  | { type: E.SUBMIT; data?: any };

export type ModalState =
  | { value: S.OPENED; context: ModalContext }
  | { value: S.CLOSED; context: ModalContext }
  | { value: S.SUBMITTING; context: ModalContext };

const modalMachine = createMachine<ModalContext, ModalEvent, ModalState>(
  {
    context: {
      data: null,
    },
    initial: S.CLOSED,
    states: {
      [S.CLOSED]: {
        on: {
          [E.OPEN]: S.OPENED,
        },
      },
      [S.OPENED]: {
        on: {
          [E.SUBMIT]: S.SUBMITTING,
          [E.CLOSE_MODAL]: S.CLOSED,
        },
      },
      [S.SUBMITTING]: {
        invoke: {
          src: 'submit',
          onDone: S.CLOSED,
          onError: {
            actions: ['notifyError'],
            target: S.OPENED,
          },
        },
      },
    },
  },
  {
    actions: {},
  }
);

export { modalMachine, S as MODAL_STATES, E as MODAL_EVENTS };
