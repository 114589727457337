import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';
import { RiCloseFill } from 'react-icons/ri';

type CloseButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  iconSize?: string;
};

export const CloseButton = ({ iconSize, ...props }: CloseButtonProps) => {
  return (
    <IconButton
      variant="close"
      icon={<Icon as={RiCloseFill} boxSize={iconSize} fontSize="inherit" />}
      aria-label="close"
      {...props}
    />
  );
};
