import * as R from 'ramda';
import { createMachine, assign, AnyEventObject } from 'xstate';
import { sdk } from '../apollo/client';
import { PageVersion, Schedule } from '../apollo/types.generate';
import { formatDate } from '../utils/formatDate';
import { createStandaloneToast } from '../components/Toast';

const toast = createStandaloneToast();

export type ScheduleContext = {
  schedules: Array<Schedule>;
  versions: Array<PageVersion>;
  publishDate: Date | null;
  versionId: string;
  pageId: string;
};

export type ScheduleEvent =
  | { type: E.UPDATE_PUBLISH_DATE; publishDate: Date | null }
  | { type: E.UPDATE_VERSION_ID; versionId: string }
  | { type: E.CREATE_SCHEDULE }
  | { type: E.DELETE_SCHEDULE; id: string }
  | { type: E.RELOAD; pageId: string };

enum S {
  CREATE_SCHEDULE_SECTION = 'CREATE_SCHEDULE_SECTION',
  SCHEDULE_HISTORY_SECTION = 'SCHEDULE_HISTORY_SECTION',
  IDLE = 'IDLE',
  UNKONWN = 'UNKONWN',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  SCHEDULES_EMPTY = 'SCHEDULE_EMPTY',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
}

enum E {
  UPDATE_PUBLISH_DATE = 'UPDATE_PUBLISH_DATE',
  UPDATE_VERSION_ID = 'UPDATE_VERSION_ID',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  RELOAD = 'RELOAD',
}

const scheduleMachine = createMachine<ScheduleContext, ScheduleEvent>(
  {
    id: 'schedule',
    context: {
      schedules: [],
      versions: [],
      publishDate: null,
      versionId: '',
      pageId: '',
    },
    initial: S.UNKONWN,
    states: {
      [S.UNKONWN]: {
        always: [{ cond: 'hasPageId', target: S.LOADING }, { target: S.IDLE }],
      },
      [S.IDLE]: {},
      [S.LOADING]: {
        id: S.LOADING,
        invoke: {
          src: 'getSchedulesAndVersions',
          onDone: { target: S.LOADED, actions: ['assignSchedulesAndVersions'] },
          onError: {
            target: S.ERROR,
          },
        },
      },
      [S.LOADED]: {
        id: S.LOADED,
        type: 'parallel',
        states: {
          [S.CREATE_SCHEDULE_SECTION]: {
            initial: S.IDLE,
            states: {
              [S.IDLE]: {
                on: {
                  [E.UPDATE_PUBLISH_DATE]: {
                    actions: ['assignPublishDate'],
                  },
                  [E.UPDATE_VERSION_ID]: {
                    actions: ['assignVersionId'],
                  },
                  [E.CREATE_SCHEDULE]: {
                    target: S.CREATE_SCHEDULE,
                  },
                },
              },
              [S.CREATE_SCHEDULE]: {
                invoke: {
                  src: 'createSchedule',
                  onDone: {
                    target: `#${S.LOADING}`,
                    actions: [
                      'notifyCreateScheduleSuccess',
                      'clearDateAndVersion',
                    ],
                  },
                  onError: {
                    target: S.IDLE,
                    actions: ['notifyCreateScheduleError'],
                  },
                },
              },
            },
          },
          [S.SCHEDULE_HISTORY_SECTION]: {
            initial: S.UNKONWN,
            states: {
              [S.UNKONWN]: {
                always: [
                  {
                    cond: 'isScheduleEmpty',
                    target: S.SCHEDULES_EMPTY,
                  },
                  {
                    target: S.IDLE,
                  },
                ],
              },
              [S.IDLE]: {
                on: {
                  [E.DELETE_SCHEDULE]: {
                    target: S.DELETE_SCHEDULE,
                  },
                },
              },
              [S.SCHEDULES_EMPTY]: {},
              [S.DELETE_SCHEDULE]: {
                invoke: {
                  src: 'deleteSchedule',
                  onDone: {
                    target: `#${S.LOADING}`,
                    actions: ['notifyDeleteScheduleSuccess'],
                  },
                  onError: {
                    target: S.IDLE,
                    actions: ['notifyDeleteScheduleError'],
                  },
                },
              },
            },
          },
        },
      },
      [S.ERROR]: {},
    },
    on: {
      [E.RELOAD]: {
        target: S.UNKONWN,
        actions: ['assignPageId', 'clearDateAndVersion'],
      },
    },
  },
  {
    guards: {
      isScheduleEmpty: ({ schedules }) => R.isEmpty(schedules),
      hasPageId: ({ pageId }) => !!pageId,
    },
    actions: {
      assignSchedulesAndVersions: assign({
        schedules: (_, event: any) => event.data.schedules,
        versions: (_, event: any) => event.data.versions,
      }),
      assignPublishDate: assign({
        publishDate: (_, event: any) => event.publishDate,
      }),
      assignVersionId: assign({
        versionId: (_, event: any) => event.versionId,
      }),
      assignPageId: assign({ pageId: (_, event: any) => event.pageId }),
      clearDateAndVersion: assign((_) => ({
        publishDate: null,
        versionId: '',
      })),
      notifyCreateScheduleSuccess: (_, { data }: AnyEventObject) =>
        toast({
          title: `Page schedule for ${formatDate(
            data.createSchedule.publishDate
          )}`,
          status: 'success',
        }),
      notifyCreateScheduleError: () =>
        toast({
          title: 'Failed to set page schedule.',
        }),
      notifyDeleteScheduleSuccess: () =>
        toast({
          title: 'Schedule deleted.',
          status: 'success',
        }),
      notifyDeleteScheduleError: () =>
        toast({
          title: 'Failed to delete schedule.',
        }),
    },
    services: {
      getSchedulesAndVersions: async (ctx) => {
        const [{ schedules }, { pageVersions }] = await Promise.all([
          sdk.schedules(
            {
              pageId: ctx.pageId,
            },
            { fetchPolicy: 'network-only' }
          ),
          sdk.pageVersions(
            {
              input: {
                id: ctx.pageId,
              },
            },
            { fetchPolicy: 'network-only' }
          ),
        ]);
        return {
          schedules,
          versions: pageVersions,
        };
      },
      createSchedule: ({ publishDate, versionId }) =>
        sdk.createSchedule({
          input: { publishDate: publishDate, pageVersionId: versionId },
        }),
      deleteSchedule: (_, event: any) => sdk.deleteSchedule({ id: event.id }),
    },
  }
);

export { scheduleMachine, S as SCHEDULE_STATES, E as SCHEDULE_EVENTS };
