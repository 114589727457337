import { Text } from '@chakra-ui/react';

const Subtitle = ({ children, ...props }) => {
  return (
    <Text
      fontSize="12px"
      lineHeight="16px"
      color="text"
      fontWeight={600}
      {...props}
    >
      {children}
    </Text>
  );
};
export default Subtitle;
