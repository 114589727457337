import {
  HStack,
  VStack,
  MenuList,
  MenuItem,
  Heading,
  Button,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Tabs,
  TabList,
  Tab,
  Text,
  Center,
  SimpleGrid,
  Link,
  Spinner,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
} from '@chakra-ui/react';
import * as R from 'ramda';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useActor, useMachine } from '@xstate/react';
import { ActorRefFrom } from 'xstate';
import { PageCard } from '../../components/PageCard';
import { Section } from '../../components/Section';
import { NoResultIcon } from '../../components/Icons';
import {
  pagesMachines,
  PAGE_EVENTS,
  PAGE_STATES,
} from '../../machines/pages/pagesMachine';
import { RiFunctionLine, RiMore2Fill, RiSearch2Line } from 'react-icons/ri';
import { FiList } from 'react-icons/fi';
import { PageStatus, PageType } from '../../apollo/types.generate';
import { GoTopIcon } from '../../components/Icons';
import {
  HomeContext,
  HomeEvent,
  homeMachine,
  HOME_EVENTS,
} from '../../machines/homeMachine';
import { MODAL_STATES, MODAL_EVENTS } from '../../machines/modalMachine';
import { CreatePageModal } from '../../components/CreatePageModal';
import { getVersionName } from '../../utils/getVersionName';
import ScheduleModal from '../../components/ScheduleModal';
import { CloseButton } from '../../components/CloseButton';
import {
  useShopifyBlogsQuery,
  useUserQuery,
  useShowGiftTeaserMutation,
  useInterviewFormQuery,
} from '../../apollo/apollo.generate';
import { getPath } from '../../utils/page';
import WelcomeBanner from '../../components/WelcomeBanner';
import SupportCard from '../../components/SupportCard';
import RequestPageBuildCard from '../../components/RequestPageBuildCard';
import StoreDesignKnowledgeCard from '../../components/StoreDesignKnowledgeCard';
import InstructionByPageType from '../../components/InstructionByPageType';
import TeaserTrigger from '../../components/TeaserTrigger';
import PromoGiftCard from '../../components/PromoGiftCard';
import InterviewFormModal from '../../components/InterviewFormModal';

const PAGE_TYPE_NAME_MAP = [
  { id: null, name: 'All' },
  {
    id: PageType.Regular,
    name: 'Regular',
  },
  {
    id: PageType.Product,
    name: 'Product',
  },
  {
    id: PageType.Collection,
    name: 'Collection',
  },
  {
    id: PageType.Post,
    name: 'Blog Post',
  },
];

const Gallery = ({ cardType, ...rest }) => {
  return (
    <SimpleGrid
      width={cardType === 'list' ? '100%' : 'auto'}
      columns={cardType === 'list' ? 1 : 3}
      spacing={cardType === 'list' ? '8px' : '24px'}
      {...rest}
    />
  );
};

const EmptyContainer = (props) => (
  <VStack mt="80px" spacing="16px" {...props} />
);

const EmptyText = (props) => (
  <Text
    w="346px"
    fontSize="16px"
    fontWeight="600"
    textAlign="center"
    {...props}
  />
);

const HomepageDialog = ({
  isCurrentHomepage,
  homepageDialogRef,
  isOpen,
  onClose,
  updateHomepage,
  isUpdatingHomepage,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={homepageDialogRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            {isCurrentHomepage ? 'Revert' : 'Set as'} Homepage
          </AlertDialogHeader>
          <CloseButton top="30px" right="24px" onClick={onClose} />
          <AlertDialogBody>
            {isCurrentHomepage ? (
              'Revert back to a previous homepage set on Layoutbase or Shopify.'
            ) : (
              <>
                Confirm to set this as your homepage.
                <br />
                <br />
                You can later revert it by selecting “Revert Homepage” under “
                <Icon as={RiMore2Fill} verticalAlign="middle" />“ (More Options
                button).
              </>
            )}
            <br />
            <br />
            <Link
              color="primary"
              textDecor="underline"
              href="https://layoutbase.helpdocs.io/article/h1r4lurcf5-setting-a-page-as-homepage"
              isExternal
            >
              <b>Learn more</b>
            </Link>
          </AlertDialogBody>
          <AlertDialogFooter px="24px">
            <Button variant="outline" ref={homepageDialogRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => updateHomepage(!isCurrentHomepage)}
              isLoading={isUpdatingHomepage}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
const ToolBar = ({ searchText, cardType, changeCardType }) => (
  <HStack width="100%" justify="space-between">
    <Box w="258px">
      <InputGroup>
        <Input type="text" placeholder="Search page..." onChange={searchText} />
        <InputRightElement
          fontSize="24px"
          color="icon"
          pointerEvents="none"
          children={<RiSearch2Line />}
        />
      </InputGroup>
    </Box>
    <HStack spacing="8px">
      <IconButton
        variant="icon"
        size="icon-md"
        aria-label="list"
        data-track-event="click_list_view"
        isActive={cardType === 'list'}
        icon={<FiList />}
        onClick={changeCardType('list')}
      />
      <IconButton
        variant="icon"
        size="icon-md"
        aria-label="card"
        data-track-event="click_grid_view"
        isActive={cardType === 'card'}
        icon={<RiFunctionLine />}
        onClick={changeCardType('card')}
      />
    </HStack>
  </HStack>
);

export function Home() {
  const [cardType, setCardType] = useState<'list' | 'card'>('list');
  const [showTopButton, setShowTopButton] = useState(false);

  const { data: interviewFormData } = useInterviewFormQuery({
    fetchPolicy: 'network-only',
  });
  const interviewForm = interviewFormData?.interviewForm;

  const [setShowGiftTeaserInDb] = useShowGiftTeaserMutation();

  const [showGiftTeaser, setShowGiftTeaser] = useState<boolean>(false);

  const [haveSubmittedInterviewForm, setHaveSubmittedInterviewForm] =
    useState<boolean>(false);

  useEffect(() => {
    if (interviewForm) {
      setShowGiftTeaser(R.propOr(false, 'showTeaser', interviewForm));
      setHaveSubmittedInterviewForm(interviewForm.submittedAt !== null);
    }
  }, [interviewForm]);

  const { data: blogsQuery } = useShopifyBlogsQuery();
  const blogById = useMemo(
    () => R.indexBy(R.prop('id'), R.propOr([], 'shopifyBlogs', blogsQuery)),
    [blogsQuery]
  );
  const { data: userData } = useUserQuery();

  const [state, send] = useMachine<HomeContext, HomeEvent>(homeMachine);
  const {
    createPageModalRef,
    pagesRef,
    scheduleModalRef,
    interviewFormModalRef,
    deletePageDialogRef,
    deletePageId,
    confirmingHomepageId,
    schedulePageId,
    selectedPageType,
  } = state.context;
  const [createPageModalState] = useActor(createPageModalRef);
  const [pagesState, pagesSend] =
    useActor<ActorRefFrom<typeof pagesMachines>>(pagesRef);

  const {
    pages: pagesData,
    cursorId,
    homepage,
  } = pagesState.context ?? {
    pages: [],
    cursorId: null,
  };
  const isDeletingPage = pagesState.matches(
    `${PAGE_STATES.LOADED}.${PAGE_STATES.DELETING_PAGE}`
  );
  const isEmpty =
    pagesState.matches(PAGE_STATES.EMPTY) ||
    (pagesState.matches(PAGE_STATES.LOADED) && pagesData.length === 0);

  const [scheduleModalState, scheduleModalSend] = useActor(scheduleModalRef);
  const [interviewFormModalState, interviewFormModalSend] = useActor(
    interviewFormModalRef
  );
  const [deletePageDialogState, deletePageDialogSend] =
    useActor(deletePageDialogRef);
  const [homepageDialogState, homepageDialogSend] = useActor(
    state.context.homepageDialogRef
  );
  const cancelDeletePageRef = useRef(null);
  const homepageDialogRef = useRef(null);

  const handleDeletePageDialogClose = useCallback(
    () => deletePageDialogSend({ type: MODAL_EVENTS.CLOSE_MODAL }),
    [deletePageDialogSend]
  );
  const deletePage = useCallback(() => {
    pagesSend({
      type: PAGE_EVENTS.DELETE_PAGE,
      pageId: deletePageId,
    });
    handleDeletePageDialogClose();
  }, [handleDeletePageDialogClose, pagesSend, deletePageId]);
  const changeCardType = useCallback(
    (type) => () => {
      setCardType(type);
    },
    []
  );
  const changePageType = useCallback(
    (type) => () => {
      pagesSend({
        type: PAGE_EVENTS.CHANGE_PAGE_TYPE,
        pageType: type,
      });
    },
    [pagesSend]
  );
  const loadMorePages = useCallback(() => {
    pagesSend({ type: PAGE_EVENTS.MORE_PAGES });
  }, [pagesSend]);
  const searchText = useCallback(
    (event) => {
      pagesSend({ type: PAGE_EVENTS.SEARCH, search: event.target.value });
    },
    [pagesSend]
  );
  const updateHomepage = useCallback(
    (isHomepage) => {
      pagesSend({
        type: PAGE_EVENTS.UPDATE_HOMEPAGE,
        pageId: confirmingHomepageId,
        isHomepage,
      });
    },
    [pagesSend, confirmingHomepageId]
  );
  const openHomepageDialog = useCallback(
    (pageId) => send({ type: HOME_EVENTS.OPEN_HOMEPAGE_DIALOG, pageId }),
    [send]
  );
  const closeHomepageDialog = useCallback(
    () => homepageDialogSend({ type: MODAL_EVENTS.CLOSE_MODAL }),
    [homepageDialogSend]
  );
  const goTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const showTopButtonOnScroll = useCallback(() => {
    setShowTopButton(window.scrollY > 74);
  }, []);
  const createPageByType = useCallback(
    (pageType: PageType) => {
      send({ type: HOME_EVENTS.CREATE_PAGE, pageType });
    },
    [send]
  );
  const handleGiftPromoClose = () => {
    setShowGiftTeaser(true);
    setShowGiftTeaserInDb();
  };
  const handleInterviewFormSumbit = () => {
    setHaveSubmittedInterviewForm(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', showTopButtonOnScroll, { passive: true });
    return () => window.removeEventListener('scroll', showTopButtonOnScroll);
  }, [showTopButtonOnScroll]);

  return (
    <Section my="64px" flexDirection="column">
      <Section justify="space-between">
        <Heading fontSize="24px">Dashboard</Heading>
        <Button
          data-track-event="click_new_page"
          onClick={() => {
            send({ type: HOME_EVENTS.CREATE_PAGE, pageType: undefined });
          }}
        >
          New Page
        </Button>
      </Section>
      <Section mt="32px" height="100%">
        <Tabs w="full">
          <TabList>
            {R.map(
              ({ id, name }) => (
                <Tab
                  key={id}
                  data-track-event="switch_tab"
                  data-track-parameter-name="placement"
                  data-track-parameter-value={
                    name === 'All'
                      ? 'main_tab'
                      : `${name.replace(/\s+/g, '_').toLocaleLowerCase()}_tab`
                  }
                  onClick={changePageType(id)}
                >
                  {name}
                </Tab>
              ),
              PAGE_TYPE_NAME_MAP
            )}
          </TabList>
          {pagesState.matches(PAGE_STATES.LOADING) ? (
            <Center alignItems="center" height="calc(100% - 37px)">
              <Spinner color="primary" />
            </Center>
          ) : (
            <Box mt="24px">
              {isEmpty && pagesState.context.pageType === null ? (
                <EmptyContainer mt="32px" spacing="24px">
                  <WelcomeBanner createPageByType={createPageByType} />
                  <HStack spacing="24px">
                    <SupportCard variant="horizontal" />
                    <RequestPageBuildCard variant="horizontal" />
                  </HStack>
                  <StoreDesignKnowledgeCard
                    createPageByType={createPageByType}
                  />
                </EmptyContainer>
              ) : isEmpty &&
                PAGE_TYPE_NAME_MAP.some(
                  (x) =>
                    x.id === pagesState.context.pageType &&
                    pagesState.context.pageType !== null
                ) ? (
                <InstructionByPageType
                  pageType={pagesState.context.pageType as PageType}
                  createPageByType={createPageByType}
                />
              ) : (
                <HStack spacing="33px" alignItems="flex-start">
                  <VStack spacing="24px" flexGrow={1}>
                    <ToolBar
                      searchText={searchText}
                      cardType={cardType}
                      changeCardType={changeCardType}
                    />
                    {pagesState.matches(PAGE_STATES.SEARCHING) && (
                      <Center minHeight="215px">
                        <Spinner color="primary" />
                      </Center>
                    )}
                    {pagesState.matches(PAGE_STATES.SEARCH_EMPTY) && (
                      <EmptyContainer>
                        <NoResultIcon />
                        <EmptyText>
                          Sorry, we cannot find any page that matched your
                          searching keywords.
                        </EmptyText>
                      </EmptyContainer>
                    )}
                    {pagesState.matches(PAGE_STATES.LOADED) && (
                      <Gallery cardType={cardType}>
                        {R.map(
                          ({
                            id,
                            type,
                            title,
                            handle,
                            isHomepage,
                            status,
                            currentVersion,
                            attributes,
                          }) => {
                            const pagePath = getPath(
                              type,
                              R.path(
                                [`${R.prop('blogId', attributes)}`, 'handle'],
                                blogById
                              ),
                              handle
                            );
                            return (
                              currentVersion && (
                                <PageCard
                                  key={id}
                                  variant={cardType}
                                  cover={currentVersion.cover}
                                  status={status}
                                  title={title}
                                  isHomepage={isHomepage}
                                  version={getVersionName(currentVersion)}
                                  link={`/pages/${id}`}
                                  pagePath={pagePath}
                                  option={
                                    <MenuList>
                                      {status !== PageStatus.Published && (
                                        <MenuItem
                                          closeOnSelect={false}
                                          onClick={() =>
                                            pagesSend({
                                              type: PAGE_EVENTS.PUBLISH_PAGE,
                                              pageId: id,
                                            })
                                          }
                                        >
                                          {pagesState.matches(
                                            `${PAGE_STATES.LOADED}.${PAGE_STATES.PUBLISHING_PAGE}`
                                          ) ? (
                                            <Center w="full">
                                              <Spinner />
                                            </Center>
                                          ) : (
                                            'Publish'
                                          )}
                                        </MenuItem>
                                      )}
                                      {type === PageType.Regular && isHomepage && (
                                        <MenuItem
                                          onClick={() => openHomepageDialog(id)}
                                        >
                                          {pagesState.matches(
                                            `${PAGE_STATES.LOADED}.${PAGE_STATES.UPDATING_HOMEPAGE}`
                                          ) ? (
                                            <Center w="full">
                                              <Spinner />
                                            </Center>
                                          ) : (
                                            'Revert homepage'
                                          )}
                                        </MenuItem>
                                      )}
                                      {!homepage &&
                                        type === PageType.Regular &&
                                        status === PageStatus.Published &&
                                        !isHomepage && (
                                          <MenuItem
                                            onClick={() =>
                                              openHomepageDialog(id)
                                            }
                                          >
                                            {pagesState.matches(
                                              `${PAGE_STATES.LOADED}.${PAGE_STATES.UPDATING_HOMEPAGE}`
                                            ) ? (
                                              <Center w="full">
                                                <Spinner />
                                              </Center>
                                            ) : (
                                              'Set as homepage'
                                            )}
                                          </MenuItem>
                                        )}
                                      <MenuItem
                                        as={Link}
                                        href={`/preview/${id}`}
                                        isExternal
                                      >
                                        Preview
                                      </MenuItem>
                                      <MenuItem
                                        as={Link}
                                        href={`//${userData?.user.currentStore}/${pagePath}`}
                                        isExternal
                                      >
                                        View Live Page
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          send({
                                            type: HOME_EVENTS.OPEN_SCHEDULE,
                                            pageId: id,
                                          });
                                        }}
                                      >
                                        Schedule
                                      </MenuItem>
                                      {!isHomepage && (
                                        <MenuItem
                                          onClick={() => {
                                            send({
                                              type: HOME_EVENTS.OPEN_DELETE_PAGE,
                                              pageId: id,
                                            });
                                          }}
                                        >
                                          Delete page
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  }
                                />
                              )
                            );
                          },
                          pagesData
                        )}
                      </Gallery>
                    )}
                    {pagesState.matches(PAGE_STATES.LOADED) && cursorId && (
                      <Center>
                        <Button
                          w="142px"
                          mt="48px"
                          variant="outline"
                          isLoading={pagesState.matches(
                            `${PAGE_STATES.LOADED}.${PAGE_STATES.MORE_PAGES_LOADING}`
                          )}
                          loadingText="Load More"
                          onClick={loadMorePages}
                        >
                          Load More
                        </Button>
                      </Center>
                    )}
                  </VStack>
                  <VStack spacing="16px">
                    <PromoGiftCard
                      show={!showGiftTeaser && !haveSubmittedInterviewForm}
                      onClose={handleGiftPromoClose}
                      onSubmit={() =>
                        send({ type: HOME_EVENTS.OPEN_INTERVIEW_FORM })
                      }
                    />
                    <SupportCard variant="vertical" />
                    <RequestPageBuildCard variant="vertical" />
                  </VStack>
                </HStack>
              )}
            </Box>
          )}
        </Tabs>
      </Section>
      {showTopButton && (
        <IconButton
          pos="fixed"
          right="32px"
          bottom="114px"
          variant="outline"
          w="42px"
          icon={<GoTopIcon />}
          aria-label="go top"
          onClick={goTop}
        />
      )}
      {createPageModalState.matches(MODAL_STATES.OPENED) && (
        <CreatePageModal
          createPageModalRef={createPageModalRef}
          pageType={selectedPageType}
        />
      )}
      <AlertDialog
        isOpen={
          !deletePageDialogState.matches(MODAL_STATES.CLOSED) || isDeletingPage
        }
        leastDestructiveRef={cancelDeletePageRef}
        onClose={handleDeletePageDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Page</AlertDialogHeader>
            <CloseButton
              top="30px"
              right="24px"
              onClick={handleDeletePageDialogClose}
            />
            <AlertDialogBody>Are you sure to delete this page?</AlertDialogBody>
            <AlertDialogFooter px="24px">
              <Button
                variant="outline"
                ref={cancelDeletePageRef}
                onClick={handleDeletePageDialogClose}
              >
                Cancel
              </Button>
              <Button
                bgColor="danger"
                onClick={deletePage}
                isLoading={isDeletingPage}
                loadingText="Deleting"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <HomepageDialog
        isCurrentHomepage={confirmingHomepageId === homepage?.id}
        homepageDialogRef={homepageDialogRef}
        isOpen={homepageDialogState.matches(MODAL_STATES.OPENED)}
        onClose={closeHomepageDialog}
        isUpdatingHomepage={pagesState.matches(
          `${PAGE_STATES.LOADED}.${PAGE_STATES.UPDATING_HOMEPAGE}`
        )}
        updateHomepage={updateHomepage}
      />
      <TeaserTrigger
        show={showGiftTeaser && !haveSubmittedInterviewForm}
        onClick={() => send({ type: HOME_EVENTS.OPEN_INTERVIEW_FORM })}
      />
      <InterviewFormModal
        isOpen={interviewFormModalState.matches(MODAL_STATES.OPENED)}
        onClose={() =>
          interviewFormModalSend({ type: MODAL_EVENTS.CLOSE_MODAL })
        }
        onSubmit={handleInterviewFormSumbit}
      />
      <ScheduleModal
        isOpen={scheduleModalState.matches(MODAL_STATES.OPENED)}
        onClose={() => scheduleModalSend({ type: MODAL_EVENTS.CLOSE_MODAL })}
        pageId={schedulePageId}
      />
    </Section>
  );
}
