export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Long: any;
  Upload: any;
  JSONObject: any;
};

export type Blog = {
  __typename?: 'Blog';
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
  imageSrc?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['ID']>;
};

export type CreateLibrarySnippetInput = {
  name: Scalars['String'];
  componentData: Scalars['JSON'];
  categoryName: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type CreatePageInput = {
  type: PageType;
  from: PageFrom;
  title: Scalars['String'];
  handle: Scalars['String'];
  shopifyBlogId?: Maybe<Scalars['String']>;
  shopifyProductId?: Maybe<Scalars['String']>;
  shopifyCollectionId?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
};

export type CreateScheduleInput = {
  publishDate: Scalars['DateTime'];
  pageVersionId: Scalars['String'];
};

export type CreateSnippetInput = {
  name: Scalars['String'];
  componentData: Scalars['JSON'];
  categoryName: Scalars['String'];
};


export type DeleteLibrarySnippetInput = {
  id: Scalars['String'];
};

export type FlatShopifyCollection = {
  __typename?: 'FlatShopifyCollection';
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
  descriptionHtml: Scalars['String'];
  image?: Maybe<ShopifyImage>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type FlatShopifyProduct = {
  __typename?: 'FlatShopifyProduct';
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
  descriptionHtml: Scalars['String'];
  vendor: Scalars['String'];
  price: Scalars['String'];
  status: ProductStatus;
  publishedAt?: Maybe<Scalars['DateTime']>;
  compareAtPrice?: Maybe<Scalars['String']>;
  variants: Array<ShopifyProductVariant>;
  featuredImage?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
};

export type ImagesResponse = {
  __typename?: 'ImagesResponse';
  cursorId?: Maybe<Scalars['String']>;
  nodes: Array<Image>;
};

export type InterviewForm = {
  __typename?: 'InterviewForm';
  id: Scalars['ID'];
  shopifyStoreId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  showTeaser: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  submittedAt?: Maybe<Scalars['DateTime']>;
};



export type LibrarySnippet = {
  __typename?: 'LibrarySnippet';
  id: Scalars['String'];
  name: Scalars['String'];
  componentData: Scalars['JSON'];
  thumbnail: Scalars['String'];
  category: LibrarySnippetCategory;
};

export type LibrarySnippetCategoriesInput = {
  filterNoSnippets?: Maybe<Scalars['Boolean']>;
};

export type LibrarySnippetCategory = {
  __typename?: 'LibrarySnippetCategory';
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  type: LibrarySnippetCategoryType;
  children?: Maybe<Array<Maybe<LibrarySnippetCategory>>>;
};

export enum LibrarySnippetCategoryType {
  Block = 'BLOCK',
  Page = 'PAGE'
}

export type LibrarySnippetsInput = {
  categoryId: Scalars['String'];
};


export type Mutation = {
  __typename?: 'Mutation';
  checkShopifyRecurringCharge: RecurringStatus;
  createLibrarySnippet: LibrarySnippet;
  createPage?: Maybe<Page>;
  createSchedule: Schedule;
  createShopifyWebhook?: Maybe<Scalars['Boolean']>;
  createSnippet: Snippet;
  deleteLibrarySnippet: Scalars['Boolean'];
  deletePage: Scalars['Boolean'];
  deleteSchedule: Scalars['Boolean'];
  deleteSnippet: Scalars['Boolean'];
  deleteSnippetCategory: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  login: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  publishAllOverTimeSchedulePage: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  revertPage?: Maybe<Page>;
  saveInterviewForm?: Maybe<InterviewForm>;
  savePage?: Maybe<Page>;
  setPassword: Scalars['Boolean'];
  shopifyInstall: ShopifyInstallResponse;
  shopifyLogin: Scalars['String'];
  shopifyPublishPage: Page;
  shopifyRecurringCharge: Scalars['String'];
  showGiftTeaser: Scalars['Boolean'];
  switchStore: Scalars['Boolean'];
  updateHomepage?: Maybe<Page>;
  updatePageSettings?: Maybe<Page>;
  updateUser: UpdateUserResponse;
  uploadImage: Scalars['ID'];
};


export type MutationCreateLibrarySnippetArgs = {
  input: CreateLibrarySnippetInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationCreateSnippetArgs = {
  input: CreateSnippetInput;
};


export type MutationDeleteLibrarySnippetArgs = {
  input: DeleteLibrarySnippetInput;
};


export type MutationDeletePageArgs = {
  id: Scalars['String'];
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSnippetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSnippetCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRevertPageArgs = {
  input: RevertPageInput;
};


export type MutationSaveInterviewFormArgs = {
  input: SaveInterviewFormInput;
};


export type MutationSavePageArgs = {
  input: SavePageInput;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String'];
};


export type MutationShopifyInstallArgs = {
  input: ShopifyInstallInput;
};


export type MutationShopifyLoginArgs = {
  input: ShopifyLoginInput;
};


export type MutationShopifyPublishPageArgs = {
  pageId: Scalars['ID'];
};


export type MutationShopifyRecurringChargeArgs = {
  plan: Plan;
};


export type MutationSwitchStoreArgs = {
  storeName: Scalars['String'];
};


export type MutationUpdateHomepageArgs = {
  input: UpdateHomepageInput;
};


export type MutationUpdatePageSettingsArgs = {
  input: UpdatePageSettingsInput;
};


export type MutationUpdateUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationUploadImageArgs = {
  image: Scalars['Upload'];
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: PageType;
  handle: Scalars['String'];
  currentVersion?: Maybe<PageVersion>;
  currentVersionId?: Maybe<Scalars['ID']>;
  publishedVersion?: Maybe<PageVersion>;
  publishedVersionId?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Scalars['JSON']>;
  isHomepage: Scalars['Boolean'];
  status: PageStatus;
};

export enum PageFrom {
  Blank = 'BLANK',
  Shopify = 'SHOPIFY',
  Extend = 'EXTEND'
}

export type PagesResponse = {
  __typename?: 'PagesResponse';
  cursorId?: Maybe<Scalars['String']>;
  nodes: Array<Page>;
};

export enum PageStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Outdated = 'OUTDATED'
}

export enum PageType {
  Regular = 'REGULAR',
  Product = 'PRODUCT',
  Collection = 'COLLECTION',
  Post = 'POST'
}

export type PageVersion = {
  __typename?: 'PageVersion';
  id: Scalars['ID'];
  content: Scalars['JSON'];
  cover?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
};

export type PageVersionInput = {
  versionId: Scalars['String'];
};

export type PageVersionsInput = {
  id: Scalars['String'];
};

export type PageWithVersions = {
  __typename?: 'PageWithVersions';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: PageType;
  handle: Scalars['String'];
  currentVersion?: Maybe<PageVersion>;
  currentVersionId?: Maybe<Scalars['ID']>;
  publishedVersion?: Maybe<PageVersion>;
  publishedVersionId?: Maybe<Scalars['ID']>;
  pageVersions: Array<PageVersion>;
  attributes?: Maybe<Scalars['JSON']>;
  isHomepage: Scalars['Boolean'];
  status: PageStatus;
};

export type Permission = {
  __typename?: 'Permission';
  development: Scalars['Boolean'];
  admin: Scalars['Boolean'];
};

export enum Plan {
  FreeTrial = 'FREE_TRIAL',
  Starter = 'STARTER',
  PreReleasePro = 'PRE_RELEASE_PRO'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
  imageSrc?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['ID']>;
  status?: Maybe<ProductStatus>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProductStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT'
}

export type Query = {
  __typename?: 'Query';
  dammy: Scalars['String'];
  images: ImagesResponse;
  interviewForm?: Maybe<InterviewForm>;
  latestShopifyCollection?: Maybe<FlatShopifyCollection>;
  latestShopifyProduct?: Maybe<FlatShopifyProduct>;
  librarySnippetCategories: Array<LibrarySnippetCategory>;
  librarySnippets: Array<LibrarySnippet>;
  page: Page;
  pageVersion: PageVersion;
  pageVersions: Array<Maybe<PageVersion>>;
  pages: PagesResponse;
  permission: Permission;
  schedules: Array<Schedule>;
  shopifyBlog: Blog;
  shopifyBlogs: Array<Maybe<Blog>>;
  shopifyCollection?: Maybe<FlatShopifyCollection>;
  shopifyCollections: Array<Maybe<Collection>>;
  shopifyCollectionsCount: Scalars['Int'];
  shopifyProduct?: Maybe<FlatShopifyProduct>;
  shopifyProducts: Array<Maybe<Product>>;
  shopifyProductsCount: Scalars['Int'];
  shopifyShop: Shop;
  snippetCategories: Array<SnippetCategory>;
  snippets: Array<Snippet>;
  user: User;
  userStores: Array<Maybe<Store>>;
  verifyCategory: Scalars['Boolean'];
  verifyEmail: Scalars['Boolean'];
  verifyHandle: Scalars['Boolean'];
  verifyResetPasswordToken: Scalars['Boolean'];
};


export type QueryImagesArgs = {
  take?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['String']>;
};


export type QueryLibrarySnippetCategoriesArgs = {
  input?: Maybe<LibrarySnippetCategoriesInput>;
};


export type QueryLibrarySnippetsArgs = {
  input: LibrarySnippetsInput;
};


export type QueryPageArgs = {
  id: Scalars['String'];
};


export type QueryPageVersionArgs = {
  input: PageVersionInput;
};


export type QueryPageVersionsArgs = {
  input: PageVersionsInput;
};


export type QueryPagesArgs = {
  keyword?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['String']>;
  pageType?: Maybe<PageType>;
  disabledFromShopify?: Maybe<Scalars['Boolean']>;
};


export type QuerySchedulesArgs = {
  pageId: Scalars['String'];
};


export type QueryShopifyBlogArgs = {
  id: Scalars['String'];
};


export type QueryShopifyCollectionArgs = {
  id: Scalars['String'];
};


export type QueryShopifyCollectionsArgs = {
  input?: Maybe<ShopifyCollectionsInput>;
};


export type QueryShopifyProductArgs = {
  id: Scalars['String'];
};


export type QueryShopifyProductsArgs = {
  input?: Maybe<ShopifyProductsInput>;
};


export type QueryVerifyCategoryArgs = {
  name: Scalars['String'];
};


export type QueryVerifyEmailArgs = {
  email: Scalars['String'];
};


export type QueryVerifyHandleArgs = {
  pageType: PageType;
  handle: Scalars['String'];
};


export type QueryVerifyResetPasswordTokenArgs = {
  token: Scalars['String'];
};

export type RecurringStatus = {
  __typename?: 'RecurringStatus';
  isActive: Scalars['Boolean'];
  restTrialDays?: Maybe<Scalars['Int']>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type RevertPageInput = {
  id: Scalars['String'];
  pageVersionId: Scalars['String'];
};

export type SaveInterviewFormInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
};

export type SavePageInput = {
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  componentDataObj: Scalars['JSON'];
  js?: Maybe<Scalars['String']>;
  css?: Maybe<Scalars['String']>;
  fonts?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  image: Scalars['Upload'];
};

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['String'];
  status: ScheduleStatus;
  publishDate: Scalars['DateTime'];
  pageVersion: PageVersion;
  createdAt: Scalars['DateTime'];
};

export enum ScheduleStatus {
  Ready = 'READY',
  Published = 'PUBLISHED',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['Long'];
  name: Scalars['String'];
  domain: Scalars['String'];
  moneyFormat: Scalars['String'];
  currency: Scalars['String'];
};

export type ShopifyCollectionsInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ShopifyImage = {
  __typename?: 'ShopifyImage';
  altText?: Maybe<Scalars['String']>;
  transformedSrc: Scalars['String'];
};

export type ShopifyInstallInput = {
  code: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  state: Scalars['String'];
  shop: Scalars['String'];
  timestamp: Scalars['Int'];
  query: Scalars['String'];
};

export type ShopifyInstallResponse = {
  __typename?: 'ShopifyInstallResponse';
  type: ShopifyInstallResponseType;
  query?: Maybe<Scalars['String']>;
};

export enum ShopifyInstallResponseType {
  Login = 'LOGIN',
  GetUser = 'GET_USER'
}

export type ShopifyLoginInput = {
  hmac: Scalars['String'];
  shop: Scalars['String'];
  timestamp: Scalars['Int'];
  online: Scalars['Boolean'];
  query: Scalars['String'];
};

export type ShopifyProductsInput = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ShopifyProductVariant = {
  __typename?: 'ShopifyProductVariant';
  id: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['String'];
  compareAtPrice?: Maybe<Scalars['String']>;
  available: Scalars['Boolean'];
  image?: Maybe<ShopifyImage>;
};

export type Snippet = {
  __typename?: 'Snippet';
  id: Scalars['String'];
  name: Scalars['String'];
  componentData: Scalars['JSON'];
  category: SnippetCategory;
};

export type SnippetCategory = {
  __typename?: 'SnippetCategory';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  name: Scalars['String'];
};

export type UpdateHomepageInput = {
  id: Scalars['String'];
  isHomepage: Scalars['Boolean'];
};

export type UpdatePageSettingsInput = {
  id: Scalars['String'];
  title: Scalars['String'];
  handle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['JSON']>;
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  currentStore: Scalars['String'];
  hasPassword: Scalars['Boolean'];
};
