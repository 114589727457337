import {
  Heading,
  Text,
  Box,
  Link,
  HStack,
  Flex,
  Button,
  SimpleGrid,
  Icon,
} from '@chakra-ui/react';
import { PageType } from '../apollo/types.generate';
import { CONTENTS_BY_TYPE } from '../constants/page';
import * as R from 'ramda';

const StoreDesignKnowledgeCard = ({ createPageByType }) => {
  return (
    <Box
      width="100%"
      borderRadius="8px"
      boxShadow="0 2px 16px 0 rgba(0, 0, 0, 0.1)"
      background="#fff"
      padding="32px"
    >
      <Heading fontSize="24px" fontWeight="bold" color="black">
        Store Design 101
      </Heading>
      <Text fontSize="16px" fontWeight={600} mt="8px">
        Learn about the various pages you can build with Layoutbase
      </Text>
      <SimpleGrid columns={2} spacing="32px" mt="32px">
        {R.map((pageType) => {
          const {
            icon,
            typeName,
            description,
            documentUrl,
            typeNameForTracking,
          } = CONTENTS_BY_TYPE[pageType];
          return (
            <Box
              key={pageType}
              borderRadius="lg"
              p="24px 32px"
              bgColor="background"
            >
              <Flex justify="space-between" align="center">
                <HStack spacing="12px">
                  <Icon as={icon} w="50px" h="30px" />
                  <Text fontSize="18px" fontWeight={600} color="black">
                    {typeName}
                  </Text>
                </HStack>
              </Flex>
              <Text mt="16px">
                {description}{' '}
                <Link
                  fontSize="14px"
                  color="black"
                  fontWeight="semibold"
                  textDecoration="underline"
                  data-track-event={`click_learn_more_${typeNameForTracking}`}
                  data-track-parameter-name="placement"
                  data-track-parameter-value="main_tab"
                  _hover={{ textDecoration: 'underline' }}
                  isExternal
                  href={documentUrl}
                >
                  Learn more
                </Link>
              </Text>
              <Button
                variant="outline"
                size="sm"
                mt="24px"
                data-track-event={`click_build_${typeNameForTracking}`}
                data-track-parameter-name="placement"
                data-track-parameter-value="main_tab_design101"
                onClick={() => createPageByType(pageType)}
              >
                {`Build a ${typeName}${
                  pageType === PageType.Post ? '' : ' Page'
                }`}
              </Button>
            </Box>
          );
        }, R.keys(CONTENTS_BY_TYPE))}
      </SimpleGrid>
    </Box>
  );
};

export default StoreDesignKnowledgeCard;
