import { RgbaColor } from 'polished/lib/types/color';
import { createMachine, assign } from 'xstate';
import { cancel, send } from 'xstate/lib/actions';
import {
  COLOR_HISTORY_MAX_LENGTH,
  DEFAULT_FALLBACK_COLOR,
  UNAVAILABLE_COLOR,
} from './constants';
import { ColorSchema, HexColorSchema, RgbaColorSchema } from './schema';
import { ColorSchemaType } from './types';
import { getFallbackColor, rgbaToFullHex } from './utils';
import * as R from 'ramda';

enum S {
  SINGLE_COLOR = 'SINGLE_COLOR',
  MULTIPLE_COLORS = 'MULTIPLE_COLORS',
}

enum E {
  DATA_CHANGED = 'DATA_CHANGED',
  PICK_COLOR = 'PICK_COLOR',
  CHANGE_HEX_INPUT = 'CHANGE_HEX_INPUT',
  UPDATE_COLOR_BY_HEX = 'UPDATE_COLOR_BY_HEX',
  CHANGE_RGBA_INPUT = 'CHANGE_RGBA_INPUT',
  UPDATE_COLOR_BY_RGBA = 'UPDATE_COLOR_BY_RGBA',
  TRIGGER_CHANGE_CALLBACK = 'TRIGGER_CHANGE_CALLBACK',
  CLOSE = 'CLOSE',
}

type ColorContext = {
  color?: ColorSchemaType;
  rgbaFallbackColor: RgbaColor;
  rgbaDisplayColor: RgbaColor;
  hexInput?: string;
  redInput?: string;
  greenInput?: string;
  blueInput?: string;
  alphaInput?: string;
  history: RgbaColor[];
};

const colorMachine = createMachine<ColorContext>(
  {
    initial: S.SINGLE_COLOR,
    context: {
      rgbaDisplayColor: DEFAULT_FALLBACK_COLOR,
      rgbaFallbackColor: DEFAULT_FALLBACK_COLOR,
      history: [],
    },
    states: {
      [S.SINGLE_COLOR]: {
        on: {
          [E.CLOSE]: {
            actions: ['saveHistory', 'triggerCloseCallback'],
          },
        },
      },
      [S.MULTIPLE_COLORS]: {
        on: {
          [E.CLOSE]: {
            actions: ['triggerCloseCallback'],
          },
        },
      },
    },
    on: {
      [E.DATA_CHANGED]: [
        {
          cond: 'isMultipleColors',
          target: S.MULTIPLE_COLORS,
          actions: [
            'assignColor',
            'assignRgbaFallbackColor',
            'assignUnavailableRgbaDisplayColor',
            'assignEmptyHexInput',
            'assignEmptyRgbaInput',
          ],
        },
        {
          target: S.SINGLE_COLOR,
          actions: [
            'assignColor',
            'assignRgbaFallbackColor',
            'assignRgbaDisplayColor',
            'assignHexInput',
            'assignRgbaInput',
          ],
        },
      ],
      [E.TRIGGER_CHANGE_CALLBACK]: {
        actions: ['triggerChangeCallback'],
      },
      [E.PICK_COLOR]: {
        target: S.SINGLE_COLOR,
        actions: [
          'updateRgbaDisplayColor',
          'assignHexInput',
          'assignRgbaInput',
          'cancelTriggerChangeCallback',
          'debounceTriggerChangeCallback',
        ],
      },
      [E.CHANGE_HEX_INPUT]: {
        actions: ['changeHexInput'],
      },
      [E.UPDATE_COLOR_BY_HEX]: {
        target: S.SINGLE_COLOR,
        actions: [
          'updateRgbaDisplayColorByHex',
          'assignHexInput',
          'assignRgbaInput',
          'debounceTriggerChangeCallback',
        ],
      },
      [E.CHANGE_RGBA_INPUT]: {
        actions: ['changeRgbaInput'],
      },
      [E.UPDATE_COLOR_BY_RGBA]: {
        target: S.SINGLE_COLOR,
        actions: [
          'fillInEmptyRgbaInput',
          'updateRgbaDisplayColorByRgba',
          'assignHexInput',
          'debounceTriggerChangeCallback',
        ],
      },
    },
  },
  {
    guards: {
      isMultipleColors: (_, { color }) => Array.isArray(color),
    },
    actions: {
      assignColor: assign({
        color: (_, { color }) => color,
      }),
      assignRgbaFallbackColor: assign({
        rgbaFallbackColor: (_, { fallbackColor }) =>
          getFallbackColor(fallbackColor),
      }),
      assignRgbaDisplayColor: assign({
        rgbaDisplayColor: ({ rgbaFallbackColor }, { color }) => {
          const colorResult = ColorSchema.safeParse(color);
          const colorData = colorResult.success
            ? colorResult.data
            : rgbaFallbackColor;

          return colorData === 'unset' ? rgbaFallbackColor : colorData;
        },
      }),
      assignUnavailableRgbaDisplayColor: assign({
        rgbaDisplayColor: (_) => UNAVAILABLE_COLOR,
      }),
      assignHexInput: assign({
        hexInput: ({ rgbaDisplayColor }) => rgbaToFullHex(rgbaDisplayColor),
      }),
      assignEmptyHexInput: assign({
        hexInput: (_) => '',
      }),
      assignRgbaInput: assign(({ rgbaDisplayColor }) => ({
        redInput: rgbaDisplayColor.red.toString(),
        greenInput: rgbaDisplayColor.green.toString(),
        blueInput: rgbaDisplayColor.blue.toString(),
        alphaInput: `${rgbaDisplayColor.alpha * 100}`,
      })),
      assignEmptyRgbaInput: assign((_) => ({
        redInput: '',
        greenInput: '',
        blueInput: '',
        alphaInput: '',
      })),
      cancelTriggerChangeCallback: cancel(
        `debounced-${E.TRIGGER_CHANGE_CALLBACK}`
      ),
      debounceTriggerChangeCallback: send(E.TRIGGER_CHANGE_CALLBACK, {
        delay: 200,
        id: `debounced-${E.TRIGGER_CHANGE_CALLBACK}`,
      }),
      changeHexInput: assign({
        hexInput: (_, { value }) => value,
      }),
      updateRgbaDisplayColor: assign({
        rgbaDisplayColor: (_, { rgbaColor }) => rgbaColor,
      }),
      updateRgbaDisplayColorByHex: assign({
        rgbaDisplayColor: ({ rgbaDisplayColor, hexInput }) => {
          const result = HexColorSchema.safeParse(`#${hexInput}`);
          return result.success ? result.data : rgbaDisplayColor;
        },
      }),
      changeRgbaInput: assign((_, { colorKey, value }) => ({
        [`${colorKey}Input`]: value,
      })),
      fillInEmptyRgbaInput: assign({
        redInput: ({ redInput }) => redInput || '0',
        greenInput: ({ greenInput }) => greenInput || '0',
        blueInput: ({ blueInput }) => blueInput || '0',
        alphaInput: ({ alphaInput }) => alphaInput || '0',
      }),
      updateRgbaDisplayColorByRgba: assign({
        rgbaDisplayColor: ({
          rgbaDisplayColor,
          redInput,
          greenInput,
          blueInput,
          alphaInput,
        }) => {
          const rgba: RgbaColor = {
            red: +(redInput || 0),
            green: +(greenInput || 0),
            blue: +(blueInput || 0),
            alpha: +(alphaInput || 0) / 100,
          };
          const result = RgbaColorSchema.safeParse(rgba);
          return result.success ? result.data : rgbaDisplayColor;
        },
      }),
      saveHistory: assign({
        history: ({ history, rgbaDisplayColor }) =>
          R.uniq([rgbaDisplayColor, ...history]).slice(
            0,
            COLOR_HISTORY_MAX_LENGTH
          ),
      }),
    },
  }
);
export { colorMachine, S as COLOR_STATE, E as COLOR_EVENT };
