import * as R from 'ramda';
import { sdk } from '../apollo/client';
import { PageType } from '../apollo/types.generate';
import {
  INVALID_URL_REGEX,
  MORE_THAN_ONE_DASH_REGEX,
  BOTH_ENDS_DASH_REGEX,
  SPECIFIC_URL_REGEX,
} from '../constants';
import { PAGE_PATH_BY_TYPE } from '../constants/page';
import { debounce } from './debounce';

export const toHandle = (value: string) => {
  const validUrl = R.pipe(
    R.toLower,
    R.replace(SPECIFIC_URL_REGEX, ''),
    R.replace(INVALID_URL_REGEX, '-'),
    R.replace(MORE_THAN_ONE_DASH_REGEX, '-'),
    R.replace(BOTH_ENDS_DASH_REGEX, '')
  )(value);

  return validUrl;
};

export const pageTitleRules = {
  required: 'Page Title is required.',
  minLength: {
    value: 3,
    message: 'Length must be 3 characters or more.',
  },
};

const verifyHandle = ({
  handle,
  pageType,
}: {
  handle: string;
  pageType: PageType;
}) =>
  sdk
    .verifyHandle(
      {
        handle,
        pageType,
      },
      { fetchPolicy: 'network-only' }
    )
    .then(() => true)
    .catch(() => 'Page URL is already taken.');

export const pageHandleRulesByType = (type: PageType, handle?: string) => ({
  required: 'Page URL is required.',
  validate: {
    isInvalid: (v) => v.match(INVALID_URL_REGEX) && 'Invalid character(s).',
    urlIsExist: debounce(
      async (v) =>
        v === handle
          ? true
          : await verifyHandle({
              handle: v,
              pageType: type,
            }),
      200
    ),
  },
});

export const getPath = (
  pageType: PageType,
  blogHandle?: string,
  pageHandle?: string
) =>
  `${PAGE_PATH_BY_TYPE[pageType] || ''}/${
    pageType === PageType.Post && blogHandle ? `${blogHandle}/` : ''
  }${pageHandle || ''}`;
