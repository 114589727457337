import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  VStack,
  IconButton,
  HStack,
  Tabs,
  TabList,
  Tab,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Center,
  InputLeftAddon,
  Tooltip,
  Icon,
  Alert,
  AlertIcon,
  AlertDescription,
  Link,
  Image,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { useActor, useMachine } from '@xstate/react';
import { ActorRefFrom, State } from 'xstate';
import * as R from 'ramda';
import { useForm, Controller } from 'react-hook-form';
import {
  createPageMachine,
  CREATE_PAGE_STATES,
  CREATE_PAGE_EVENTS,
  CreatePageContext,
  CreatePageEvent,
  CreatePageState,
} from '../machines/createPageMachine';
import { ArrowLeftIcon, NoResultIcon } from './Icons';
import { PageFrom, PageType } from '../apollo/types.generate';
import {
  modalMachine,
  MODAL_EVENTS,
  MODAL_STATES,
} from '../machines/modalMachine';
import { useEffect } from 'react';
import { RiInformationLine, RiSearch2Line } from 'react-icons/ri';
import {
  pagesMachines,
  PAGE_EVENTS,
  PAGE_STATES,
} from '../machines/pages/pagesMachine';
import { ModalPageCard } from './ModalPageCard';
import {
  BlogImage,
  CollectionImage,
  NewPageImage,
  ProductImage,
  RegularImage,
} from '../components/Images';
import { NewPageIcon } from '../components/Icons';
import { useToast } from '../components/Toast';
import CharacterCounter from './CharacterCounter';
import { CloseButton } from './CloseButton';
import Select from './Select';
import { toHandle, pageTitleRules, pageHandleRulesByType } from '../utils/page';
import { getVersionName } from '../utils/getVersionName';
import {
  PAGE_TITLE_MAX_LENGTH,
  PAGE_HANDLE_HELPER_TEXT,
  PRODUCT_COLLECTION_CONFIGURATION_HYPERLINK,
} from '../constants/page';
import ShopifyItemList, { ShopifyItemBar } from './shopifyItemList';

const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

const typeOptions = [
  {
    type: PageType.Regular,
    name: 'Regular',
    renderImg: () => <RegularImage />,
    description:
      'Customize home, landing, sales, contact, about us and testimonial pages, etc.',
    tooltip:
      'Build a /pages/{page title} page such as your Homepage, About Us, User Reviews, landing pages, and more.',
  },
  {
    type: PageType.Product,
    name: 'Product',
    renderImg: () => <ProductImage />,
    description: 'Convert product description into stylized components.',
    tooltip:
      'Build a /products/{product name} page and use it as a template across your store.',
  },
  {
    type: PageType.Collection,
    name: 'Collection',
    renderImg: () => <CollectionImage />,
    description:
      'Expand the content from your current collection page. Personalize your product groupings display.',
    tooltip:
      "Build a /collections/{collection name} page to house products in collections you've defined on Shopify.",
  },
  {
    type: PageType.Post,
    name: 'Blog Post',
    renderImg: () => <BlogImage />,
    description:
      'Create article to engage your audience with ready-to-use layouts and elements.',
    tooltip:
      'Create a /blogs/{page title} page and customize it from layout to content.',
  },
];

type FromOption<T> = {
  type: T;
  name: (pageType?: PageType) => string;
  description: (pageType?: PageType) => string;
  renderImg: (pageType?: PageType) => React.ReactElement;
};
const fromOptions: { [key in PageFrom]: FromOption<PageFrom> } = {
  [PageFrom.Shopify]: {
    type: PageFrom.Shopify,
    name: () => 'Add to an Existing Shopify Page',
    description: (pageType: PageType) =>
      `Add content above and below the existing ${pageType?.toLowerCase()} page`,
    renderImg: (pageType: PageType) => (
      <Image
        src={`${
          process.env.PUBLIC_URL
        }image/create_page_${pageType?.toLowerCase()}_shopify.png`}
      />
    ),
  },
  [PageFrom.Blank]: {
    type: PageFrom.Blank,
    name: (pageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection])
        ? `New ${capitalize(pageType)} Page Layout`
        : 'New blank page',
    description: (pageType: PageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection])
        ? `Build a new layout for a ${pageType?.toLowerCase()} you have on Shopify`
        : '',
    renderImg: (pageType: PageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection]) ? (
        <Image
          src={`${
            process.env.PUBLIC_URL
          }image/create_page_${pageType?.toLowerCase()}_new.png`}
        />
      ) : (
        <NewPageImage />
      ),
  },
  [PageFrom.Extend]: {
    type: PageFrom.Extend,
    name: (pageType: PageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection])
        ? 'Build from a Template'
        : 'From my existing page',
    description: (pageType: PageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection])
        ? "Choose the pages you've built as a template"
        : '',
    renderImg: (pageType: PageType) =>
      R.includes(pageType, [PageType.Product, PageType.Collection]) ? (
        <Image
          src={`${
            process.env.PUBLIC_URL
          }image/create_page_${pageType?.toLowerCase()}_template.png`}
        />
      ) : (
        <RegularImage />
      ),
  },
};

const PAGE_TYPE_NAME_MAP = [
  { id: null, name: 'All' },
  {
    id: PageType.Regular,
    name: 'Regular',
  },
  {
    id: PageType.Product,
    name: 'Product',
  },
  {
    id: PageType.Collection,
    name: 'Collection',
  },
  {
    id: PageType.Post,
    name: 'Blog Post',
  },
];

const CONFIG_PAGE_BUTTON_STYLE = {
  w: '258px',
  h: '190px',
  p: '0px',
  border: '2px solid transparent',
  borderRadius: '16px',
  overflow: 'hidden',
  _hover: {
    borderColor: 'primary',
  },
  _active: {
    borderColor: 'primary',
  },
};

type CreateTemplateModalProps = {
  createPageModalRef: ActorRefFrom<typeof modalMachine>;
  pageType?: PageType;
};

const getModalTitle = (
  state: State<CreatePageContext, any, any, CreatePageState>
) => {
  if (
    state.matches({
      values: CREATE_PAGE_STATES.CHOOSING_TYPE,
    })
  ) {
    return 'Create New Page';
  }
  if (state.matches({ values: CREATE_PAGE_STATES.CHOOSING_SHOPIFY_ITEM })) {
    return `Select ${capitalize(state.context.type)}`;
  }
  if (
    state.matches({
      values: CREATE_PAGE_STATES.FILLING_INFO,
    })
  ) {
    return `Create New Regular Page`;
  }
  if (
    state.matches({
      values: CREATE_PAGE_STATES.FILLING_POST_INFO,
    })
  ) {
    return `Create New Blog Post`;
  }
  if (state.matches({ values: CREATE_PAGE_STATES.CHOOSING_FROM })) {
    return `Choose ${capitalize(state.context.type)} Configuration`;
  }
  if (state.matches({ values: CREATE_PAGE_STATES.CHOOSING_EXISTING_PAGE })) {
    return 'Create new page from my existing page';
  }
};

const EmptyContainer = (props) => (
  <VStack h="full" justifyContent="center" spacing="16px" {...props} />
);

const EmptyText = (props) => (
  <Text
    w="346px"
    fontSize="16px"
    fontWeight="600"
    textAlign="center"
    {...props}
  />
);

const Gallery = ({ ...rest }) => (
  <SimpleGrid
    overflow="auto"
    columns={4}
    spacing="24px"
    rowGap="16px"
    p="24px"
    pb="0"
    {...rest}
  />
);

export const CreatePageModal = ({
  createPageModalRef,
  pageType,
}: CreateTemplateModalProps) => {
  const toast = useToast();
  const [modalState, modalSend] = useActor(createPageModalRef);
  const [state, send] = useMachine<CreatePageContext, CreatePageEvent>(
    createPageMachine,
    {
      actions: {
        closeModal: () => modalSend({ type: MODAL_EVENTS.CLOSE_MODAL }),
        notifySubmitError: () =>
          toast({
            title: 'Failed to create page.',
          }),
      },
    }
  );
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
    control,
    setValue,
    watch,
  } = useForm({ mode: 'onChange', reValidateMode: 'onBlur' });

  const [regularTitle, postTitle] = watch(['regularTitle', 'postTitle']);
  const modaltitle = getModalTitle(state);
  const back = () =>
    send({
      type: CREATE_PAGE_EVENTS.BACK,
    });
  const setType = (type: PageType) => () =>
    send({
      type: CREATE_PAGE_EVENTS.NEXT,
      data: { type },
    });
  const setFrom = (from: PageFrom) => () =>
    send({
      type: CREATE_PAGE_EVENTS.CHOOSE_FROM,
      data: { from },
    });
  const onSubmitInfo = handleSubmit((value) => {
    const type = state.context.type?.toLowerCase();
    send({
      type: CREATE_PAGE_EVENTS.NEXT,
      data: { title: value[`${type}Title`], handle: value[`${type}Handle`] },
    });
  });
  const onFromNextClick = () =>
    send({
      type: CREATE_PAGE_EVENTS.NEXT,
    });
  const onClose = () => modalSend({ type: MODAL_EVENTS.CLOSE_MODAL });
  const [pagesState, pagesSend] = useActor<ActorRefFrom<typeof pagesMachines>>(
    state.context.pagesRef
  );
  const { pages, cursorId } = pagesState.context ?? {
    pages: [],
    cursorId: null,
  };
  const changePageType = (type) => () =>
    pagesSend({
      type: PAGE_EVENTS.CHANGE_PAGE_TYPE,
      pageType: type,
    });
  const searchText = (event) =>
    pagesSend({ type: PAGE_EVENTS.SEARCH, search: event.target.value });
  const loadMorePages = () => pagesSend({ type: PAGE_EVENTS.MORE_PAGES });
  const choosePage = (pageId) => () =>
    send({ type: CREATE_PAGE_EVENTS.CHOOSE_EXISTING_PAGE, data: { pageId } });

  useEffect(() => {
    if (modalState.matches(MODAL_STATES.OPENED)) {
      send({ type: CREATE_PAGE_EVENTS.RESET });
      reset();
    }
  }, [send, modalState, reset]);

  useEffect(() => {
    if (pageType) {
      send({
        type: CREATE_PAGE_EVENTS.NEXT,
        data: { type: pageType },
      });
    }
  }, [send, pageType]);

  const isSubmitting = state.matches({ submit: CREATE_PAGE_STATES.PENDING });

  return (
    <>
      <Modal
        isOpen={!modalState.matches(MODAL_STATES.CLOSED)}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent minW="min-content" pt="64px">
          <ModalHeader
            textAlign="center"
            fontSize="24px"
            fontWeight="bold"
            p="0"
          >
            {modaltitle}
          </ModalHeader>
          {!state.matches({ values: CREATE_PAGE_STATES.CHOOSING_TYPE }) && (
            <IconButton
              variant="line"
              aria-label="back"
              icon={<ArrowLeftIcon />}
              position="absolute"
              h="28px"
              w="28px"
              top="18"
              left="18"
              onClick={back}
            />
          )}
          <CloseButton onClick={onClose} top="18px" right="18px" />
          <ModalBody
            w={
              state.matches({
                values: CREATE_PAGE_STATES.CHOOSING_EXISTING_PAGE,
              })
                ? '1104px'
                : '916px'
            }
          >
            {state.matches({ values: CREATE_PAGE_STATES.CHOOSING_TYPE }) && (
              <Flex flexDirection="column" align="center" mt="16px">
                <Heading fontSize="18px" color="text" fontWeight="600">
                  What are we building today?
                </Heading>
                <HStack align="flex-start" spacing="24px" mt="64px" mb="48px">
                  {typeOptions.map((option) => (
                    <VStack key={option.type} spacing="16px" w="164px">
                      <IconButton
                        variant="image"
                        icon={option.renderImg()}
                        aria-label="type"
                        onClick={setType(option.type)}
                      />
                      <Heading w="100%" display="flex" alignItems="center">
                        {option.name}
                        <Tooltip label={option.tooltip} placement="top">
                          <Box ml="4px" h="16px" lineHeight="16px">
                            <Icon
                              as={RiInformationLine}
                              boxSize="16px"
                              color="icon"
                            />
                          </Box>
                        </Tooltip>
                      </Heading>
                      <Text fontSize="12px" textAlign="left">
                        {option.description}
                      </Text>
                    </VStack>
                  ))}
                </HStack>
              </Flex>
            )}
            {state.matches({ values: CREATE_PAGE_STATES.FILLING_INFO }) && (
              <Flex justifyContent="center" mt="48px">
                <Box as="form" w="400px">
                  <VStack spacing="32px">
                    <FormControl
                      id="regularTitle"
                      isInvalid={!!errors.regularTitle}
                    >
                      <FormLabel>Page Title</FormLabel>
                      <Input
                        {...register('regularTitle', {
                          ...pageTitleRules,
                          onChange: (e) => {
                            setValue(
                              'regularHandle',
                              toHandle(e.target.value),
                              {
                                shouldValidate: true,
                              }
                            );
                          },
                        })}
                        placeholder="Input page title"
                        maxLength={PAGE_TITLE_MAX_LENGTH}
                      />
                      <CharacterCounter text={regularTitle} />
                      <FormErrorMessage>
                        {errors.regularTitle && errors.regularTitle.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="regularHandle"
                      isInvalid={!!errors.regularHandle}
                    >
                      <FormLabel>Page URL</FormLabel>
                      <InputGroup>
                        <InputLeftAddon children="pages/" />
                        <Input
                          {...register('regularHandle', {
                            ...pageHandleRulesByType(
                              state.context.type as PageType
                            ),
                            onBlur: (e) => {
                              setValue(
                                'regularHandle',
                                toHandle(e.target.value),
                                {
                                  shouldValidate: true,
                                }
                              );
                            },
                          })}
                          placeholder="handle"
                        />
                      </InputGroup>
                      <FormHelperText>{PAGE_HANDLE_HELPER_TEXT}</FormHelperText>
                      <FormErrorMessage>
                        {errors.regularHandle && errors.regularHandle.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Box>
              </Flex>
            )}

            {state.matches({
              values: CREATE_PAGE_STATES.FILLING_POST_INFO,
            }) && (
              <Flex justifyContent="center" mt="48px">
                <Box as="form" w="400px">
                  <VStack spacing="32px">
                    <FormControl id="blog" isInvalid={!!errors.blog}>
                      <FormLabel>Choose a blog</FormLabel>
                      <Controller
                        name="blog"
                        control={control}
                        rules={{
                          required: 'blog is required',
                        }}
                        render={({ field: { onChange } }) => (
                          <Select
                            options={
                              state.context.items?.map((b) => ({
                                name: b.title,
                                value: b,
                              })) || []
                            }
                            itemToString={(item) => item.name}
                            onChange={(option) => {
                              if (option) {
                                onChange(option.value);
                                send({
                                  type: CREATE_PAGE_EVENTS.CHOOSE_SHOPIFY_ITEM,
                                  data: { item: option.value },
                                });
                              }
                            }}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.blog && errors.blog.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl id="postTitle" isInvalid={!!errors.postTitle}>
                      <FormLabel>Page Title</FormLabel>
                      <Input
                        {...register('postTitle', {
                          ...pageTitleRules,
                          onChange: (e) => {
                            setValue('postHandle', toHandle(e.target.value), {
                              shouldValidate: true,
                            });
                          },
                        })}
                        placeholder="Input page title"
                        maxLength={PAGE_TITLE_MAX_LENGTH}
                      />
                      <CharacterCounter text={postTitle} />
                      <FormErrorMessage>
                        {errors.postTitle && errors.postTitle.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="postHandle"
                      isInvalid={!!errors.postHandle}
                    >
                      <FormLabel>Page URL</FormLabel>
                      <InputGroup>
                        <InputLeftAddon
                          children={
                            state.context.item?.title
                              ? `blogs/${state.context.item?.title}/`
                              : 'blogs/'
                          }
                        />
                        <Input
                          {...register('postHandle', {
                            ...pageHandleRulesByType(
                              state.context.type as PageType
                            ),
                            onBlur: (e) => {
                              setValue('postHandle', toHandle(e.target.value), {
                                shouldValidate: true,
                              });
                            },
                          })}
                          placeholder="handle"
                        />
                      </InputGroup>
                      <FormHelperText>{PAGE_HANDLE_HELPER_TEXT}</FormHelperText>
                      <FormErrorMessage>
                        {errors.postHandle && errors.postHandle.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Box>
              </Flex>
            )}
            {state.matches({
              values: CREATE_PAGE_STATES.CHOOSING_SHOPIFY_ITEM,
            }) && (
              <ShopifyItemList
                mt="32px"
                h="586px"
                type={
                  state.context.type === PageType.Collection
                    ? PageType.Collection
                    : PageType.Product
                }
                renderItemButton={(item) =>
                  item.pageId ? (
                    <Button
                      size="sm"
                      variant="outline"
                      ml="34px"
                      as={RouteLink}
                      to={`/pages/${item.pageId}`}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      onClick={() => {
                        send({
                          type: CREATE_PAGE_EVENTS.CHOOSE_SHOPIFY_ITEM,
                          data: { item },
                        });
                      }}
                    >
                      Select
                    </Button>
                  )
                }
              />
            )}

            {state.matches({ values: CREATE_PAGE_STATES.CHOOSING_FROM }) &&
              R.includes(state.context.type, [
                PageType.Regular,
                PageType.Post,
              ]) && (
                <Flex align="center" flexDir="column" mt="64px">
                  <HStack spacing="60px">
                    <VStack>
                      <IconButton
                        variant="image"
                        icon={fromOptions[PageFrom.Blank].renderImg(
                          state.context.type
                        )}
                        aria-label="from"
                        isActive={
                          fromOptions[PageFrom.Blank].type ===
                          state.context.from
                        }
                        onClick={setFrom(fromOptions[PageFrom.Blank].type)}
                      />
                      <Heading fontSize="14px">
                        {fromOptions[PageFrom.Blank].name(state.context.type)}
                      </Heading>
                    </VStack>

                    <VStack>
                      <IconButton
                        variant="image"
                        icon={fromOptions[PageFrom.Extend].renderImg(
                          state.context.type
                        )}
                        aria-label="from"
                        isActive={
                          fromOptions[PageFrom.Extend].type ===
                          state.context.from
                        }
                        onClick={setFrom(fromOptions[PageFrom.Extend].type)}
                      />
                      <Heading fontSize="14px">
                        {fromOptions[PageFrom.Extend].name(state.context.type)}
                      </Heading>
                    </VStack>
                  </HStack>
                </Flex>
              )}

            {state.matches({ values: CREATE_PAGE_STATES.CHOOSING_FROM }) &&
              R.includes(state.context.type, [
                PageType.Product,
                PageType.Collection,
              ]) && (
                <>
                  <Alert variant="tip" mb="16px" mt="32px">
                    <AlertIcon as={RiInformationLine} mx="8px" />
                    <AlertDescription fontWeight="600" p="8px" pl="0">
                      You can only add to or replace your Shopify theme using
                      Layoutbase.
                      <br />
                      You cannot edit content within the theme. To do so, go to
                      Shopify's Theme Editor.
                    </AlertDescription>
                  </Alert>

                  <Flex
                    align="center"
                    flexDir="column"
                    border="1px solid"
                    borderColor="border"
                    borderRadius="4px"
                  >
                    {state.context.item &&
                      R.includes(state.context.type, [
                        PageType.Product,
                        PageType.Collection,
                      ]) && (
                        <Flex flexDirection="column" w="full">
                          <ShopifyItemBar
                            w="full"
                            borderBottom="1px solid"
                            borderColor="border"
                            {...state.context.item}
                          />
                        </Flex>
                      )}
                    <Flex justifyContent="flex-end" width="100%" padding="16px">
                      <Link
                        href={PRODUCT_COLLECTION_CONFIGURATION_HYPERLINK}
                        color="primary"
                        textDecor="underline"
                        isExternal
                        fontSize={12}
                        fontWeight={600}
                      >
                        Read about {capitalize(state.context.type)}{' '}
                        Configuration
                      </Link>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      width="100%"
                      padding="0 16px 32px"
                    >
                      {R.map((option) => {
                        const { type, renderImg, name, description } =
                          fromOptions[option];
                        return (
                          <Box flex="0" key={type}>
                            <Button
                              variant="image"
                              aria-label="from"
                              isActive={type === state.context.from}
                              onClick={setFrom(type)}
                              {...CONFIG_PAGE_BUTTON_STYLE}
                            >
                              {renderImg(state.context.type)}
                            </Button>
                            <Heading
                              fontSize="14px"
                              w="100%"
                              mt="16px"
                              mb="4px"
                            >
                              {name(state.context.type)}
                            </Heading>
                            <Text fontSize="12px">
                              {description(state.context.type)}
                            </Text>
                          </Box>
                        );
                      }, Object.keys(fromOptions))}
                    </Flex>
                  </Flex>
                </>
              )}

            {state.matches({
              values: CREATE_PAGE_STATES.CHOOSING_EXISTING_PAGE,
            }) && (
              <Flex
                mt="32px"
                borderWidth="1px"
                borderColor="border"
                borderRadius="4px"
              >
                <Tabs w="100%">
                  <TabList pt="8px">
                    {R.map(
                      ({ id, name }) => (
                        <Tab key={id} onClick={changePageType(id)}>
                          {name}
                        </Tab>
                      ),
                      PAGE_TYPE_NAME_MAP
                    )}
                  </TabList>
                  <HStack p="16px" borderBottom="1px" borderColor="border">
                    <Box w="258px">
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder="Search page..."
                          onChange={searchText}
                        />
                        <InputRightElement
                          fontSize="20px"
                          color="icon"
                          pointerEvents="none"
                          children={<RiSearch2Line />}
                        />
                      </InputGroup>
                    </Box>
                  </HStack>
                  <Box h="508px" overflow="auto">
                    {pagesState.matches(PAGE_STATES.LOADING) && (
                      <Gallery>
                        <ModalPageCard isLoading />
                        <ModalPageCard isLoading />
                      </Gallery>
                    )}
                    {(pagesState.matches(PAGE_STATES.SEARCH_EMPTY) ||
                      pagesState.matches(PAGE_STATES.EMPTY)) && (
                      <EmptyContainer>
                        <NoResultIcon />
                        <EmptyText>
                          Sorry, we cannot find any page that matched your
                          searching keywords.
                        </EmptyText>
                      </EmptyContainer>
                    )}
                    {pagesState.matches(PAGE_STATES.EMPTY) && (
                      <EmptyContainer>
                        <NewPageIcon />
                        <EmptyText>
                          Welcome! Thank you for using Layoutbase. Please create
                          a new page to get started.
                        </EmptyText>
                      </EmptyContainer>
                    )}
                    {pagesState.matches(PAGE_STATES.SEARCH_EMPTY) && (
                      <EmptyContainer>
                        <NoResultIcon />
                        <EmptyText>
                          Sorry, we cannot find any page that matched your
                          searching keywords.
                        </EmptyText>
                      </EmptyContainer>
                    )}
                    {pagesState.matches(PAGE_STATES.LOADED) && (
                      <Gallery>
                        {pages.map(
                          ({ id, title, type, currentVersion }) =>
                            currentVersion && (
                              <ModalPageCard
                                key={id}
                                id={id}
                                title={title}
                                version={getVersionName(currentVersion)}
                                cover={currentVersion?.cover}
                                type={type}
                                isChoosing={
                                  state.context.pageId === id &&
                                  state.matches({
                                    values:
                                      CREATE_PAGE_STATES.CHOOSING_EXISTING_PAGE,
                                    submit: CREATE_PAGE_STATES.PENDING,
                                  })
                                }
                                onChoose={choosePage}
                              />
                            )
                        )}
                      </Gallery>
                    )}
                    {pagesState.matches(PAGE_STATES.LOADED) && cursorId && (
                      <Center mt="16px" mb="24px">
                        <Button
                          w="142px"
                          variant="outline"
                          isLoading={pagesState.matches(
                            `${PAGE_STATES.LOADED}.${PAGE_STATES.MORE_PAGES_LOADING}`
                          )}
                          loadingText="Load more"
                          onClick={loadMorePages}
                        >
                          Load More
                        </Button>
                      </Center>
                    )}
                  </Box>
                </Tabs>
              </Flex>
            )}
          </ModalBody>
          {(state.matches({ values: CREATE_PAGE_STATES.FILLING_INFO }) ||
            state.matches({
              values: CREATE_PAGE_STATES.FILLING_POST_INFO,
            })) && (
            <ModalFooter justifyContent="center" pt="0" borderTop="none">
              <Box mb="48px">
                <Button disabled={!isValid} onClick={onSubmitInfo}>
                  Next
                </Button>
              </Box>
            </ModalFooter>
          )}
          {state.matches({ values: CREATE_PAGE_STATES.CHOOSING_FROM }) && (
            <ModalFooter justifyContent="center" padding="0" borderTop="none">
              <Box mb="60px">
                <Button
                  w="130px"
                  onClick={onFromNextClick}
                  isLoading={isSubmitting}
                  isDisabled={!state.context.from}
                  loadingText="Loading"
                >
                  {state.context.from !== PageFrom.Extend
                    ? 'Create Page'
                    : 'Next'}
                </Button>
              </Box>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
