import { useEffect, useState } from 'react';
import { Input } from '@chakra-ui/input';
import { Flex } from '@chakra-ui/layout';
import * as R from 'ramda';
import {
  ChakraProps,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';

export enum UNIT {
  PX = 'px',
  PERCENT = '%',
  EM = 'em',
  AUTO = 'auto',
  SECOND = 'SEC',
  DAYS = 'Days',
}
const NORMAL_UNITS = R.without([UNIT.AUTO], R.values(UNIT));

const IS_NUMBER_REGEX = /^-?\d+\.?\d*$/;

const InputWithUnit = ({
  onChange,
  value,
  disableUnit,
  isFitted = true,
  isDisabled,
  align = 'left',
  placeholder,
  ...props
}: {
  onChange: (valueAsString: string, valueAsNumber: number) => void;
  value: string;
  disableUnit?: boolean;
  isFitted?: boolean;
  isDisabled?: boolean;
  align?: 'center' | 'left';
  placeholder?: string;
} & ChakraProps) => {
  const [input, setInput] = useState('');
  const [unit, setUnit] = useState(value === 'auto' ? UNIT.AUTO : UNIT.PX);

  const [isFocusing, setIsFocusing] = useState(false);

  useEffect(() => {
    if (!R.isNil(value) && value !== input && value !== `${input}${unit}`) {
      NORMAL_UNITS.some((unit) => {
        if (value.includes(unit)) {
          setInput(value.slice(0, -unit.length));
          setUnit(unit);

          return true;
        }
        return false;
      });

      if (value === UNIT.AUTO) {
        setInput(value);
        setUnit(UNIT.AUTO);
      }
      if (value === '') {
        setInput(value);
        setUnit(UNIT.PX);
      }
    }
  }, [input, unit, value]);

  return (
    <Flex
      border="1px"
      borderColor={isFocusing ? 'primary' : 'border'}
      borderRadius="md"
      bg={isDisabled ? '#eee' : 'white'}
      w={isFitted ? 'full' : '70px'}
      h="42px"
      _hover={{
        borderColor: isFocusing ? 'primary' : 'hoveredBorder',
      }}
      transitionProperty="common"
      transitionDuration="normal"
      overflow="hidden"
    >
      <Input
        variant="unstyled"
        textAlign={align}
        px={align === 'left' ? '12px' : 1}
        _disabled={{
          color: 'disabledText',
        }}
        placeholder={placeholder || '0'}
        value={input}
        isDisabled={isDisabled || unit === UNIT.AUTO}
        onChange={(e) => {
          setInput(e.target.value);

          // handle css string value like 'auto'
          const isNumericValue = IS_NUMBER_REGEX.test(e.target.value);
          const value = isNumericValue
            ? `${e.target.value}${unit}`
            : e.target.value;
          const numericValue = isNumericValue ? +e.target.value : 0;
          onChange(value, numericValue);
        }}
        onFocus={() => setIsFocusing(true)}
        onBlur={() => setIsFocusing(false)}
        {...props}
      />
      <Menu strategy="fixed">
        <MenuButton
          borderLeftWidth="1px"
          borderColor="border"
          minW="30px"
          fontSize="12px"
          color="text"
          disabled={isDisabled || disableUnit}
          _hover={{
            bg: 'rgba(246, 94, 78, 0.1)',
          }}
          _active={{
            bg: 'rgba(246, 94, 78, 0.1)',
          }}
          _disabled={{
            bg: 'border',
            color: 'text',
            cursor: 'not-allowed',
          }}
        >
          {unit}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            value={unit}
            onChange={(value: UNIT) => {
              setUnit(value);
              if (value !== UNIT.AUTO) {
                setInput('');
                onChange('', 0);
              } else {
                setInput(UNIT.AUTO);
                onChange(UNIT.AUTO, 0);
              }
            }}
          >
            <MenuItemOption value={UNIT.PX}>{UNIT.PX}</MenuItemOption>
            <MenuItemOption value={UNIT.PERCENT}>{UNIT.PERCENT}</MenuItemOption>
            <MenuItemOption value={UNIT.EM}>{UNIT.EM}</MenuItemOption>
            <MenuItemOption value={UNIT.AUTO}>{UNIT.AUTO}</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default InputWithUnit;
