import { ColorSchemaType, RgbaColor, SimpleRGBA } from './types';
import * as R from 'ramda';
import { DEFAULT_FALLBACK_COLOR } from './constants';
import { ColorSchema } from './schema';

export const toSimpleRGBA = (rgba: RgbaColor): SimpleRGBA => ({
  r: rgba.red,
  g: rgba.green,
  b: rgba.blue,
  a: rgba.alpha,
});
export const toRGBAColor = (rgba: SimpleRGBA): RgbaColor => ({
  red: rgba.r,
  green: rgba.g,
  blue: rgba.b,
  alpha: rgba.a,
});

export const rgbaToFullHex = (rgba: RgbaColor, prependHash = false): string =>
  R.pipe(
    R.mapObjIndexed((color: number) => color.toString(16).padStart(2, '0')),
    R.props(['red', 'green', 'blue']),
    R.join(''),
    R.toUpper,
    (x) => (prependHash ? '#' + x : x)
  )(rgba);

export const getFallbackColor = (fallbackColor: ColorSchemaType): RgbaColor => {
  const fallbackColorResult = ColorSchema.safeParse(fallbackColor);
  if (fallbackColorResult.success) {
    return fallbackColorResult.data === 'unset'
      ? DEFAULT_FALLBACK_COLOR
      : fallbackColorResult.data;
  }

  return DEFAULT_FALLBACK_COLOR;
};
