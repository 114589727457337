import * as R from 'ramda';
import { Badge, Text, BoxProps } from '@chakra-ui/react';
import { ProductStatus } from '../../apollo/types.generate';

type ProductStatusBadgeProps = BoxProps & {
  status: ProductStatus;
  publishedAt?: Date | null;
};

const ProductStatusBadge = ({
  status,
  publishedAt,
  ...props
}: ProductStatusBadgeProps) => {
  return status !== ProductStatus.Active ? (
    <Badge
      colorScheme={status === ProductStatus.Draft ? status : 'gray'}
      {...props}
    >
      {status.toLowerCase()}
    </Badge>
  ) : R.isNil(publishedAt) ? (
    <Text fontSize="12px" fontWeight="semibold" {...props}>
      0 Sales Channels
    </Text>
  ) : null;
};

export default ProductStatusBadge;
