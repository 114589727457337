import { Controller, useForm } from 'react-hook-form';
import * as R from 'ramda';
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Stack,
  SimpleGrid,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import {
  addDays,
  isWeekend,
  nextMonday,
  startOfToday,
  eachDayOfInterval,
  setHours,
  isSaturday,
  isSunday,
  addBusinessDays,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { CloseButton } from './CloseButton';
import Select from './Select';
import { useToast } from './Toast';
import {
  SHOPIFY_ORGANIZATION_ID,
  CHAT_INTERVIEW_SUBMISSIONS_WEBHOOK_URL,
  DATE_FORMAT,
  HK_TIME_ZONE,
  HOUR_OPTIONS,
} from '../constants';
import { sendMessageToGoogleChat } from '../utils/sendMessageToGoogleChat';
import { Country, COUNTRIES } from '../constants/countries';
import {
  useSaveInterviewFormMutation,
  useShopifyShopQuery,
} from '../apollo/apollo.generate';

type InterviewFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const InterviewFormModal = ({
  isOpen,
  onClose,
  onSubmit: submit,
}: InterviewFormModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onBlur' });

  const toast = useToast();
  const { data: shopData } = useShopifyShopQuery();
  const [saveInterviewForm, { loading: saveInterviewFormLoading }] =
    useSaveInterviewFormMutation();

  const onSubmit = ({
    firstName,
    lastName,
    phoneCountry,
    phoneNumber,
    email,
    preferredDate,
    preferredTime,
  }) => {
    const preferredDateTime = setHours(preferredDate, +preferredTime.value);
    const zonedDate = utcToZonedTime(preferredDateTime, HK_TIME_ZONE);
    const { name: country, dialCode: countryCode } = JSON.parse(phoneCountry);
    const store = shopData?.shopifyShop;

    const message =
      `Name: ${firstName} ${lastName}\n` +
      `Location: ${country}\n` +
      `Phone: ${countryCode} ${phoneNumber}\n` +
      `Email: ${email}\n` +
      `Preferred Date & Time: \n` +
      `⏰ HK time: ${format(
        zonedDate,
        DATE_FORMAT.DATETIME_WITH_TIMEZONE_WITHOUT_SECOND,
        {
          timeZone: HK_TIME_ZONE,
        }
      )}\n` +
      `🕰️ Customer time: ${format(
        preferredDateTime,
        DATE_FORMAT.DATETIME_WITH_TIMEZONE_WITHOUT_SECOND
      )}\n` +
      `-------------------------------------------------------------------------------------\n` +
      `Store name: ${store?.name}\n` +
      `Store URL: ${store?.domain}\n` +
      `Installation history: https://partners.shopify.com/${SHOPIFY_ORGANIZATION_ID}/stores/${store?.id}`;

    saveInterviewForm({
      variables: {
        input: {
          firstName,
          lastName,
          country,
          countryCode,
          phoneNumber,
          email,
        },
      },
    })
      .then(() => {
        sendMessageToGoogleChat(
          CHAT_INTERVIEW_SUBMISSIONS_WEBHOOK_URL,
          message
        );
        toast({
          title: 'Form submitted.',
          status: 'success',
        });
        submit();
        onClose();
      })
      .catch(() => {
        toast({
          title: 'Failed to submit form, please try again.',
        });
      });
  };

  const startDate = addDays(startOfToday(), 2);
  const startBusinessDate = isWeekend(startDate)
    ? nextMonday(startDate)
    : startDate;

  const preferredDates = R.pipe(
    R.reject(isSaturday),
    R.reject(isSunday)
  )(
    eachDayOfInterval({
      start: startBusinessDate,
      end: addBusinessDays(startBusinessDate, 4),
    })
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        data-track-event="click_overlay_close_modal"
        data-track-parameter-name="placement"
        data-track-parameter-value="interview_form_modal"
      />
      <ModalContent minW="916px" h="690px" mt="150px" pt="64px">
        <ModalHeader textAlign="center" fontSize="24px" fontWeight="bold" p="0">
          Book an Interview Slot
        </ModalHeader>
        <CloseButton
          top="16px"
          data-track-event="click_close_icon"
          data-track-parameter-name="placement"
          data-track-parameter-value="interview_form_modal"
          onClick={onClose}
        />
        <ModalBody w="524px" mx="auto" pt="16px" pb="48px" textAlign="center">
          <Text fontSize="12px">
            Participate in a short User Interview and get a US$ 20 Amazon gift
            card.
          </Text>
          <Text fontSize="12px">
            We will confirm your booking within 24 hours of registration.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="24px" mt="32px">
              <SimpleGrid columns={2} spacingX="24px">
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    {...register('firstName', { required: true })}
                    placeholder="First Name"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    {...register('lastName', { required: true })}
                    placeholder="Last Name"
                  />
                </FormControl>
              </SimpleGrid>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <SimpleGrid columns={2} spacingX="24px">
                  <Controller
                    name="phoneCountry"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <ChakraSelect onChange={onChange} required>
                        <option selected disabled value="">
                          Country Code
                        </option>
                        {R.map((country: Country) => {
                          const { name, dialCode } = country;
                          return (
                            <option key={name} value={JSON.stringify(country)}>
                              {`${name} (${dialCode})`}
                            </option>
                          );
                        })(COUNTRIES)}
                      </ChakraSelect>
                    )}
                  />
                  <Input
                    type="tel"
                    {...register('phoneNumber', { required: true })}
                    placeholder="Your Phone Number"
                  />
                </SimpleGrid>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  {...register('email', { required: true })}
                  placeholder="Your Email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Preferred Date & Time (local time)</FormLabel>
                <SimpleGrid columns={2} spacingX="24px">
                  <Controller
                    name="preferredDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={preferredDates}
                        itemToString={(option) =>
                          format(option, DATE_FORMAT.DATE_WITH_DAY)
                        }
                        onChange={onChange}
                        placeholder="Select Date"
                      />
                    )}
                  />
                  <Controller
                    name="preferredTime"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={HOUR_OPTIONS}
                        itemToString={(option) => option.name}
                        onChange={onChange}
                        placeholder="Select Time"
                      />
                    )}
                  />
                </SimpleGrid>
              </FormControl>
            </Stack>
            <Button
              mt="48px"
              type="submit"
              disabled={!isDirty || !isValid}
              isLoading={saveInterviewFormLoading}
              loadingText="Submitting"
              data-track-event="click_interview_form_submit"
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InterviewFormModal;
