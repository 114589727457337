import { useMultipleSelection, useSelect } from 'downshift';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputGroup,
  Input,
  InputRightElement,
  Flex,
  Stack,
  Checkbox,
  useMultiStyleConfig,
  Placement,
  Spinner,
  InputGroupProps,
  InputLeftElement,
  Portal,
} from '@chakra-ui/react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { useEffect, useState, useRef } from 'react';
import * as R from 'ramda';

type SelectProps<OptionItem extends object | string> =
  | ({
      variant?: string;
      multiple?: false;
      options: OptionItem[];
      placeholder?: string;
      placement?: Placement;
      disabled?: boolean;
      isLoading?: boolean;
      matchWidth?: boolean;
      onChange?: (selected: OptionItem | undefined) => void;
      name?: string;
      value?: OptionItem;
      itemToString?: (item: OptionItem) => string;
      renderLabel?: (item: OptionItem) => React.ReactNode;
      renderInput?: (item: OptionItem) => React.ReactNode;
    } & Omit<InputGroupProps, 'onChange'>)
  | ({
      variant?: string;
      multiple: true;
      options: OptionItem[];
      placeholder?: string;
      placement?: Placement;
      disabled?: boolean;
      isLoading?: boolean;
      matchWidth: boolean;
      onChange?: (selected: OptionItem[] | undefined) => void;
      name?: string;
      value?: OptionItem[];
      itemToString?: (item: OptionItem) => string;
      renderLabel?: (item: OptionItem) => React.ReactNode;
      renderInput?: (item: OptionItem | OptionItem[]) => React.ReactNode;
    } & Omit<InputGroupProps, 'onChange'>);

const defaultItemToString =
  <OptionItem extends object | string>(
    f: undefined | ((item: OptionItem) => string)
  ) =>
  (item: OptionItem): string => {
    if (typeof f === 'function') {
      return f(item);
    }
    return typeof item === 'string' ? item : '';
  };

/**
 *
 * usage
 * 1. single select
 * 單選時 value, onChange 的參數不是 Array，是單一的 option
 * ```
 * const options = ['a','b','c']
 * <Select
 *  options={options}
 *  value={options[0]}
 *  onChange={option => console.log(option)}
 * />
 * ```
 * 2. multiple select
 * 多選時 value, onChange 的參數都是 Array, options
 * ```
 * const options = ['a','b','c']
 * <Select
 *  multiple // 必傳
 *  options={options}
 *  value={[options[0]]}
 *  onChange={options => console.log(options)}
 * />
 * ```
 * 3. object option
 * 當 option 是 object 時，必須傳入`itemToString`
 * `itemToString` 需要回傳 option 的唯一識別值，用來當作 key, 判斷異同用等等用
 * ```
 * const options = [{name: 'a'}, {name: 'b'}, {name: 'c'}]
 * <Select
 *  options={options}
 *  value={options[0]}
 *  onChange={options => console.log(options)}
 *  itemToString={option => option.name} // 必傳
 *  // renderLabel={option => option.name} 可傳入 `renderLabel` 來根據 option  render 選項文字｀
 * />
 * ```
 */

const Select = <OptionItem extends object | string>(
  props: SelectProps<OptionItem>
) => {
  const {
    variant = '',
    multiple = false,
    matchWidth = true,
    options,
    name,
    value,
    placeholder,
    disabled = false,
    isLoading = false,
    itemToString,
    renderLabel,
    renderInput,
    placement = 'bottom-start',
    height = '40px',
    borderWidth = '1px',
    borderColor = 'border',
    borderRadius = 'md',
    ...restProps
  } = props;
  const valueRef = useRef(value);
  const multipleRef = useRef(multiple);
  const _itemToString = defaultItemToString(itemToString);
  const [innerValue, setInnerValue] = useState(() => {
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  });

  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({
    initialSelectedItems: innerValue,
    onSelectedItemsChange: ({ selectedItems }) => {
      if (props.multiple) {
        if (
          props.onChange &&
          selectedItems &&
          R.symmetricDifference(value as OptionItem[], selectedItems).length !==
            0 &&
          R.symmetricDifference(innerValue, selectedItems).length !== 0
        ) {
          props.onChange(selectedItems);
        }
        setInnerValue(selectedItems || []);
      } else {
        if (
          props.onChange &&
          selectedItems &&
          !R.isEmpty(selectedItems) &&
          !R.equals(value, selectedItems[0]) &&
          !R.equals(innerValue[0], selectedItems[0])
        ) {
          props.onChange(selectedItems[0]);
        }
        setInnerValue(
          Array.isArray(selectedItems) ? selectedItems.slice(0, 1) : []
        );
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: options,
    itemToString: _itemToString,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          const { selectedItem } = changes;
          if (selectedItem && multiple) {
            const item = selectedItems.find(
              (i) => _itemToString(i) === _itemToString(selectedItem)
            );
            if (item) {
              removeSelectedItem(item);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          return {
            ...changes,
            isOpen: multiple ? true : false,
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem && !multiple) {
            setSelectedItems([selectedItem]);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (multipleRef.current !== multiple) {
      setSelectedItems([]);
      multipleRef.current = multiple;
    }
  }, [setSelectedItems, multiple]);

  useEffect(() => {
    if (valueRef.current === value) return;
    valueRef.current = value;
    const newValue = value ? (Array.isArray(value) ? value : [value]) : [];
    if (!R.equals(newValue, selectedItems)) {
      setSelectedItems(newValue);
      selectItem(newValue[0]);
    }
  }, [selectItem, selectedItems, setSelectedItems, value]);

  const styles = useMultiStyleConfig('StyledSelect', {
    active: isOpen,
    variant,
  });

  return (
    <Popover
      matchWidth={matchWidth}
      gutter={0}
      isOpen={isOpen}
      placement={placement}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup
          {...restProps}
          borderWidth={borderWidth}
          borderColor={borderColor}
          borderRadius={borderRadius}
          {...getToggleButtonProps(
            getDropdownProps({
              preventKeyAction: isOpen,
              disabled: disabled,
            })
          )}
        >
          {renderInput && (
            <InputLeftElement
              w="auto"
              pl="12px"
              userSelect="none"
              cursor="pointer"
            >
              {multiple
                ? (renderInput as (item: OptionItem[]) => React.ReactNode)(
                    selectedItems
                  )
                : renderInput(selectedItems[0])}
            </InputLeftElement>
          )}
          <Input
            sx={styles.input}
            border="0"
            height={height}
            active={isOpen || undefined}
            name={name}
            value={
              selectedItems.length === 0 || typeof renderInput === 'function'
                ? ''
                : multiple
                ? `${selectedItems.length} Selected`
                : _itemToString(selectedItems[0])
            }
            placeholder={placeholder}
            disabled={isLoading || disabled}
            isReadOnly
          />
          <InputRightElement
            sx={styles.icon}
            height={height}
            children={
              isLoading ? (
                <Spinner size="sm" color="disabledText" />
              ) : isOpen ? (
                <RiArrowDropUpLine />
              ) : (
                <RiArrowDropDownLine />
              )
            }
          />
        </InputGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent sx={styles.popoverContent}>
          <PopoverBody sx={styles.popoverBody}>
            <Stack
              spacing={0}
              sx={styles.list}
              {...getMenuProps({}, { suppressRefError: true })}
            >
              {options.map((item, index) => {
                return (
                  <Flex
                    key={R.propOr(_itemToString(item), 'id', item)}
                    sx={styles.option}
                    highlight={+(index === highlightedIndex)}
                    {...getItemProps({ item, index })}
                  >
                    {multiple && (
                      <Checkbox
                        sx={styles.checkbox}
                        isChecked={
                          !!selectedItems.find(
                            (i) => _itemToString(i) === _itemToString(item)
                          )
                        }
                        pointerEvents="none"
                        isReadOnly
                      />
                    )}
                    {renderLabel ? renderLabel(item) : _itemToString(item)}
                  </Flex>
                );
              })}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default Select;
