import { DocumentNode } from 'graphql';
import * as Operations from './operations.generate';




























































export type Requester<C= {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    interviewForm(variables?: Operations.InterviewFormQueryVariables, options?: C): Promise<Operations.InterviewFormQuery> {
      return requester<Operations.InterviewFormQuery, Operations.InterviewFormQueryVariables>(Operations.InterviewFormDocument, variables, options);
    },
    showGiftTeaser(variables?: Operations.ShowGiftTeaserMutationVariables, options?: C): Promise<Operations.ShowGiftTeaserMutation> {
      return requester<Operations.ShowGiftTeaserMutation, Operations.ShowGiftTeaserMutationVariables>(Operations.ShowGiftTeaserDocument, variables, options);
    },
    saveInterviewForm(variables: Operations.SaveInterviewFormMutationVariables, options?: C): Promise<Operations.SaveInterviewFormMutation> {
      return requester<Operations.SaveInterviewFormMutation, Operations.SaveInterviewFormMutationVariables>(Operations.SaveInterviewFormDocument, variables, options);
    },
    getImages(variables?: Operations.GetImagesQueryVariables, options?: C): Promise<Operations.GetImagesQuery> {
      return requester<Operations.GetImagesQuery, Operations.GetImagesQueryVariables>(Operations.GetImagesDocument, variables, options);
    },
    uploadImage(variables: Operations.UploadImageMutationVariables, options?: C): Promise<Operations.UploadImageMutation> {
      return requester<Operations.UploadImageMutation, Operations.UploadImageMutationVariables>(Operations.UploadImageDocument, variables, options);
    },
    ShopifyLogin(variables: Operations.ShopifyLoginMutationVariables, options?: C): Promise<Operations.ShopifyLoginMutation> {
      return requester<Operations.ShopifyLoginMutation, Operations.ShopifyLoginMutationVariables>(Operations.ShopifyLoginDocument, variables, options);
    },
    ShopifyInstall(variables: Operations.ShopifyInstallMutationVariables, options?: C): Promise<Operations.ShopifyInstallMutation> {
      return requester<Operations.ShopifyInstallMutation, Operations.ShopifyInstallMutationVariables>(Operations.ShopifyInstallDocument, variables, options);
    },
    login(variables: Operations.LoginMutationVariables, options?: C): Promise<Operations.LoginMutation> {
      return requester<Operations.LoginMutation, Operations.LoginMutationVariables>(Operations.LoginDocument, variables, options);
    },
    setPassword(variables: Operations.SetPasswordMutationVariables, options?: C): Promise<Operations.SetPasswordMutation> {
      return requester<Operations.SetPasswordMutation, Operations.SetPasswordMutationVariables>(Operations.SetPasswordDocument, variables, options);
    },
    logout(variables?: Operations.LogoutMutationVariables, options?: C): Promise<Operations.LogoutMutation> {
      return requester<Operations.LogoutMutation, Operations.LogoutMutationVariables>(Operations.LogoutDocument, variables, options);
    },
    createShopifyWebhook(variables?: Operations.CreateShopifyWebhookMutationVariables, options?: C): Promise<Operations.CreateShopifyWebhookMutation> {
      return requester<Operations.CreateShopifyWebhookMutation, Operations.CreateShopifyWebhookMutationVariables>(Operations.CreateShopifyWebhookDocument, variables, options);
    },
    verifyEmail(variables: Operations.VerifyEmailQueryVariables, options?: C): Promise<Operations.VerifyEmailQuery> {
      return requester<Operations.VerifyEmailQuery, Operations.VerifyEmailQueryVariables>(Operations.VerifyEmailDocument, variables, options);
    },
    verifyResetPasswordToken(variables: Operations.VerifyResetPasswordTokenQueryVariables, options?: C): Promise<Operations.VerifyResetPasswordTokenQuery> {
      return requester<Operations.VerifyResetPasswordTokenQuery, Operations.VerifyResetPasswordTokenQueryVariables>(Operations.VerifyResetPasswordTokenDocument, variables, options);
    },
    forgotPassword(variables: Operations.ForgotPasswordMutationVariables, options?: C): Promise<Operations.ForgotPasswordMutation> {
      return requester<Operations.ForgotPasswordMutation, Operations.ForgotPasswordMutationVariables>(Operations.ForgotPasswordDocument, variables, options);
    },
    resetPassword(variables: Operations.ResetPasswordMutationVariables, options?: C): Promise<Operations.ResetPasswordMutation> {
      return requester<Operations.ResetPasswordMutation, Operations.ResetPasswordMutationVariables>(Operations.ResetPasswordDocument, variables, options);
    },
    createPage(variables: Operations.CreatePageMutationVariables, options?: C): Promise<Operations.CreatePageMutation> {
      return requester<Operations.CreatePageMutation, Operations.CreatePageMutationVariables>(Operations.CreatePageDocument, variables, options);
    },
    deletePage(variables: Operations.DeletePageMutationVariables, options?: C): Promise<Operations.DeletePageMutation> {
      return requester<Operations.DeletePageMutation, Operations.DeletePageMutationVariables>(Operations.DeletePageDocument, variables, options);
    },
    savePage(variables: Operations.SavePageMutationVariables, options?: C): Promise<Operations.SavePageMutation> {
      return requester<Operations.SavePageMutation, Operations.SavePageMutationVariables>(Operations.SavePageDocument, variables, options);
    },
    revertPage(variables: Operations.RevertPageMutationVariables, options?: C): Promise<Operations.RevertPageMutation> {
      return requester<Operations.RevertPageMutation, Operations.RevertPageMutationVariables>(Operations.RevertPageDocument, variables, options);
    },
    verifyHandle(variables: Operations.VerifyHandleQueryVariables, options?: C): Promise<Operations.VerifyHandleQuery> {
      return requester<Operations.VerifyHandleQuery, Operations.VerifyHandleQueryVariables>(Operations.VerifyHandleDocument, variables, options);
    },
    updateHomepage(variables: Operations.UpdateHomepageMutationVariables, options?: C): Promise<Operations.UpdateHomepageMutation> {
      return requester<Operations.UpdateHomepageMutation, Operations.UpdateHomepageMutationVariables>(Operations.UpdateHomepageDocument, variables, options);
    },
    updatePageSettings(variables: Operations.UpdatePageSettingsMutationVariables, options?: C): Promise<Operations.UpdatePageSettingsMutation> {
      return requester<Operations.UpdatePageSettingsMutation, Operations.UpdatePageSettingsMutationVariables>(Operations.UpdatePageSettingsDocument, variables, options);
    },
    pages(variables?: Operations.PagesQueryVariables, options?: C): Promise<Operations.PagesQuery> {
      return requester<Operations.PagesQuery, Operations.PagesQueryVariables>(Operations.PagesDocument, variables, options);
    },
    page(variables: Operations.PageQueryVariables, options?: C): Promise<Operations.PageQuery> {
      return requester<Operations.PageQuery, Operations.PageQueryVariables>(Operations.PageDocument, variables, options);
    },
    pageVersions(variables: Operations.PageVersionsQueryVariables, options?: C): Promise<Operations.PageVersionsQuery> {
      return requester<Operations.PageVersionsQuery, Operations.PageVersionsQueryVariables>(Operations.PageVersionsDocument, variables, options);
    },
    pageVersion(variables: Operations.PageVersionQueryVariables, options?: C): Promise<Operations.PageVersionQuery> {
      return requester<Operations.PageVersionQuery, Operations.PageVersionQueryVariables>(Operations.PageVersionDocument, variables, options);
    },
    dam(variables?: Operations.DamQueryVariables, options?: C): Promise<Operations.DamQuery> {
      return requester<Operations.DamQuery, Operations.DamQueryVariables>(Operations.DamDocument, variables, options);
    },
    user(variables?: Operations.UserQueryVariables, options?: C): Promise<Operations.UserQuery> {
      return requester<Operations.UserQuery, Operations.UserQueryVariables>(Operations.UserDocument, variables, options);
    },
    shopifyProducts(variables?: Operations.ShopifyProductsQueryVariables, options?: C): Promise<Operations.ShopifyProductsQuery> {
      return requester<Operations.ShopifyProductsQuery, Operations.ShopifyProductsQueryVariables>(Operations.ShopifyProductsDocument, variables, options);
    },
    shopifyProduct(variables: Operations.ShopifyProductQueryVariables, options?: C): Promise<Operations.ShopifyProductQuery> {
      return requester<Operations.ShopifyProductQuery, Operations.ShopifyProductQueryVariables>(Operations.ShopifyProductDocument, variables, options);
    },
    latestShopifyProduct(variables?: Operations.LatestShopifyProductQueryVariables, options?: C): Promise<Operations.LatestShopifyProductQuery> {
      return requester<Operations.LatestShopifyProductQuery, Operations.LatestShopifyProductQueryVariables>(Operations.LatestShopifyProductDocument, variables, options);
    },
    shopifyCollections(variables?: Operations.ShopifyCollectionsQueryVariables, options?: C): Promise<Operations.ShopifyCollectionsQuery> {
      return requester<Operations.ShopifyCollectionsQuery, Operations.ShopifyCollectionsQueryVariables>(Operations.ShopifyCollectionsDocument, variables, options);
    },
    shopifyCollection(variables: Operations.ShopifyCollectionQueryVariables, options?: C): Promise<Operations.ShopifyCollectionQuery> {
      return requester<Operations.ShopifyCollectionQuery, Operations.ShopifyCollectionQueryVariables>(Operations.ShopifyCollectionDocument, variables, options);
    },
    latestShopifyCollection(variables?: Operations.LatestShopifyCollectionQueryVariables, options?: C): Promise<Operations.LatestShopifyCollectionQuery> {
      return requester<Operations.LatestShopifyCollectionQuery, Operations.LatestShopifyCollectionQueryVariables>(Operations.LatestShopifyCollectionDocument, variables, options);
    },
    shopifyBlogs(variables?: Operations.ShopifyBlogsQueryVariables, options?: C): Promise<Operations.ShopifyBlogsQuery> {
      return requester<Operations.ShopifyBlogsQuery, Operations.ShopifyBlogsQueryVariables>(Operations.ShopifyBlogsDocument, variables, options);
    },
    shopifyBlog(variables: Operations.ShopifyBlogQueryVariables, options?: C): Promise<Operations.ShopifyBlogQuery> {
      return requester<Operations.ShopifyBlogQuery, Operations.ShopifyBlogQueryVariables>(Operations.ShopifyBlogDocument, variables, options);
    },
    shopifyProductsCount(variables?: Operations.ShopifyProductsCountQueryVariables, options?: C): Promise<Operations.ShopifyProductsCountQuery> {
      return requester<Operations.ShopifyProductsCountQuery, Operations.ShopifyProductsCountQueryVariables>(Operations.ShopifyProductsCountDocument, variables, options);
    },
    shopifyCollectionsCount(variables?: Operations.ShopifyCollectionsCountQueryVariables, options?: C): Promise<Operations.ShopifyCollectionsCountQuery> {
      return requester<Operations.ShopifyCollectionsCountQuery, Operations.ShopifyCollectionsCountQueryVariables>(Operations.ShopifyCollectionsCountDocument, variables, options);
    },
    shopifyShop(variables?: Operations.ShopifyShopQueryVariables, options?: C): Promise<Operations.ShopifyShopQuery> {
      return requester<Operations.ShopifyShopQuery, Operations.ShopifyShopQueryVariables>(Operations.ShopifyShopDocument, variables, options);
    },
    schedules(variables: Operations.SchedulesQueryVariables, options?: C): Promise<Operations.SchedulesQuery> {
      return requester<Operations.SchedulesQuery, Operations.SchedulesQueryVariables>(Operations.SchedulesDocument, variables, options);
    },
    createSchedule(variables: Operations.CreateScheduleMutationVariables, options?: C): Promise<Operations.CreateScheduleMutation> {
      return requester<Operations.CreateScheduleMutation, Operations.CreateScheduleMutationVariables>(Operations.CreateScheduleDocument, variables, options);
    },
    deleteSchedule(variables: Operations.DeleteScheduleMutationVariables, options?: C): Promise<Operations.DeleteScheduleMutation> {
      return requester<Operations.DeleteScheduleMutation, Operations.DeleteScheduleMutationVariables>(Operations.DeleteScheduleDocument, variables, options);
    },
    shopifyPublishPage(variables: Operations.ShopifyPublishPageMutationVariables, options?: C): Promise<Operations.ShopifyPublishPageMutation> {
      return requester<Operations.ShopifyPublishPageMutation, Operations.ShopifyPublishPageMutationVariables>(Operations.ShopifyPublishPageDocument, variables, options);
    },
    verifyCategory(variables: Operations.VerifyCategoryQueryVariables, options?: C): Promise<Operations.VerifyCategoryQuery> {
      return requester<Operations.VerifyCategoryQuery, Operations.VerifyCategoryQueryVariables>(Operations.VerifyCategoryDocument, variables, options);
    },
    snippets(variables?: Operations.SnippetsQueryVariables, options?: C): Promise<Operations.SnippetsQuery> {
      return requester<Operations.SnippetsQuery, Operations.SnippetsQueryVariables>(Operations.SnippetsDocument, variables, options);
    },
    librarySnippets(variables: Operations.LibrarySnippetsQueryVariables, options?: C): Promise<Operations.LibrarySnippetsQuery> {
      return requester<Operations.LibrarySnippetsQuery, Operations.LibrarySnippetsQueryVariables>(Operations.LibrarySnippetsDocument, variables, options);
    },
    librarySnippetCategories(variables?: Operations.LibrarySnippetCategoriesQueryVariables, options?: C): Promise<Operations.LibrarySnippetCategoriesQuery> {
      return requester<Operations.LibrarySnippetCategoriesQuery, Operations.LibrarySnippetCategoriesQueryVariables>(Operations.LibrarySnippetCategoriesDocument, variables, options);
    },
    snippetCategories(variables?: Operations.SnippetCategoriesQueryVariables, options?: C): Promise<Operations.SnippetCategoriesQuery> {
      return requester<Operations.SnippetCategoriesQuery, Operations.SnippetCategoriesQueryVariables>(Operations.SnippetCategoriesDocument, variables, options);
    },
    createSnippet(variables: Operations.CreateSnippetMutationVariables, options?: C): Promise<Operations.CreateSnippetMutation> {
      return requester<Operations.CreateSnippetMutation, Operations.CreateSnippetMutationVariables>(Operations.CreateSnippetDocument, variables, options);
    },
    deleteSnippet(variables: Operations.DeleteSnippetMutationVariables, options?: C): Promise<Operations.DeleteSnippetMutation> {
      return requester<Operations.DeleteSnippetMutation, Operations.DeleteSnippetMutationVariables>(Operations.DeleteSnippetDocument, variables, options);
    },
    deleteSnippetCategory(variables: Operations.DeleteSnippetCategoryMutationVariables, options?: C): Promise<Operations.DeleteSnippetCategoryMutation> {
      return requester<Operations.DeleteSnippetCategoryMutation, Operations.DeleteSnippetCategoryMutationVariables>(Operations.DeleteSnippetCategoryDocument, variables, options);
    },
    createLibrarySnippet(variables: Operations.CreateLibrarySnippetMutationVariables, options?: C): Promise<Operations.CreateLibrarySnippetMutation> {
      return requester<Operations.CreateLibrarySnippetMutation, Operations.CreateLibrarySnippetMutationVariables>(Operations.CreateLibrarySnippetDocument, variables, options);
    },
    deleteLibrarySnippet(variables: Operations.DeleteLibrarySnippetMutationVariables, options?: C): Promise<Operations.DeleteLibrarySnippetMutation> {
      return requester<Operations.DeleteLibrarySnippetMutation, Operations.DeleteLibrarySnippetMutationVariables>(Operations.DeleteLibrarySnippetDocument, variables, options);
    },
    getStores(variables?: Operations.GetStoresQueryVariables, options?: C): Promise<Operations.GetStoresQuery> {
      return requester<Operations.GetStoresQuery, Operations.GetStoresQueryVariables>(Operations.GetStoresDocument, variables, options);
    },
    switchStore(variables: Operations.SwitchStoreMutationVariables, options?: C): Promise<Operations.SwitchStoreMutation> {
      return requester<Operations.SwitchStoreMutation, Operations.SwitchStoreMutationVariables>(Operations.SwitchStoreDocument, variables, options);
    },
    charge(variables: Operations.ChargeMutationVariables, options?: C): Promise<Operations.ChargeMutation> {
      return requester<Operations.ChargeMutation, Operations.ChargeMutationVariables>(Operations.ChargeDocument, variables, options);
    },
    checkCharge(variables?: Operations.CheckChargeMutationVariables, options?: C): Promise<Operations.CheckChargeMutation> {
      return requester<Operations.CheckChargeMutation, Operations.CheckChargeMutationVariables>(Operations.CheckChargeDocument, variables, options);
    },
    updateUser(variables: Operations.UpdateUserMutationVariables, options?: C): Promise<Operations.UpdateUserMutation> {
      return requester<Operations.UpdateUserMutation, Operations.UpdateUserMutationVariables>(Operations.UpdateUserDocument, variables, options);
    },
    permission(variables?: Operations.PermissionQueryVariables, options?: C): Promise<Operations.PermissionQuery> {
      return requester<Operations.PermissionQuery, Operations.PermissionQueryVariables>(Operations.PermissionDocument, variables, options);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;