import { memo } from 'react';
import * as R from 'ramda';
import { Text } from '@chakra-ui/react';
import { PAGE_TITLE_MAX_LENGTH } from '../constants/page';

type CharacterCounterProps = {
  text: string;
  maximum?: number;
};

const CharacterCounter = ({
  text,
  maximum = PAGE_TITLE_MAX_LENGTH,
}: CharacterCounterProps) => (
  <Text
    pos="absolute"
    right="0"
    fontSize="12px"
    color={
      R.pipe(R.defaultTo(''), R.propEq('length', maximum))(text)
        ? 'primaryDark'
        : 'text'
    }
  >
    {R.propOr(0, 'length', text)}/{maximum}
  </Text>
);

export default memo(CharacterCounter);
