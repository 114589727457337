import { ComponentData } from './CDContext';
import { EditorComponentIds } from './components';
import { ELEMENT_CATEGORY } from './constants';

export enum DragType {
  block = 'block',
  component = 'component',
  snippet = 'snippet',
}
export enum ComponentType {
  component = 'component',
  shopify_product_component = 'shopify_product_component',
  shopify_collection_component = 'shopify_collection_component',
  shopify_customer_form_component = 'shopify_customer_form_component',
  shopify_contact_form_component = 'shopify_contact_form_component',
}

export type DragItemType =
  | {
      type: DragType.block;
      title: string;
      componentId: EditorComponentIds;
      props: any;
      componentType: ComponentType;
      isGlobal?: boolean;
    }
  | {
      type: DragType.component;
      index: number;
      path: number[];
      componentType: ComponentType;
      componentId: EditorComponentIds;
    }
  | {
      type: DragType.snippet;
      componentData: ComponentData;
      componentType: ComponentType;
      componentId: EditorComponentIds;
    };

export enum AdditionalTooltipType {
  INFO,
  QUESTION,
}
export enum AdditionalTooltipAction {
  NONE,
  LINK,
}
export type AdditionalTooltip = {
  label: string;
  type: AdditionalTooltipType;
  action?: AdditionalTooltipAction;
  link?: string;
};
export type Element = {
  title: string;
  category: ELEMENT_CATEGORY;
  componentId: EditorComponentIds;
  defaultProps?: any;
  icon?: any;
  componentType: ComponentType;
  hidden?: boolean;
  isGlobal?: boolean;
  additionalTooltip?: AdditionalTooltip;
};
