import {
  Box,
  ChakraProps,
  Flex,
  HStack,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

const RadioContainer = styled(Box)`
  &:first-of-type > div {
    border-radius: 4px 0 0 4px;
  }
  &:last-child > div {
    border-radius: 0 4px 4px 0;
  }
  &:not(:first-of-type) {
    margin-inline-start: 0;
    > div {
      border-left: 0;
    }
  }
`;

export const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <RadioContainer as="label" flex="1">
      <Flex
        {...checkbox}
        justify="center"
        align="center"
        borderWidth="1px"
        borderColor="line"
        w="full"
        h="42px"
        color="text"
        fontSize="12px"
        cursor="pointer"
        _checked={{
          bg: 'primary',
          color: 'white',
          borderColor: 'primary',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _disabled={{
          bg: '#eee',
          color: 'disabledText',
          _checked: {
            borderColor: 'line',
          },
        }}
      >
        <input {...input} />
        {props.children}
      </Flex>
    </RadioContainer>
  );
};

export type RadioOption = {
  value: string;
  label?: string;
  renderComponent?: (isChecked: boolean) => React.ReactNode;
};

type RadioCardGroupProps = {
  name: string;
  options: RadioOption[];
  value?: string;
  onChange?: (nextValue: string) => void;
  isDisabled?: boolean;
} & ChakraProps;

export const RadioCardGroup = ({
  name,
  options,
  value,
  onChange,
  isDisabled,
  ...props
}: RadioCardGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: value,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <HStack maxW="full" {...group} {...props}>
      {options.map((option) => {
        const radio = getRadioProps({
          value: option.value,
        });
        return (
          <RadioCard key={option.value} {...radio} isDisabled={isDisabled}>
            {option.renderComponent &&
              option.renderComponent(value === option.value)}
            {option.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
