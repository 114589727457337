import { useState, useEffect } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
} from '@chakra-ui/react';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCalendarLine,
} from 'react-icons/ri';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { format, isSameDay, isPast, isToday } from 'date-fns';
import { DATE_FORMAT, DEFAULT_DATE } from '../constants';

const Wrapper = styled(Box)`
  .react-datepicker__header {
    background-color: unset;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: inherit;
  }
`;

const CalendarContainer = ({ className, children }) => {
  return (
    <Wrapper
      border={0}
      boxShadow="0 2px 16px 0 rgba(0, 0, 0, 0.2)"
      className={className}
    >
      {children}
    </Wrapper>
  );
};

const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = R.range(
    DEFAULT_DATE.getFullYear(),
    DEFAULT_DATE.getFullYear() + 5
  );
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <Flex justify="center" pt={2} px={2}>
      <IconButton
        icon={<RiArrowLeftSLine />}
        size="sm"
        variant="ghost"
        aria-label=""
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <Select
        size="sm"
        value={`${date.getFullYear()}`}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <Select
        size="sm"
        pl={2}
        value={months[date.getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <IconButton
        icon={<RiArrowRightSLine />}
        size="sm"
        variant="ghost"
        aria-label=""
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </Flex>
  );
};

const renderDayContents = (day, date, selectedDate) => {
  const isSelected = isSameDay(date, selectedDate);
  return (
    <Button
      variant="dataPicker"
      isActive={isSelected}
      isDisabled={isPast(date) && !isToday(date)}
      size="xs"
      p={0}
    >
      {day}
    </Button>
  );
};

type TimeInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

const TimeInput = ({ value, onChange }: TimeInputProps) => (
  <Input
    type="time"
    value={value}
    onChange={(e) => {
      const value = e.target.value;
      if (onChange && value) {
        onChange(value);
      }
    }}
    size="sm"
  />
);

type DatetimePickerProps = ReactDatePickerProps & {
  showIcon?: boolean;
  displayFormat?: DATE_FORMAT;
};
const DatetimePicker = ({
  selected,
  onChange,
  onBlur,
  placeholderText,
  disabled,
  showIcon = true,
  showTimeInput = true,
  displayFormat,
  ...props
}: DatetimePickerProps) => {
  const [selectedDate, setSelectedDate] =
    useState<Date | null | undefined>(selected);

  useEffect(() => {
    if (!R.equals(selected, selectedDate)) {
      setSelectedDate(selected);
    }
  }, [selected, selectedDate]);

  return (
    <Popover>
      <PopoverTrigger>
        <InputGroup cursor="pointer">
          <Input
            value={
              selectedDate
                ? format(
                    selectedDate,
                    displayFormat ||
                      (showTimeInput ? DATE_FORMAT.DATETIME : DATE_FORMAT.DATE)
                  )
                : ''
            }
            autoComplete="off"
            placeholder={placeholderText}
            isDisabled={disabled}
            cursor="pointer"
            readOnly
          />
          {showIcon && (
            <InputRightElement
              children={
                <Icon as={RiCalendarLine} color="icon" boxSize="24px" />
              }
            />
          )}
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent w="auto" onBlur={onBlur}>
        <DatePicker
          selected={selectedDate}
          onChange={(
            date: Date | null,
            event: React.SyntheticEvent<any> | undefined
          ) => {
            setSelectedDate(date);
            if (onChange) {
              onChange(date, event);
            }
          }}
          calendarContainer={CalendarContainer}
          renderCustomHeader={renderCustomHeader}
          renderDayContents={(day, date) =>
            renderDayContents(day, date, selectedDate)
          }
          customTimeInput={<TimeInput />}
          showTimeInput={showTimeInput}
          shouldCloseOnSelect={false}
          inline
          {...props}
        />
      </PopoverContent>
    </Popover>
  );
};
export default DatetimePicker;
