import { PageType } from '../apollo/types.generate';
import {
  RegularPageIcon,
  ProductPageIcon,
  CollectionPageIcon,
  BlogPostPageIcon,
} from '../components/Icons';

export const PAGE_PATH_BY_TYPE = {
  [PageType.Regular]: 'pages',
  [PageType.Post]: 'blogs',
  [PageType.Product]: 'products',
  [PageType.Collection]: 'collections',
};

export const PAGE_TITLE_MAX_LENGTH = 255;

export const PAGE_HANDLE_HELPER_TEXT = `Page URL is a unique public URL for your page. Only supports
lowercase letters (a-z), numbers (0-9), dashes (-), underscores
(_), or Mandarin characters.`;

export const PRODUCT_COLLECTION_CONFIGURATION_HYPERLINK =
  'https://layoutbase.helpdocs.io/article/mmphpat8k8-configuring-new-product-collection-pages';

export const CONTENTS_BY_TYPE = {
  [PageType.Regular]: {
    typeName: 'Regular',
    icon: RegularPageIcon,
    description:
      'Create custom pages to house static content for your customers. Common Regular Pages include homepage, about us page, customer forms, or campaign landing page.',
    documentUrl:
      'https://layoutbase.helpdocs.io/article/e29bq7kqqi-regular-pages',
    typeNameForTracking: 'regular',
  },
  [PageType.Product]: {
    typeName: 'Product',
    icon: ProductPageIcon,
    description:
      'Build a product page to display information as listed on Shopify. Add content to existing Product Pages or create a new one from scratch.',
    documentUrl:
      'https://layoutbase.helpdocs.io/article/uhfkr6ti4k-product-page',
    typeNameForTracking: 'product',
  },
  [PageType.Collection]: {
    typeName: 'Collection',
    icon: CollectionPageIcon,
    description:
      'Build a Collection Page to display collection information as listed on Shopify. Add content to existing Collection Pages or create a new one from scratch.',
    documentUrl:
      'https://layoutbase.helpdocs.io/article/9wx2p7un11-collection-page',
    typeNameForTracking: 'collection',
  },
  [PageType.Post]: {
    typeName: 'Blog Post',
    icon: BlogPostPageIcon,
    description:
      'Create a new blog entry. Customize how you display text and visuals with maximum flexibility.',
    documentUrl: 'https://layoutbase.helpdocs.io/article/xvem3j910r-blog-page',
    typeNameForTracking: 'blog_post',
  },
};
