import { DEBOUNCE_UPDATE_TIME } from '../pages/editor/constants';

type Debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait?: number
) => (
  ...args: Parameters<T>
) => ReturnType<T> extends Promise<any>
  ? ReturnType<T>
  : Promise<ReturnType<T>>;

export const debounce: Debounce = (f, wait = DEBOUNCE_UPDATE_TIME) => {
  let timer: any = null;

  const ff = (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(...args)), wait);
    }) as ReturnType<typeof f> extends Promise<any>
      ? ReturnType<typeof f>
      : Promise<ReturnType<typeof f>>;
  };
  return ff;
};
