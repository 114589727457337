import React, { ReactElement } from 'react';
import * as R from 'ramda';
import { Link as RouteLink } from 'react-router-dom';
import {
  LinkBox,
  LinkOverlay,
  Skeleton,
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Heading,
  Text,
  Badge,
  useMultiStyleConfig,
  IconButton,
  Portal,
  MenuButton,
  Menu,
} from '@chakra-ui/react';
import { RiHome5Line, RiMore2Fill } from 'react-icons/ri';
import { PageStatus } from '../apollo/types.generate';

type PageCardProps =
  | {
      variant?: 'list' | 'card';
      cover?: string | null;
      title: string;
      version: string;
      status: PageStatus;
      link: string;
      isHomepage?: boolean;
      isLoading?: false;
      option: ReactElement;
      pagePath?: string;
    }
  | {
      variant?: 'list' | 'card';
      isLoading: true;
    };

export const PageCard = (props: PageCardProps) => {
  const styles = useMultiStyleConfig('PageCard', {
    variant: props.variant || 'list',
  });
  const loadingStyles = useMultiStyleConfig('PageCardLoading', {
    variant: props.variant || 'list',
  });
  if (props.isLoading) {
    return (
      <Flex sx={styles.container}>
        <Skeleton sx={{ ...styles.cover, ...loadingStyles.cover }} />
        <Box>
          <Heading sx={styles.title}>
            <Skeleton sx={loadingStyles.title} />
            <Skeleton sx={loadingStyles.description} />
          </Heading>
        </Box>
      </Flex>
    );
  }

  const {
    cover = '',
    title,
    version,
    status = PageStatus.Draft,
    link,
    isHomepage = false,
    option,
    pagePath,
  } = props;

  return (
    <LinkBox>
      <Flex sx={styles.container} role="group">
        <Icon
          sx={styles.markIcon}
          as={RiHome5Line}
          visibility={isHomepage ? 'visible' : 'hidden'}
        />
        <Image
          sx={styles.cover}
          src={cover || ''}
          fallbackSrc={`${process.env.PUBLIC_URL}image/img_card_default_cover.png`}
        />
        <Box>
          <LinkOverlay as={RouteLink} to={link}>
            <Heading sx={styles.title} noOfLines={2}>
              {title}
            </Heading>
          </LinkOverlay>
          <Text sx={styles.description} isTruncated>
            /{pagePath}
          </Text>
        </Box>
        <Box sx={styles.option}>
          <Menu>
            <MenuButton
              variant="icon"
              size="icon-md"
              sx={styles.optionButton}
              as={IconButton}
              icon={<RiMore2Fill />}
              aria-label="option"
            />
            <Portal>{option}</Portal>
          </Menu>
        </Box>
        <HStack sx={styles.footer}>
          <Text sx={styles.info} isTruncated>
            {version}
          </Text>
          <Badge colorScheme={status}>{R.toLower(status)}</Badge>
        </HStack>
      </Flex>
    </LinkBox>
  );
};
