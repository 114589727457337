import * as Types from './types.generate';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import gql from 'graphql-tag';
export type InterviewFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InterviewFormQuery = (
  { __typename?: 'Query' }
  & { interviewForm?: Types.Maybe<(
    { __typename?: 'InterviewForm' }
    & Pick<Types.InterviewForm, 'id' | 'shopifyStoreId' | 'firstName' | 'lastName' | 'country' | 'countryCode' | 'phoneNumber' | 'email' | 'showTeaser' | 'createdAt' | 'submittedAt'>
  )> }
);

export type ShowGiftTeaserMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ShowGiftTeaserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'showGiftTeaser'>
);

export type SaveInterviewFormMutationVariables = Types.Exact<{
  input: Types.SaveInterviewFormInput;
}>;


export type SaveInterviewFormMutation = (
  { __typename?: 'Mutation' }
  & { saveInterviewForm?: Types.Maybe<(
    { __typename?: 'InterviewForm' }
    & Pick<Types.InterviewForm, 'id'>
  )> }
);

export type GetImagesQueryVariables = Types.Exact<{
  take?: Types.Maybe<Types.Scalars['Int']>;
  cursorId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetImagesQuery = (
  { __typename?: 'Query' }
  & { images: (
    { __typename?: 'ImagesResponse' }
    & Pick<Types.ImagesResponse, 'cursorId'>
    & { nodes: Array<(
      { __typename?: 'Image' }
      & Pick<Types.Image, 'id'>
    )> }
  ) }
);

export type UploadImageMutationVariables = Types.Exact<{
  image: Types.Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'uploadImage'>
);

export type ShopifyLoginMutationVariables = Types.Exact<{
  input: Types.ShopifyLoginInput;
}>;


export type ShopifyLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'shopifyLogin'>
);

export type ShopifyInstallMutationVariables = Types.Exact<{
  input: Types.ShopifyInstallInput;
}>;


export type ShopifyInstallMutation = (
  { __typename?: 'Mutation' }
  & { shopifyInstall: (
    { __typename?: 'ShopifyInstallResponse' }
    & Pick<Types.ShopifyInstallResponse, 'type' | 'query'>
  ) }
);

export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'login'>
);

export type SetPasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
}>;


export type SetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'setPassword'>
);

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'logout'>
);

export type CreateShopifyWebhookMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateShopifyWebhookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'createShopifyWebhook'>
);

export type VerifyEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type VerifyEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'verifyEmail'>
);

export type VerifyResetPasswordTokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type VerifyResetPasswordTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'verifyResetPasswordToken'>
);

export type ForgotPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Types.Exact<{
  input: Types.ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'resetPassword'>
);

export type CreatePageMutationVariables = Types.Exact<{
  input: Types.CreatePageInput;
}>;


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { createPage?: Types.Maybe<(
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'type' | 'attributes' | 'title' | 'status'>
    & { currentVersion?: Types.Maybe<(
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'cover' | 'content'>
    )> }
  )> }
);

export type DeletePageMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeletePageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deletePage'>
);

export type SavePageMutationVariables = Types.Exact<{
  input: Types.SavePageInput;
}>;


export type SavePageMutation = (
  { __typename?: 'Mutation' }
  & { savePage?: Types.Maybe<(
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'type' | 'attributes' | 'title' | 'status'>
    & { currentVersion?: Types.Maybe<(
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'cover' | 'content' | 'note' | 'updatedAt'>
    )> }
  )> }
);

export type RevertPageMutationVariables = Types.Exact<{
  input: Types.RevertPageInput;
}>;


export type RevertPageMutation = (
  { __typename?: 'Mutation' }
  & { revertPage?: Types.Maybe<(
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'type' | 'attributes' | 'title' | 'status'>
    & { currentVersion?: Types.Maybe<(
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'cover' | 'content' | 'note' | 'updatedAt'>
    )> }
  )> }
);

export type VerifyHandleQueryVariables = Types.Exact<{
  pageType: Types.PageType;
  handle: Types.Scalars['String'];
}>;


export type VerifyHandleQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'verifyHandle'>
);

export type UpdateHomepageMutationVariables = Types.Exact<{
  input: Types.UpdateHomepageInput;
}>;


export type UpdateHomepageMutation = (
  { __typename?: 'Mutation' }
  & { updateHomepage?: Types.Maybe<(
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'isHomepage'>
  )> }
);

export type UpdatePageSettingsMutationVariables = Types.Exact<{
  input: Types.UpdatePageSettingsInput;
}>;


export type UpdatePageSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updatePageSettings?: Types.Maybe<(
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'type' | 'attributes' | 'title' | 'status'>
    & { currentVersion?: Types.Maybe<(
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'cover' | 'content'>
    )> }
  )> }
);

export type PagesQueryVariables = Types.Exact<{
  keyword?: Types.Maybe<Types.Scalars['String']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  pageType?: Types.Maybe<Types.PageType>;
  cursorId?: Types.Maybe<Types.Scalars['String']>;
  disabledFromShopify?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type PagesQuery = (
  { __typename?: 'Query' }
  & { pages: (
    { __typename?: 'PagesResponse' }
    & Pick<Types.PagesResponse, 'cursorId'>
    & { nodes: Array<(
      { __typename?: 'Page' }
      & Pick<Types.Page, 'id' | 'type' | 'attributes' | 'title' | 'handle' | 'status' | 'isHomepage'>
      & { currentVersion?: Types.Maybe<(
        { __typename?: 'PageVersion' }
        & Pick<Types.PageVersion, 'id' | 'cover' | 'note' | 'updatedAt' | 'content'>
      )> }
    )> }
  ) }
);

export type PageQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type PageQuery = (
  { __typename?: 'Query' }
  & { page: (
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'type' | 'title' | 'attributes' | 'handle' | 'status' | 'currentVersionId' | 'publishedVersionId' | 'isHomepage'>
    & { currentVersion?: Types.Maybe<(
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'cover' | 'content' | 'updatedAt' | 'note'>
    )> }
  ) }
);

export type PageVersionsQueryVariables = Types.Exact<{
  input: Types.PageVersionsInput;
}>;


export type PageVersionsQuery = (
  { __typename?: 'Query' }
  & { pageVersions: Array<Types.Maybe<(
    { __typename?: 'PageVersion' }
    & Pick<Types.PageVersion, 'id' | 'cover' | 'note' | 'updatedAt'>
  )>> }
);

export type PageVersionQueryVariables = Types.Exact<{
  input: Types.PageVersionInput;
}>;


export type PageVersionQuery = (
  { __typename?: 'Query' }
  & { pageVersion: (
    { __typename?: 'PageVersion' }
    & Pick<Types.PageVersion, 'id' | 'cover' | 'content' | 'note' | 'updatedAt'>
  ) }
);

export type DamQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DamQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'dammy'>
);

export type UserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<Types.User, 'email' | 'name' | 'firstName' | 'lastName' | 'currentStore' | 'hasPassword'>
  ) }
);

export type ShopifyProductsQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.ShopifyProductsInput>;
}>;


export type ShopifyProductsQuery = (
  { __typename?: 'Query' }
  & { shopifyProducts: Array<Types.Maybe<(
    { __typename?: 'Product' }
    & Pick<Types.Product, 'id' | 'imageSrc' | 'title' | 'handle' | 'pageId' | 'status' | 'publishedAt'>
  )>> }
);

export type ShopifyProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ShopifyProductQuery = (
  { __typename?: 'Query' }
  & { shopifyProduct?: Types.Maybe<(
    { __typename?: 'FlatShopifyProduct' }
    & Pick<Types.FlatShopifyProduct, 'id' | 'title' | 'handle' | 'vendor' | 'descriptionHtml' | 'price' | 'compareAtPrice' | 'featuredImage' | 'status' | 'publishedAt'>
    & { variants: Array<(
      { __typename?: 'ShopifyProductVariant' }
      & Pick<Types.ShopifyProductVariant, 'id' | 'title' | 'price' | 'compareAtPrice' | 'available'>
      & { image?: Types.Maybe<(
        { __typename?: 'ShopifyImage' }
        & Pick<Types.ShopifyImage, 'altText' | 'transformedSrc'>
      )> }
    )> }
  )> }
);

export type LatestShopifyProductQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LatestShopifyProductQuery = (
  { __typename?: 'Query' }
  & { latestShopifyProduct?: Types.Maybe<(
    { __typename?: 'FlatShopifyProduct' }
    & Pick<Types.FlatShopifyProduct, 'id' | 'title' | 'handle' | 'vendor' | 'descriptionHtml' | 'price' | 'compareAtPrice' | 'featuredImage' | 'status' | 'publishedAt'>
    & { variants: Array<(
      { __typename?: 'ShopifyProductVariant' }
      & Pick<Types.ShopifyProductVariant, 'id' | 'title' | 'price' | 'compareAtPrice' | 'available'>
      & { image?: Types.Maybe<(
        { __typename?: 'ShopifyImage' }
        & Pick<Types.ShopifyImage, 'altText' | 'transformedSrc'>
      )> }
    )> }
  )> }
);

export type ShopifyCollectionsQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.ShopifyCollectionsInput>;
}>;


export type ShopifyCollectionsQuery = (
  { __typename?: 'Query' }
  & { shopifyCollections: Array<Types.Maybe<(
    { __typename?: 'Collection' }
    & Pick<Types.Collection, 'id' | 'imageSrc' | 'title' | 'handle' | 'pageId'>
  )>> }
);

export type ShopifyCollectionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ShopifyCollectionQuery = (
  { __typename?: 'Query' }
  & { shopifyCollection?: Types.Maybe<(
    { __typename?: 'FlatShopifyCollection' }
    & Pick<Types.FlatShopifyCollection, 'id' | 'title' | 'handle' | 'descriptionHtml'>
    & { image?: Types.Maybe<(
      { __typename?: 'ShopifyImage' }
      & Pick<Types.ShopifyImage, 'altText' | 'transformedSrc'>
    )>, products?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Product' }
      & Pick<Types.Product, 'id' | 'title' | 'handle'>
    )>>> }
  )> }
);

export type LatestShopifyCollectionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LatestShopifyCollectionQuery = (
  { __typename?: 'Query' }
  & { latestShopifyCollection?: Types.Maybe<(
    { __typename?: 'FlatShopifyCollection' }
    & Pick<Types.FlatShopifyCollection, 'id' | 'title' | 'handle' | 'descriptionHtml'>
    & { image?: Types.Maybe<(
      { __typename?: 'ShopifyImage' }
      & Pick<Types.ShopifyImage, 'altText' | 'transformedSrc'>
    )>, products?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'Product' }
      & Pick<Types.Product, 'id' | 'title' | 'handle'>
    )>>> }
  )> }
);

export type ShopifyBlogsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyBlogsQuery = (
  { __typename?: 'Query' }
  & { shopifyBlogs: Array<Types.Maybe<(
    { __typename?: 'Blog' }
    & Pick<Types.Blog, 'id' | 'title' | 'handle'>
  )>> }
);

export type ShopifyBlogQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ShopifyBlogQuery = (
  { __typename?: 'Query' }
  & { shopifyBlog: (
    { __typename?: 'Blog' }
    & Pick<Types.Blog, 'id' | 'title' | 'handle'>
  ) }
);

export type ShopifyProductsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyProductsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'shopifyProductsCount'>
);

export type ShopifyCollectionsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyCollectionsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'shopifyCollectionsCount'>
);

export type ShopifyShopQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyShopQuery = (
  { __typename?: 'Query' }
  & { shopifyShop: (
    { __typename?: 'Shop' }
    & Pick<Types.Shop, 'id' | 'name' | 'domain' | 'moneyFormat' | 'currency'>
  ) }
);

export type SchedulesQueryVariables = Types.Exact<{
  pageId: Types.Scalars['String'];
}>;


export type SchedulesQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Types.Schedule, 'id' | 'status' | 'publishDate' | 'createdAt'>
    & { pageVersion: (
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'note' | 'updatedAt'>
    ) }
  )> }
);

export type CreateScheduleMutationVariables = Types.Exact<{
  input: Types.CreateScheduleInput;
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createSchedule: (
    { __typename?: 'Schedule' }
    & Pick<Types.Schedule, 'id' | 'status' | 'publishDate' | 'createdAt'>
    & { pageVersion: (
      { __typename?: 'PageVersion' }
      & Pick<Types.PageVersion, 'id' | 'note' | 'updatedAt'>
    ) }
  ) }
);

export type DeleteScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteSchedule'>
);

export type ShopifyPublishPageMutationVariables = Types.Exact<{
  pageId: Types.Scalars['ID'];
}>;


export type ShopifyPublishPageMutation = (
  { __typename?: 'Mutation' }
  & { shopifyPublishPage: (
    { __typename?: 'Page' }
    & Pick<Types.Page, 'id' | 'status'>
  ) }
);

export type VerifyCategoryQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type VerifyCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'verifyCategory'>
);

export type SnippetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SnippetsQuery = (
  { __typename?: 'Query' }
  & { snippets: Array<(
    { __typename?: 'Snippet' }
    & Pick<Types.Snippet, 'id' | 'name' | 'componentData'>
    & { category: (
      { __typename?: 'SnippetCategory' }
      & Pick<Types.SnippetCategory, 'id' | 'name'>
    ) }
  )> }
);

export type LibrarySnippetsQueryVariables = Types.Exact<{
  input: Types.LibrarySnippetsInput;
}>;


export type LibrarySnippetsQuery = (
  { __typename?: 'Query' }
  & { librarySnippets: Array<(
    { __typename?: 'LibrarySnippet' }
    & Pick<Types.LibrarySnippet, 'id' | 'name' | 'componentData' | 'thumbnail'>
    & { category: (
      { __typename?: 'LibrarySnippetCategory' }
      & Pick<Types.LibrarySnippetCategory, 'id' | 'name'>
    ) }
  )> }
);

export type LibrarySnippetCategoriesQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.LibrarySnippetCategoriesInput>;
}>;


export type LibrarySnippetCategoriesQuery = (
  { __typename?: 'Query' }
  & { librarySnippetCategories: Array<(
    { __typename?: 'LibrarySnippetCategory' }
    & Pick<Types.LibrarySnippetCategory, 'id' | 'name' | 'order' | 'type'>
    & { children?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'LibrarySnippetCategory' }
      & Pick<Types.LibrarySnippetCategory, 'id' | 'name' | 'order' | 'type'>
      & { children?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'LibrarySnippetCategory' }
        & Pick<Types.LibrarySnippetCategory, 'id' | 'name' | 'order' | 'type'>
      )>>> }
    )>>> }
  )> }
);

export type SnippetCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SnippetCategoriesQuery = (
  { __typename?: 'Query' }
  & { snippetCategories: Array<(
    { __typename?: 'SnippetCategory' }
    & Pick<Types.SnippetCategory, 'id' | 'name'>
  )> }
);

export type CreateSnippetMutationVariables = Types.Exact<{
  input: Types.CreateSnippetInput;
}>;


export type CreateSnippetMutation = (
  { __typename?: 'Mutation' }
  & { createSnippet: (
    { __typename?: 'Snippet' }
    & Pick<Types.Snippet, 'id' | 'name' | 'componentData'>
    & { category: (
      { __typename?: 'SnippetCategory' }
      & Pick<Types.SnippetCategory, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteSnippetMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSnippetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteSnippet'>
);

export type DeleteSnippetCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSnippetCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteSnippetCategory'>
);

export type CreateLibrarySnippetMutationVariables = Types.Exact<{
  input: Types.CreateLibrarySnippetInput;
}>;


export type CreateLibrarySnippetMutation = (
  { __typename?: 'Mutation' }
  & { createLibrarySnippet: (
    { __typename?: 'LibrarySnippet' }
    & Pick<Types.LibrarySnippet, 'id' | 'name' | 'componentData' | 'thumbnail'>
    & { category: (
      { __typename?: 'LibrarySnippetCategory' }
      & Pick<Types.LibrarySnippetCategory, 'id' | 'name'>
    ) }
  ) }
);

export type DeleteLibrarySnippetMutationVariables = Types.Exact<{
  input: Types.DeleteLibrarySnippetInput;
}>;


export type DeleteLibrarySnippetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteLibrarySnippet'>
);

export type GetStoresQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStoresQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<Types.User, 'currentStore'>
  ), stores: Array<Types.Maybe<(
    { __typename?: 'Store' }
    & Pick<Types.Store, 'name'>
  )>> }
);

export type SwitchStoreMutationVariables = Types.Exact<{
  storeName: Types.Scalars['String'];
}>;


export type SwitchStoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'switchStore'>
);

export type ChargeMutationVariables = Types.Exact<{
  plan: Types.Plan;
}>;


export type ChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'shopifyRecurringCharge'>
);

export type CheckChargeMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckChargeMutation = (
  { __typename?: 'Mutation' }
  & { checkShopifyRecurringCharge: (
    { __typename?: 'RecurringStatus' }
    & Pick<Types.RecurringStatus, 'isActive' | 'restTrialDays'>
  ) }
);

export type UpdateUserMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserResponse' }
    & Pick<Types.UpdateUserResponse, 'firstName' | 'lastName'>
  ) }
);

export type PermissionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PermissionQuery = (
  { __typename?: 'Query' }
  & { permission: (
    { __typename?: 'Permission' }
    & Pick<Types.Permission, 'development' | 'admin'>
  ) }
);


export const InterviewFormDocument = gql`
    query interviewForm {
  interviewForm {
    id
    shopifyStoreId
    firstName
    lastName
    country
    countryCode
    phoneNumber
    email
    showTeaser
    createdAt
    submittedAt
  }
}
    ` as unknown as DocumentNode<InterviewFormQuery, InterviewFormQueryVariables>;
export const ShowGiftTeaserDocument = gql`
    mutation showGiftTeaser {
  showGiftTeaser
}
    ` as unknown as DocumentNode<ShowGiftTeaserMutation, ShowGiftTeaserMutationVariables>;
export const SaveInterviewFormDocument = gql`
    mutation saveInterviewForm($input: saveInterviewFormInput!) {
  saveInterviewForm(input: $input) {
    id
  }
}
    ` as unknown as DocumentNode<SaveInterviewFormMutation, SaveInterviewFormMutationVariables>;
export const GetImagesDocument = gql`
    query getImages($take: Int, $cursorId: String) {
  images(take: $take, cursorId: $cursorId) {
    cursorId
    nodes {
      id
    }
  }
}
    ` as unknown as DocumentNode<GetImagesQuery, GetImagesQueryVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($image: Upload!) {
  uploadImage(image: $image)
}
    ` as unknown as DocumentNode<UploadImageMutation, UploadImageMutationVariables>;
export const ShopifyLoginDocument = gql`
    mutation ShopifyLogin($input: ShopifyLoginInput!) {
  shopifyLogin(input: $input)
}
    ` as unknown as DocumentNode<ShopifyLoginMutation, ShopifyLoginMutationVariables>;
export const ShopifyInstallDocument = gql`
    mutation ShopifyInstall($input: ShopifyInstallInput!) {
  shopifyInstall(input: $input) {
    type
    query
  }
}
    ` as unknown as DocumentNode<ShopifyInstallMutation, ShopifyInstallMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    ` as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($password: String!) {
  setPassword(password: $password)
}
    ` as unknown as DocumentNode<SetPasswordMutation, SetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    ` as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const CreateShopifyWebhookDocument = gql`
    mutation createShopifyWebhook {
  createShopifyWebhook
}
    ` as unknown as DocumentNode<CreateShopifyWebhookMutation, CreateShopifyWebhookMutationVariables>;
export const VerifyEmailDocument = gql`
    query verifyEmail($email: String!) {
  verifyEmail(email: $email)
}
    ` as unknown as DocumentNode<VerifyEmailQuery, VerifyEmailQueryVariables>;
export const VerifyResetPasswordTokenDocument = gql`
    query verifyResetPasswordToken($token: String!) {
  verifyResetPasswordToken(token: $token)
}
    ` as unknown as DocumentNode<VerifyResetPasswordTokenQuery, VerifyResetPasswordTokenQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    ` as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    ` as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreatePageDocument = gql`
    mutation createPage($input: createPageInput!) {
  createPage(input: $input) {
    id
    type
    attributes
    title
    status
    currentVersion {
      id
      cover
      content
    }
  }
}
    ` as unknown as DocumentNode<CreatePageMutation, CreatePageMutationVariables>;
export const DeletePageDocument = gql`
    mutation deletePage($id: String!) {
  deletePage(id: $id)
}
    ` as unknown as DocumentNode<DeletePageMutation, DeletePageMutationVariables>;
export const SavePageDocument = gql`
    mutation savePage($input: savePageInput!) {
  savePage(input: $input) {
    id
    type
    attributes
    title
    status
    currentVersion {
      id
      cover
      content
      note
      updatedAt
    }
  }
}
    ` as unknown as DocumentNode<SavePageMutation, SavePageMutationVariables>;
export const RevertPageDocument = gql`
    mutation revertPage($input: revertPageInput!) {
  revertPage(input: $input) {
    id
    type
    attributes
    title
    status
    currentVersion {
      id
      cover
      content
      note
      updatedAt
    }
  }
}
    ` as unknown as DocumentNode<RevertPageMutation, RevertPageMutationVariables>;
export const VerifyHandleDocument = gql`
    query verifyHandle($pageType: PageType!, $handle: String!) {
  verifyHandle(pageType: $pageType, handle: $handle)
}
    ` as unknown as DocumentNode<VerifyHandleQuery, VerifyHandleQueryVariables>;
export const UpdateHomepageDocument = gql`
    mutation updateHomepage($input: updateHomepageInput!) {
  updateHomepage(input: $input) {
    id
    isHomepage
  }
}
    ` as unknown as DocumentNode<UpdateHomepageMutation, UpdateHomepageMutationVariables>;
export const UpdatePageSettingsDocument = gql`
    mutation updatePageSettings($input: updatePageSettingsInput!) {
  updatePageSettings(input: $input) {
    id
    type
    attributes
    title
    status
    currentVersion {
      id
      cover
      content
    }
  }
}
    ` as unknown as DocumentNode<UpdatePageSettingsMutation, UpdatePageSettingsMutationVariables>;
export const PagesDocument = gql`
    query pages($keyword: String, $take: Int, $pageType: PageType, $cursorId: String, $disabledFromShopify: Boolean) {
  pages(keyword: $keyword, take: $take, pageType: $pageType, cursorId: $cursorId, disabledFromShopify: $disabledFromShopify) {
    cursorId
    nodes {
      id
      type
      attributes
      title
      handle
      status
      currentVersion {
        id
        cover
        note
        updatedAt
        content
      }
      isHomepage
    }
  }
}
    ` as unknown as DocumentNode<PagesQuery, PagesQueryVariables>;
export const PageDocument = gql`
    query page($id: String!) {
  page(id: $id) {
    id
    type
    title
    attributes
    handle
    status
    currentVersion {
      id
      cover
      content
      updatedAt
      note
    }
    currentVersionId
    publishedVersionId
    isHomepage
  }
}
    ` as unknown as DocumentNode<PageQuery, PageQueryVariables>;
export const PageVersionsDocument = gql`
    query pageVersions($input: PageVersionsInput!) {
  pageVersions(input: $input) {
    id
    cover
    note
    updatedAt
  }
}
    ` as unknown as DocumentNode<PageVersionsQuery, PageVersionsQueryVariables>;
export const PageVersionDocument = gql`
    query pageVersion($input: PageVersionInput!) {
  pageVersion(input: $input) {
    id
    cover
    content
    note
    updatedAt
  }
}
    ` as unknown as DocumentNode<PageVersionQuery, PageVersionQueryVariables>;
export const DamDocument = gql`
    query dam {
  dammy
}
    ` as unknown as DocumentNode<DamQuery, DamQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    email
    name
    firstName
    lastName
    currentStore
    hasPassword
  }
}
    ` as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const ShopifyProductsDocument = gql`
    query shopifyProducts($input: ShopifyProductsInput) {
  shopifyProducts(input: $input) {
    id
    imageSrc
    title
    handle
    pageId
    status
    publishedAt
  }
}
    ` as unknown as DocumentNode<ShopifyProductsQuery, ShopifyProductsQueryVariables>;
export const ShopifyProductDocument = gql`
    query shopifyProduct($id: String!) {
  shopifyProduct(id: $id) {
    id
    title
    handle
    vendor
    descriptionHtml
    price
    compareAtPrice
    featuredImage
    status
    publishedAt
    variants {
      id
      title
      price
      compareAtPrice
      available
      image {
        altText
        transformedSrc
      }
    }
  }
}
    ` as unknown as DocumentNode<ShopifyProductQuery, ShopifyProductQueryVariables>;
export const LatestShopifyProductDocument = gql`
    query latestShopifyProduct {
  latestShopifyProduct {
    id
    title
    handle
    vendor
    descriptionHtml
    price
    compareAtPrice
    featuredImage
    status
    publishedAt
    variants {
      id
      title
      price
      compareAtPrice
      available
      image {
        altText
        transformedSrc
      }
    }
  }
}
    ` as unknown as DocumentNode<LatestShopifyProductQuery, LatestShopifyProductQueryVariables>;
export const ShopifyCollectionsDocument = gql`
    query shopifyCollections($input: ShopifyCollectionsInput) {
  shopifyCollections(input: $input) {
    id
    imageSrc
    title
    handle
    pageId
  }
}
    ` as unknown as DocumentNode<ShopifyCollectionsQuery, ShopifyCollectionsQueryVariables>;
export const ShopifyCollectionDocument = gql`
    query shopifyCollection($id: String!) {
  shopifyCollection(id: $id) {
    id
    title
    handle
    descriptionHtml
    image {
      altText
      transformedSrc
    }
    products {
      id
      title
      handle
    }
  }
}
    ` as unknown as DocumentNode<ShopifyCollectionQuery, ShopifyCollectionQueryVariables>;
export const LatestShopifyCollectionDocument = gql`
    query latestShopifyCollection {
  latestShopifyCollection {
    id
    title
    handle
    descriptionHtml
    image {
      altText
      transformedSrc
    }
    products {
      id
      title
      handle
    }
  }
}
    ` as unknown as DocumentNode<LatestShopifyCollectionQuery, LatestShopifyCollectionQueryVariables>;
export const ShopifyBlogsDocument = gql`
    query shopifyBlogs {
  shopifyBlogs {
    id
    title
    handle
  }
}
    ` as unknown as DocumentNode<ShopifyBlogsQuery, ShopifyBlogsQueryVariables>;
export const ShopifyBlogDocument = gql`
    query shopifyBlog($id: String!) {
  shopifyBlog(id: $id) {
    id
    title
    handle
  }
}
    ` as unknown as DocumentNode<ShopifyBlogQuery, ShopifyBlogQueryVariables>;
export const ShopifyProductsCountDocument = gql`
    query shopifyProductsCount {
  shopifyProductsCount
}
    ` as unknown as DocumentNode<ShopifyProductsCountQuery, ShopifyProductsCountQueryVariables>;
export const ShopifyCollectionsCountDocument = gql`
    query shopifyCollectionsCount {
  shopifyCollectionsCount
}
    ` as unknown as DocumentNode<ShopifyCollectionsCountQuery, ShopifyCollectionsCountQueryVariables>;
export const ShopifyShopDocument = gql`
    query shopifyShop {
  shopifyShop {
    id
    name
    domain
    moneyFormat
    currency
  }
}
    ` as unknown as DocumentNode<ShopifyShopQuery, ShopifyShopQueryVariables>;
export const SchedulesDocument = gql`
    query schedules($pageId: String!) {
  schedules(pageId: $pageId) {
    id
    status
    publishDate
    pageVersion {
      id
      note
      updatedAt
    }
    createdAt
  }
}
    ` as unknown as DocumentNode<SchedulesQuery, SchedulesQueryVariables>;
export const CreateScheduleDocument = gql`
    mutation createSchedule($input: CreateScheduleInput!) {
  createSchedule(input: $input) {
    id
    status
    publishDate
    pageVersion {
      id
      note
      updatedAt
    }
    createdAt
  }
}
    ` as unknown as DocumentNode<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const DeleteScheduleDocument = gql`
    mutation deleteSchedule($id: ID!) {
  deleteSchedule(id: $id)
}
    ` as unknown as DocumentNode<DeleteScheduleMutation, DeleteScheduleMutationVariables>;
export const ShopifyPublishPageDocument = gql`
    mutation shopifyPublishPage($pageId: ID!) {
  shopifyPublishPage(pageId: $pageId) {
    id
    status
  }
}
    ` as unknown as DocumentNode<ShopifyPublishPageMutation, ShopifyPublishPageMutationVariables>;
export const VerifyCategoryDocument = gql`
    query verifyCategory($name: String!) {
  verifyCategory(name: $name)
}
    ` as unknown as DocumentNode<VerifyCategoryQuery, VerifyCategoryQueryVariables>;
export const SnippetsDocument = gql`
    query snippets {
  snippets {
    id
    name
    componentData
    category {
      id
      name
    }
  }
}
    ` as unknown as DocumentNode<SnippetsQuery, SnippetsQueryVariables>;
export const LibrarySnippetsDocument = gql`
    query librarySnippets($input: LibrarySnippetsInput!) {
  librarySnippets(input: $input) {
    id
    name
    componentData
    thumbnail
    category {
      id
      name
    }
  }
}
    ` as unknown as DocumentNode<LibrarySnippetsQuery, LibrarySnippetsQueryVariables>;
export const LibrarySnippetCategoriesDocument = gql`
    query librarySnippetCategories($input: LibrarySnippetCategoriesInput) {
  librarySnippetCategories(input: $input) {
    id
    name
    order
    type
    children {
      id
      name
      order
      type
      children {
        id
        name
        order
        type
      }
    }
  }
}
    ` as unknown as DocumentNode<LibrarySnippetCategoriesQuery, LibrarySnippetCategoriesQueryVariables>;
export const SnippetCategoriesDocument = gql`
    query snippetCategories {
  snippetCategories {
    id
    name
  }
}
    ` as unknown as DocumentNode<SnippetCategoriesQuery, SnippetCategoriesQueryVariables>;
export const CreateSnippetDocument = gql`
    mutation createSnippet($input: CreateSnippetInput!) {
  createSnippet(input: $input) {
    id
    name
    componentData
    category {
      id
      name
    }
  }
}
    ` as unknown as DocumentNode<CreateSnippetMutation, CreateSnippetMutationVariables>;
export const DeleteSnippetDocument = gql`
    mutation deleteSnippet($id: ID!) {
  deleteSnippet(id: $id)
}
    ` as unknown as DocumentNode<DeleteSnippetMutation, DeleteSnippetMutationVariables>;
export const DeleteSnippetCategoryDocument = gql`
    mutation deleteSnippetCategory($id: ID!) {
  deleteSnippetCategory(id: $id)
}
    ` as unknown as DocumentNode<DeleteSnippetCategoryMutation, DeleteSnippetCategoryMutationVariables>;
export const CreateLibrarySnippetDocument = gql`
    mutation createLibrarySnippet($input: CreateLibrarySnippetInput!) {
  createLibrarySnippet(input: $input) {
    id
    name
    componentData
    thumbnail
    category {
      id
      name
    }
  }
}
    ` as unknown as DocumentNode<CreateLibrarySnippetMutation, CreateLibrarySnippetMutationVariables>;
export const DeleteLibrarySnippetDocument = gql`
    mutation deleteLibrarySnippet($input: DeleteLibrarySnippetInput!) {
  deleteLibrarySnippet(input: $input)
}
    ` as unknown as DocumentNode<DeleteLibrarySnippetMutation, DeleteLibrarySnippetMutationVariables>;
export const GetStoresDocument = gql`
    query getStores {
  user {
    currentStore
  }
  stores: userStores {
    name
  }
}
    ` as unknown as DocumentNode<GetStoresQuery, GetStoresQueryVariables>;
export const SwitchStoreDocument = gql`
    mutation switchStore($storeName: String!) {
  switchStore(storeName: $storeName)
}
    ` as unknown as DocumentNode<SwitchStoreMutation, SwitchStoreMutationVariables>;
export const ChargeDocument = gql`
    mutation charge($plan: Plan!) {
  shopifyRecurringCharge(plan: $plan)
}
    ` as unknown as DocumentNode<ChargeMutation, ChargeMutationVariables>;
export const CheckChargeDocument = gql`
    mutation checkCharge {
  checkShopifyRecurringCharge {
    isActive
    restTrialDays
  }
}
    ` as unknown as DocumentNode<CheckChargeMutation, CheckChargeMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($firstName: String!, $lastName: String!) {
  updateUser(firstName: $firstName, lastName: $lastName) {
    firstName
    lastName
  }
}
    ` as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const PermissionDocument = gql`
    query permission {
  permission {
    development
    admin
  }
}
    ` as unknown as DocumentNode<PermissionQuery, PermissionQueryVariables>;