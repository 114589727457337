import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/popover';
import { Box, Flex, HStack, Text } from '@chakra-ui/layout';
import { RgbaColorPicker } from 'react-colorful';
import {
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { rgbToColorString } from 'polished';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Portal,
} from '@chakra-ui/react';
import { DisplayColorSchema, ColorSchema, HexColorSchema } from './schema';
import styled from '@emotion/styled';
import * as R from 'ramda';
import {
  RgbaColor,
  SimpleRGBA,
  ColorPickerProps,
  ColorPickerWithInputProps,
} from './types';
import { WHITE_COLOR } from './constants';
import { getFallbackColor, toRGBAColor, toSimpleRGBA } from './utils';
import { useMachine } from '@xstate/react';
import { colorMachine, COLOR_EVENT, COLOR_STATE } from './colorMachine';

const ColorPickerContainer = styled(Box)`
  .react-colorful {
    width: 250px;
    height: auto;
  }
  .react-colorful__saturation {
    border-bottom: 6px solid #000;
    border-radius: 4px;
    height: 150px;
  }
  .react-colorful__hue,
  .react-colorful__alpha {
    border-radius: 6px;
    height: 12px;
    &::before {
      position: absolute;
      top: -28px;
      font-size: 12px;
      font-weight: 600;
      color: #585858;
    }
  }
  .react-colorful__hue {
    margin-top: 38px;
    &::before {
      content: 'Hue';
    }
  }
  .react-colorful__alpha {
    margin-top: 40px;
    border-color: #e5e5e5;
    background-size: contain;
    &::before {
      content: 'Opacity';
    }
  }
  .react-colorful__pointer {
    cursor: pointer;
  }
  .react-colorful__saturation-pointer,
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`;

type RgbaColorFieldProp = InputProps & {
  type: keyof SimpleRGBA;
  isSingleColor: boolean;
};
const RgbaColorField = ({
  type,
  value,
  min = 0,
  max = 255,
  isSingleColor,
  ...props
}: RgbaColorFieldProp) => {
  return (
    <Flex direction="column" alignItems="center">
      <Input
        size="sm"
        textAlign="center"
        type="number"
        placeholder="0"
        isInvalid={
          isSingleColor && (R.isNil(value) || value < min || value > max)
        }
        value={value || ''}
        {...props}
      />
      <Text mt="4px" fontSize="12px" fontWeight="semibold">
        {type.toUpperCase()}
      </Text>
    </Flex>
  );
};

export const ColorPicker = ({
  color,
  fallbackColor,
  renderTrigger,
  onClose,
  onChange,
  popoverOffset,
  ...props
}: ColorPickerProps) => {
  const [state, send] = useMachine(colorMachine, {
    context: {
      rgbaFallbackColor: getFallbackColor(fallbackColor),
    },
    actions: {
      triggerChangeCallback: ({ rgbaDisplayColor }) => {
        if (typeof onChange === 'function') {
          const result = DisplayColorSchema.safeParse(
            rgbToColorString(rgbaDisplayColor)
          );
          if (result.success) {
            onChange(result.data);
          }
        }
      },
      triggerCloseCallback: ({ rgbaDisplayColor }) => {
        if (typeof onClose === 'function') {
          const result = DisplayColorSchema.safeParse(
            rgbToColorString(rgbaDisplayColor)
          );
          if (result.success) {
            onClose(result.data, state.matches(COLOR_STATE.SINGLE_COLOR));
          }
        }
      },
    },
  });
  const {
    rgbaDisplayColor,
    hexInput,
    redInput,
    greenInput,
    blueInput,
    alphaInput,
    history,
  } = state.context;
  const isSingleColor = state.matches(COLOR_STATE.SINGLE_COLOR);

  const hexColorInputResult = HexColorSchema.safeParse(`#${hexInput}`);

  const handleColorChange = useCallback(
    (rgbaColor: RgbaColor) => send({ type: COLOR_EVENT.PICK_COLOR, rgbaColor }),
    [send]
  );

  const handleHexInputChange = useCallback(
    (e) => send({ type: COLOR_EVENT.CHANGE_HEX_INPUT, value: e.target.value }),
    [send]
  );
  const handleHexInputBlur = useCallback(
    (e) => {
      if (isSingleColor || e.target.value) {
        send({ type: COLOR_EVENT.UPDATE_COLOR_BY_HEX });
      }
    },
    [isSingleColor, send]
  );
  const handleHexInputKeyUp = useCallback(
    (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleHexInputBlur(e);
      }
    },
    [handleHexInputBlur]
  );

  const handleRgbaInputChange = useCallback(
    (colorKey: keyof RgbaColor) => (e) =>
      send({
        type: COLOR_EVENT.CHANGE_RGBA_INPUT,
        colorKey,
        value: e.target.value,
      }),
    [send]
  );
  const handleRgbaInputBlur = useCallback(
    (e) => {
      if (isSingleColor || e.target.value) {
        send({ type: COLOR_EVENT.UPDATE_COLOR_BY_RGBA });
      }
    },
    [isSingleColor, send]
  );
  const handleRgbaInputKeyUp = useCallback(
    (e) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleRgbaInputBlur(e);
      }
    },
    [handleRgbaInputBlur]
  );

  const handleClose = useCallback(
    () => send({ type: COLOR_EVENT.CLOSE }),
    [send]
  );

  useEffect(() => {
    send({ type: COLOR_EVENT.DATA_CHANGED, color, fallbackColor });
  }, [color, fallbackColor, send]);

  return (
    <Popover
      onClose={handleClose}
      offset={popoverOffset}
      strategy="fixed"
      placement="bottom-start"
      isLazy
    >
      <PopoverTrigger>{renderTrigger()}</PopoverTrigger>
      <Portal>
        <PopoverContent
          border="1px"
          borderColor="border"
          w="auto"
          _focus={{
            boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.2)',
          }}
          boxShadow="0 2px 16px 0 rgba(0, 0, 0, 0.2)"
        >
          <PopoverBody p="16px" w="282px">
            <ColorPickerContainer>
              <RgbaColorPicker
                color={toSimpleRGBA(rgbaDisplayColor)}
                onChange={(c) => handleColorChange(toRGBAColor(c))}
                {...props}
              />
            </ColorPickerContainer>
            <Flex gap="4px" mt="18px" maxW="full">
              <Flex minW="90px" direction="column" alignItems="center">
                <InputGroup size="sm">
                  <InputLeftElement
                    lineHeight="inherit"
                    children={
                      <Text color="placeholder" lineHeight="32px">
                        #
                      </Text>
                    }
                  />
                  <Input
                    pl="25px"
                    value={hexInput}
                    maxLength={6}
                    isInvalid={isSingleColor && !hexColorInputResult.success}
                    onChange={handleHexInputChange}
                    onBlur={handleHexInputBlur}
                    onKeyUp={handleHexInputKeyUp}
                  />
                </InputGroup>
                <Text mt="4px" fontSize="12px" fontWeight="semibold">
                  Hex
                </Text>
              </Flex>
              <RgbaColorField
                type="r"
                value={redInput}
                isSingleColor={isSingleColor}
                onChange={handleRgbaInputChange('red')}
                onBlur={handleRgbaInputBlur}
                onKeyUp={handleRgbaInputKeyUp}
              />
              <RgbaColorField
                type="g"
                value={greenInput}
                isSingleColor={isSingleColor}
                onChange={handleRgbaInputChange('green')}
                onBlur={handleRgbaInputBlur}
                onKeyUp={handleRgbaInputKeyUp}
              />
              <RgbaColorField
                type="b"
                value={blueInput}
                isSingleColor={isSingleColor}
                onChange={handleRgbaInputChange('blue')}
                onBlur={handleRgbaInputBlur}
                onKeyUp={handleRgbaInputKeyUp}
              />
              <RgbaColorField
                type="a"
                value={alphaInput}
                max={100}
                isSingleColor={isSingleColor}
                onChange={handleRgbaInputChange('alpha')}
                onBlur={handleRgbaInputBlur}
                onKeyUp={handleRgbaInputKeyUp}
              />
            </Flex>
          </PopoverBody>

          {history.length > 0 && (
            <PopoverFooter p="12px">
              <HStack align="center" h="24px" spacing="2px">
                {history.map((c, index) => {
                  const isSelected = R.equals(c, rgbaDisplayColor);
                  const isWhite = R.equals(c, WHITE_COLOR);
                  const isTransparent = c.alpha === 0;
                  return (
                    <Box
                      key={index}
                      border={isSelected ? '1px' : '0'}
                      borderColor="icon"
                      borderRadius="8px"
                      p={isSelected ? '3px' : '4px'}
                      boxSize="24px"
                      cursor="pointer"
                      onClick={() => handleColorChange(c)}
                      _first={{ ml: 0 }}
                    >
                      <Box
                        border={isWhite || isTransparent ? '1px' : '0'}
                        borderColor="icon"
                        borderRadius="md"
                        boxSize="full"
                        bg={rgbToColorString(c)}
                      ></Box>
                    </Box>
                  );
                })}
              </HStack>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
export default memo(ColorPicker);

export const ColorPickerWithInput = memo(
  ({
    fallbackColor,
    color,
    onChange,
    onInputChange,
    onClose,
  }: ColorPickerWithInputProps) => {
    const rgbaFallBackColor = getFallbackColor(fallbackColor);

    const colorResult = ColorSchema.safeParse(color);
    const colorData = colorResult.success
      ? colorResult.data
      : rgbaFallBackColor;
    const rgbaColor: RgbaColor =
      colorData === undefined || colorData === 'unset'
        ? rgbaFallBackColor
        : colorData;

    const [colorInput, setColorInput] = useState(color);
    const colorInputResult = ColorSchema.safeParse(colorInput);

    const isInvisibleColor =
      !color ||
      color === 'unset' ||
      R.equals(colorData, WHITE_COLOR) ||
      rgbaColor.alpha === 0 ||
      !colorResult.success;

    const handleChange = useCallback(
      (value: string) => {
        if (typeof onChange === 'function') {
          onChange(value);
        }
        setColorInput(value);
      },
      [onChange]
    );

    useEffect(() => {
      if (color !== colorInput) {
        setColorInput(rgbToColorString(rgbaColor));
      }
    }, [color, colorInput, rgbaColor]);

    const handleOnBlur = useCallback(() => {
      if (!colorInputResult.success) {
        handleChange(rgbToColorString(rgbaFallBackColor));
      }
    }, [colorInputResult.success, handleChange, rgbaFallBackColor]);

    const handleOnKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          handleOnBlur();
        }
      },
      [handleOnBlur]
    );

    return (
      <InputGroup>
        <InputLeftElement
          children={
            <ColorPicker
              color={color}
              fallbackColor={fallbackColor}
              onChange={handleChange}
              onClose={onClose}
              renderTrigger={() => (
                <Box
                  borderWidth={isInvisibleColor ? '1px' : '0'}
                  borderColor="black"
                  borderRadius="xs"
                  boxSize="26px"
                  color="gray.400"
                  bg={rgbToColorString(rgbaColor)}
                  cursor="pointer"
                />
              )}
              popoverOffset={[-7, 7]}
            />
          }
        />
        <Input
          paddingLeft="42px"
          value={colorInput || ''}
          placeholder="rgba(255, 255, 255, 1)"
          isInvalid={!colorInputResult.success}
          onChange={(e) => {
            setColorInput(e.target.value);
            onInputChange(
              e.target.value,
              ColorSchema.safeParse(e.target.value).success
            );
          }}
          onKeyUp={handleOnKeyUp}
          onBlur={handleOnBlur}
        />
      </InputGroup>
    );
  }
);
