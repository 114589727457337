import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Header from './Header';
import Footer from './Footer';
import { Home } from '../pages/home/Home';
import { PrivateRoute } from '../components/PrivateRoute';
import { history } from '../history';
import Loading from '../components/Loading';
import { Center } from '@chakra-ui/react';
import SunsetMessageModal from '../components/SunsetMessageModal';

const ShopifyLogin = React.lazy(() =>
  import('../pages/ShopifyLogin').then((module) => ({
    default: module.ShopifyLogin,
  }))
);
const ShopifyRedirect = React.lazy(() =>
  import('../pages/ShopifyRedirect').then((module) => ({
    default: module.ShopifyRedirect,
  }))
);
const Login = React.lazy(() => import('../pages/Login'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const Account = React.lazy(() => import('../pages/account/Account'));
const Preview = React.lazy(() => import('../pages/Preview'));
// const Price = React.lazy(() => import('../pages/Price'));
const EditorPage = React.lazy(() => import('../pages/editor/layouts/Layout'));
const Fallback = () => (
  <Center h="100vh" bg="background">
    <Loading />
  </Center>
);

export const Layout = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route exact path="/shopify/login">
            <React.Suspense fallback={<Fallback />}>
              <ShopifyLogin />
            </React.Suspense>
          </Route>
          <Route exact path="/shopify/redirect">
            <React.Suspense fallback={<Fallback />}>
              <ShopifyRedirect />
            </React.Suspense>
          </Route>
          <Route exact path="/login">
            <React.Suspense fallback={<div />}>
              <Login />
            </React.Suspense>
          </Route>
          <Route exact path="/forgot-password">
            <React.Suspense fallback={<div />}>
              <ForgotPassword />
            </React.Suspense>
          </Route>
          <Route exact path="/reset-password">
            <React.Suspense fallback={<div />}>
              <ResetPassword />
            </React.Suspense>
          </Route>
          <Route exact path="/pages/:id">
            <React.Suspense fallback={<Fallback />}>
              <PrivateRoute>
                <EditorPage />
              </PrivateRoute>
            </React.Suspense>
          </Route>
          <Route exact path="/preview/:id">
            <React.Suspense fallback={<div />}>
              <PrivateRoute>
                <Preview />
              </PrivateRoute>
            </React.Suspense>
          </Route>
          <PrivateRoute>
            <Flex direction="column">
              <Header />
              <Flex
                pos="relative"
                justify="center"
                minH="calc(100vh - 124px)"
                bgColor="background"
                overflow="auto"
              >
                <Switch>
                  <Route exact path="/">
                    <React.Suspense fallback={<div />}>
                      <Home />
                    </React.Suspense>
                  </Route>
                  {/* <Route exact path="/shopify-charge">
                    <React.Suspense fallback={<div />}>
                      <Price />
                    </React.Suspense>
                  </Route> */}
                  <Route exact path="/account">
                    <React.Suspense fallback={<div />}>
                      <Account />
                    </React.Suspense>
                  </Route>
                </Switch>
              </Flex>
              <Footer />
            </Flex>
          </PrivateRoute>
        </Switch>
        <SunsetMessageModal />
      </QueryParamProvider>
    </Router>
  );
};
