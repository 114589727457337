import {
  Flex,
  Box,
  Heading,
  VStack,
  HStack,
  Skeleton,
  useMultiStyleConfig,
  Image,
  ButtonGroup,
  Button,
  Link,
} from '@chakra-ui/react';

type ModalPageCardProps =
  | {
      cover?: string | null;
      id: string;
      title: string;
      version: string;
      type: string;
      isLoading?: false;
      isChoosing?: boolean;
      onChoose: (pageId: string) => () => void;
    }
  | {
      isChoosing?: false;
      isLoading: true;
    };

export const ModalPageCard = (props: ModalPageCardProps) => {
  const styles = useMultiStyleConfig('ModalPageCard', {
    active: props?.isChoosing,
  });
  const loadingStyles = useMultiStyleConfig('ModalPageCardLoading', {});

  if (props.isLoading) {
    return (
      <VStack sx={styles.container} spacing="16px">
        <Skeleton sx={loadingStyles.cover} />
        <Skeleton sx={loadingStyles.info} />
        <Skeleton sx={loadingStyles.title} />
      </VStack>
    );
  }

  return (
    <VStack sx={styles.container} spacing="8px">
      <Box sx={styles.coverContainer} role="group">
        <Image
          sx={styles.cover}
          src={props.cover || ''}
          fallbackSrc={`${process.env.PUBLIC_URL}image/img_card_default_cover.png`}
        />
        <Flex sx={styles.layer} active={props.isChoosing?.toString()}>
          <ButtonGroup spacing="8px">
            <Button
              size="sm"
              onClick={props.onChoose(props.id)}
              isLoading={props.isChoosing}
            >
              Choose
            </Button>
            <Link href={`/preview/${props?.id}`} isExternal>
              <Button size="sm">Preview</Button>
            </Link>
          </ButtonGroup>
        </Flex>
      </Box>
      <HStack w="full" justify="space-between">
        <Box sx={styles.version}>{props.version}</Box>
        <Box sx={styles.type}>{props.type.toLowerCase()}</Box>
      </HStack>
      <Heading sx={styles.title} noOfLines={2}>
        {props.title}
      </Heading>
    </VStack>
  );
};
