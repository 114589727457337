import { parseToRgb, rgba, rgbToColorString } from 'polished';
import * as z from 'zod';
import { DEFAULT_FALLBACK_COLOR, UNAVAILABLE_COLOR } from './constants';
import { rgbaToFullHex } from './utils';

export const ColorSchema = z
  .string()
  .or(z.array(z.string()))
  .optional()
  .default('unset')
  .refine((x) => {
    if (x === 'unset') {
      return true;
    }
    if (Array.isArray(x)) {
      return false;
    }

    try {
      parseToRgb(x);
      return true;
    } catch (error) {
      return false;
    }
  })
  .transform((x) => {
    return x === 'unset'
      ? x
      : Array.isArray(x)
      ? UNAVAILABLE_COLOR
      : { alpha: 1, ...parseToRgb(x) };
  });

export const HexColorSchema = z
  .string()
  .optional()
  .default('')
  .refine((x) => {
    if (!x.startsWith('#') || (x.length !== 4 && x.length !== 7)) {
      return false;
    }

    try {
      parseToRgb(x);
      return true;
    } catch (error) {
      return false;
    }
  })
  .transform((x) => ({ alpha: 1, ...parseToRgb(x) }));

export const RgbaSchema = z.object({
  red: z.number(),
  green: z.number(),
  blue: z.number(),
  alpha: z.number(),
});
export const RgbaColorSchema = RgbaSchema.optional()
  .default(DEFAULT_FALLBACK_COLOR)
  .refine((x) => {
    try {
      parseToRgb(rgba(x));
      return true;
    } catch (error) {
      return false;
    }
  });

export const DisplayColorSchema = ColorSchema.transform((x) => {
  if (x === 'unset') {
    return '';
  }
  if (x.alpha === 1) {
    // convert hex color from 3 digits to 6 digits and change to upper case
    return `#${rgbaToFullHex(x)}`;
  }
  return rgbToColorString(x);
});
