import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/modal';
import { useMachine } from '@xstate/react';
import { RiCheckFill, RiWindowLine } from 'react-icons/ri';
import { siteMachine, SITE_EVENT, SITE_STATE } from '../machines/siteMachine';
import { CloseButton } from './CloseButton';

const SwitchSiteDrawer = ({ isOpen, onClose }) => {
  const [state, send] = useMachine(siteMachine);
  const { stores, currentStore } = state.context;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Heading>Switch Site</Heading>
          <CloseButton pos="initial" onClick={onClose} />
        </DrawerHeader>
        <DrawerBody>
          {stores.map((store: { name: string }) => {
            const isActiveStore = store.name === currentStore;
            return (
              <Flex
                key={store.name}
                justify="space-between"
                borderBottomWidth="1px"
                p="16px 32px"
                minH="64px"
                _first={{
                  borderTopWidth: '1px',
                }}
              >
                <Flex align="center">
                  <Box color={isActiveStore ? 'primary' : 'text'}>
                    {isActiveStore ? (
                      <RiCheckFill size="28px" />
                    ) : (
                      <RiWindowLine size="28px" />
                    )}
                  </Box>
                  <Text ml="12px" color={isActiveStore ? 'primary' : 'text'}>
                    {store.name}
                  </Text>
                </Flex>
                {!isActiveStore && (
                  <Button
                    isLoading={state.matches(SITE_STATE.SWITCHING_SITE)}
                    size="sm"
                    onClick={() =>
                      send({
                        type: SITE_EVENT.SWITCH_SITE,
                        storeName: store.name,
                      })
                    }
                  >
                    Switch
                  </Button>
                )}
              </Flex>
            );
          })}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default SwitchSiteDrawer;
