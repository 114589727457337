import { useEffect } from 'react';
import * as R from 'ramda';
import { useMachine } from '@xstate/react';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Heading,
  Text,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  Center,
} from '@chakra-ui/react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { CloseButton } from './CloseButton';
import Select from './Select';
import {
  scheduleMachine,
  ScheduleContext,
  ScheduleEvent,
  SCHEDULE_STATES,
  SCHEDULE_EVENTS,
} from '../machines/scheduleMachine';
import DatetimePicker from './DatetimePicker';
import { DEFAULT_DATE } from '../constants';
import { getVersionName } from '../utils/getVersionName';
import Loading, { LOADING_TYPE } from './Loading';
import { EmptyIcon } from './Icons';
import { formatDate } from '../utils/formatDate';
import { usePageQuery } from '../apollo/apollo.generate';
import { PageVersion } from '../apollo/types.generate';

type ScheduleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  pageId: string;
};

const Row = ({ children }) => (
  <HStack
    px="16px"
    justify="space-between"
    borderBottom="1px solid"
    borderColor="border"
  >
    {children}
  </HStack>
);

const ScheduleModal = ({ isOpen, onClose, pageId }: ScheduleModalProps) => {
  const { data: pageData } = usePageQuery({
    variables: {
      id: pageId,
    },
    skip: !pageId,
  });
  const [state, send] = useMachine<ScheduleContext, ScheduleEvent>(
    scheduleMachine,
    {
      context: { pageId },
    }
  );
  const { schedules, versions, publishDate, versionId } = state.context;

  useEffect(() => {
    if (isOpen && pageId) {
      send({
        type: SCHEDULE_EVENTS.RELOAD,
        pageId,
      });
    }
  }, [isOpen, pageId, send]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="editor">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader borderColor="primary">
          Schedule
          <CloseButton pos="initial" onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <Box
            w="full"
            h="135px"
            mb="16px"
            p="16px"
            bgColor="background"
            borderRadius="md"
          >
            <Flex justify="space-between">
              <Heading fontSize="14px">{pageData?.page.title}</Heading>
              <Heading fontSize="14px" color="primary">
                *Maximum 6 schedules
              </Heading>
            </Flex>
            <HStack spacing="16px" alignItems="flex-end" mt="16px">
              <FormControl w="240px">
                <FormLabel>Publish on</FormLabel>
                <DatetimePicker
                  minDate={DEFAULT_DATE}
                  selected={publishDate}
                  placeholderText="Select date"
                  disabled={R.gte(R.length(schedules), 6)}
                  onChange={(date: Date | null) =>
                    send({
                      type: SCHEDULE_EVENTS.UPDATE_PUBLISH_DATE,
                      publishDate: date,
                    })
                  }
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel>Revert To</FormLabel>
                <Select
                  value={versions.find((v) => v.id === versionId)}
                  options={versions}
                  placeholder="Select revert version"
                  itemToString={getVersionName}
                  renderLabel={(version: PageVersion) => (
                    <Text isTruncated>{getVersionName(version)}</Text>
                  )}
                  disabled={R.gte(R.length(schedules), 6)}
                  isLoading={state.matches(SCHEDULE_STATES.LOADING)}
                  onChange={(version: { id: string }) =>
                    version &&
                    send({
                      type: SCHEDULE_EVENTS.UPDATE_VERSION_ID,
                      versionId: version.id,
                    })
                  }
                />
              </FormControl>
              <Button
                w="96px"
                isDisabled={!publishDate || !versionId}
                isLoading={state.matches(
                  `${SCHEDULE_STATES.LOADED}.${SCHEDULE_STATES.CREATE_SCHEDULE_SECTION}.${SCHEDULE_STATES.CREATE_SCHEDULE}`
                )}
                loadingText="Add"
                onClick={() => send({ type: SCHEDULE_EVENTS.CREATE_SCHEDULE })}
              >
                Add
              </Button>
            </HStack>
          </Box>
          <Flex
            h="363px"
            direction="column"
            borderRadius="md"
            border="1px solid"
            borderColor="border"
            overflow="hidden"
          >
            <Row>
              <Flex my="8px">
                <Text w="240px" fontWeight={600}>
                  Publish on
                </Text>
                <Text w="357px" fontWeight={600}>
                  Revert to
                </Text>
              </Flex>
            </Row>
            {state.matches(SCHEDULE_STATES.LOADING) && (
              <Loading type={LOADING_TYPE.Spinner} />
            )}
            {state.matches(
              `${SCHEDULE_STATES.LOADED}.${SCHEDULE_STATES.SCHEDULE_HISTORY_SECTION}.${SCHEDULE_STATES.SCHEDULES_EMPTY}`
            ) && (
              <Center h="full" flexDirection="column">
                <EmptyIcon />
                <Text mt="8px">No schedule</Text>
              </Center>
            )}
            {(state.matches(
              `${SCHEDULE_STATES.LOADED}.${SCHEDULE_STATES.SCHEDULE_HISTORY_SECTION}.${SCHEDULE_STATES.IDLE}`
            ) ||
              state.matches(
                `${SCHEDULE_STATES.LOADED}.${SCHEDULE_STATES.SCHEDULE_HISTORY_SECTION}.${SCHEDULE_STATES.DELETE_SCHEDULE}`
              )) &&
              R.map(({ id, publishDate, pageVersion }) => {
                return (
                  <Row key={id}>
                    <Flex my="16px">
                      <Text w="240px">{formatDate(publishDate)}</Text>
                      <Text
                        w="357px"
                        isTruncated
                        title={getVersionName(pageVersion)}
                      >
                        {getVersionName(pageVersion)}
                      </Text>
                    </Flex>
                    <IconButton
                      variant="link"
                      icon={<RiDeleteBin2Line />}
                      aria-label="delete"
                      isLoading={state.matches(
                        `${SCHEDULE_STATES.LOADED}.${SCHEDULE_STATES.SCHEDULE_HISTORY_SECTION}.${SCHEDULE_STATES.DELETE_SCHEDULE}`
                      )}
                      onClick={() =>
                        send({ type: SCHEDULE_EVENTS.DELETE_SCHEDULE, id })
                      }
                    />
                  </Row>
                );
              }, schedules)}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ScheduleModal;
