import { Heading, Text, Flex, HStack, Button, Image } from '@chakra-ui/react';
import { PageType } from '../apollo/types.generate';

type WelcomeBannerProps = {
  createPageByType: (pageType: PageType) => void;
};

const WelcomeBanner = ({ createPageByType }: WelcomeBannerProps) => {
  return (
    <Flex
      width="100%"
      direction="column"
      justifyContent="center"
      backgroundImage={`url(${
        process.env.PUBLIC_URL || '/'
      }image/img_welcome_banner_background.png)`}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="8px"
      paddingY="60px"
      position="relative"
      textAlign="center"
    >
      <Image
        width="100%"
        position="absolute"
        top="0"
        left="0"
        src={`${
          process.env.PUBLIC_URL || '/'
        }image/img_welcome_banner_background.gif`}
      />
      <Text fontSize="24px" fontWeight="bold" color="#fff">
        Welcome, what are we building today?
      </Text>
      <Heading color="#fff" mt="16px">
        Please select a page type
      </Heading>
      <HStack justifyContent="center" spacing="32px" mt="32px">
        <Button
          width="150px"
          data-track-event="click_build_regular"
          data-track-parameter-name="placement"
          data-track-parameter-value="main_tab_welcome_banner"
          onClick={() => createPageByType(PageType.Regular)}
        >
          Regular
        </Button>
        <Button
          width="150px"
          data-track-event="click_build_product"
          data-track-parameter-name="placement"
          data-track-parameter-value="main_tab_welcome_banner"
          onClick={() => createPageByType(PageType.Product)}
        >
          Product
        </Button>
        <Button
          width="150px"
          data-track-event="click_build_collection"
          data-track-parameter-name="placement"
          data-track-parameter-value="main_tab_welcome_banner"
          onClick={() => createPageByType(PageType.Collection)}
        >
          Collection
        </Button>
        <Button
          width="150px"
          data-track-event="click_build_blog_post"
          data-track-parameter-name="placement"
          data-track-parameter-value="main_tab_welcome_banner"
          onClick={() => createPageByType(PageType.Post)}
        >
          Blog Post
        </Button>
      </HStack>
    </Flex>
  );
};
export default WelcomeBanner;
