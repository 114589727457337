import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client/react';
import { css, Global } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import { client, SdkProvider } from './apollo/client';
import { getSdkApollo } from './apollo/requester';
import { Layout } from './layouts/Layout';
import { AuthProvider } from './machines/authMachine';
import theme from './theme';
import Fonts from './Fonts';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <SdkProvider value={getSdkApollo(client)}>
        <AuthProvider>
          <ChakraProvider theme={theme}>
            <Global
              styles={css`
                ::selection {
                  background-color: #fccec9;
                }
                mark {
                  color: currentColor;
                }
                #chakra-toast-manager-top {
                  margin-top: 16px !important;
                  gap: 10px;
                }
                #chakra-toast-manager-bottom {
                  margin-bottom: 16px !important;
                  gap: 10px;
                }
                // override: https://github.com/chakra-ui/chakra-ui/blob/0de887f3b5110ad01ee7cf70e19bc759242da695/packages/toast/src/toast.tsx#L123
                .chakra-toast__inner {
                  margin-top: 0 !important;
                  margin-bottom: 0 !important;
                }
              `}
            />
            <Fonts />
            <Layout />
          </ChakraProvider>
        </AuthProvider>
      </SdkProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
