import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createContext, useContext } from 'react';
import { getSdkApollo, Sdk } from '../apollo/requester';
import { API_ENDPOINT } from '../constants';
import { logout } from '../machines/authMachine';
import { createUploadLink } from 'apollo-upload-client';

const link = createUploadLink({
  uri: `${API_ENDPOINT}/graphql`,
  credentials: 'include',
});

const logoutLink = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach((error) => {
    if (error.extensions?.code === 'UNAUTHENTICATED') {
      logout();
    }
  });
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(link),
});

export const sdk = getSdkApollo(client);
const SdkContext = createContext<Sdk>(sdk);
export const SdkProvider = SdkContext.Provider;
export const useSdk = () => {
  return useContext(SdkContext);
};
