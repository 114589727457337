import { Flex } from '@chakra-ui/layout';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { AUTH_STATE, useAuth } from '../machines/authMachine';
import Loading from './Loading';

export const PrivateRoute = ({ children }) => {
  const [state] = useAuth();

  return (
    <Route
      render={() => {
        switch (true) {
          case state.matches(AUTH_STATE.LOGGED_OUT):
            return <Redirect to="/login"></Redirect>;
          case state.matches([AUTH_STATE.LOGGED_IN, AUTH_STATE.ENABLED]) ||
            state.matches([AUTH_STATE.LOGGED_IN, AUTH_STATE.DISABLED]):
            return children;
          // case state.matches([AUTH_STATE.LOGGED_IN, AUTH_STATE.DISABLED]):
          //   return location.pathname === '/shopify-charge' ? (
          //     children
          //   ) : (
          //     <Redirect to="/shopify-charge"></Redirect>
          //   );
          default:
            return (
              <Flex justify="center" align="center" h="100vh" bg="background">
                <Loading />
              </Flex>
            );
        }
      }}
    ></Route>
  );
};
