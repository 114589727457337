import { Alert, AlertDescription, AlertIcon, BoxProps } from '@chakra-ui/react';
import { RiInformationLine } from 'react-icons/ri';

const ProductStatusAlert = (props: BoxProps) => {
  return (
    <Alert variant="tip" {...props}>
      <AlertIcon as={RiInformationLine} mx="8px" />
      <AlertDescription fontWeight="500" p="8px" pl="0">
        Products in <b>"Draft"</b> and <b>"Archived"</b> or with{' '}
        <b>0 sales channels</b> will not be displayed on your live page.
        <br />
        You can update product status and sales channels on Shopify.
      </AlertDescription>
    </Alert>
  );
};

export default ProductStatusAlert;
