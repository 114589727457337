import { assign, DoneInvokeEvent, createMachine } from 'xstate';
import { sdk } from '../apollo/client';

enum S {
  LOADING = 'LOADING',
  IDLE = 'IDLE',
  SWITCHING_SITE = 'SWITCHING_SITE',
  ERROR = 'ERROR',
}

enum E {
  SWITCH_SITE = 'SWITCH_SITE',
}

type Store = {
  name: string;
};

type StoreQuery = {
  user: {
    currentStore: string;
  };
  stores: Store[];
};

type ContextType = {
  currentStore: string | null;
  stores: Store[];
};

const siteMachine = createMachine<ContextType>(
  {
    context: {
      currentStore: null,
      stores: [],
    },
    initial: S.LOADING,
    states: {
      [S.LOADING]: {
        invoke: {
          src: 'getStores',
          onDone: {
            target: S.IDLE,
            actions: ['assignStores', 'assignCurrentStore'],
          },
          onError: {
            target: S.ERROR,
          },
        },
      },
      [S.IDLE]: {
        on: {
          [E.SWITCH_SITE]: S.SWITCHING_SITE,
        },
      },
      [S.SWITCHING_SITE]: {
        invoke: {
          src: 'switchStore',
          onDone: {
            target: S.IDLE,
            actions: ['reloadPage'],
          },
          onError: {
            target: S.ERROR,
          },
        },
      },
      [S.ERROR]: {},
    },
  },
  {
    actions: {
      assignStores: assign<ContextType, DoneInvokeEvent<StoreQuery>>({
        stores: (_, event) => event.data.stores,
      }),
      assignCurrentStore: assign<ContextType, DoneInvokeEvent<StoreQuery>>({
        currentStore: (_, event) => event.data.user.currentStore,
      }),
      reloadPage: () => window.location.reload(),
    },
    services: {
      getStores: () => sdk.getStores(),
      switchStore: (ctx, event) =>
        sdk.switchStore({
          storeName: event.storeName,
        }),
    },
  }
);
export { siteMachine, S as SITE_STATE, E as SITE_EVENT };
