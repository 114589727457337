import { extendTheme } from '@chakra-ui/react';
import { transparentize, rgba } from 'polished';
import { PageStatus } from './apollo/types.generate';
import { BREAKPOINT } from './pages/editor/constants';

const theme = extendTheme({
  breakpoints: [BREAKPOINT.mobile, BREAKPOINT.tablet, BREAKPOINT.desktop],
  fonts: {
    body: 'VelaSans, Helvetica Neue, Open Sans',
    heading: 'VelaSans, Helvetica Neue, Open Sans',
    lineHeight: '1.5',
  },
  radii: {
    xs: '2px',
    // Badge
    sm: '4px',
    // Button, Input, Menu
    md: '4px',
    lg: '8px',
  },
  colors: {
    primary: '#f65e4e',
    primaryDark: '#ee5140',
    primaryHover: '#fc7365',
    primaryLight: transparentize(0.9, '#f65e4e'),
    primaryUltralight: transparentize(0.95, '#f65e4e'),
    secondary: '#3d37ca',
    secondaryDark: '#2d28ad',
    secondaryHover: '#6a65df',
    secondaryLight: '#9e9be4',
    info: '#4171ff',
    infoBackground: 'rgba(65, 113, 255, 0.1)',
    success: '#39ca69',
    warning: '#fd7929',
    warningBackground: '#feefe5',
    danger: '#ff3e3e',
    background: '#f3f3f3',
    activeButtonBackground: '#feeeed',
    border: '#e5e5e5',
    hoveredBorder: '#c4c4c4',
    text: '#585858',
    disabledText: '#d0d0d0',
    placeholder: '#b2b2b2',
    icon: '#c0c0c0',
    line: '#dfdfdf',
    gray: {
      100: rgba('#585858', 0.1), // Badge default bg color
      200: rgba('#585858', 0.1), // Avatar default bg color
      800: '#585858', // Badge default text color
    },
    // Badge's ColorScheme
    [PageStatus.Draft]: {
      100: 'rgba(65, 113, 255, 0.1)', // bg color
      800: '#4171ff', // text color
    },
    [PageStatus.Published]: {
      100: 'rgba(57, 202, 105, 0.1)',
      800: '#39ca69',
    },
    [PageStatus.Outdated]: {
      100: 'rgba(253, 121, 41, 0.1)',
      800: '#fd7929',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: '#000',
        fontWeight: 600,
      },
      defaultProps: {
        size: '16px',
      },
    },
    Text: {
      baseStyle: {
        fontSize: '14px',
        color: 'text',
      },
    },
    Badge: {
      baseStyle: {
        px: '12px',
        py: '4px',
        textTransform: 'capitalize',
        fontSize: '12px',
        fontWeight: 600,
      },
    },
    Divider: {
      baseStyle: {
        opacity: 1,
        borderColor: 'line',
      },
    },
    Alert: {
      parts: ['container', 'description', 'icon'],
      baseStyle: {
        container: {
          px: '12px',
          py: '8px',
          borderRadius: '4px',
        },
        description: {
          fontSize: '12px',
          lineHeight: 1.5,
          color: 'primary',
        },
      },
      variants: {
        info: {
          container: {
            px: '8px',
            bgColor: 'infoBackground',
            borderColor: 'info',
          },
          icon: {
            mr: '8px',
            color: 'info',
            boxSize: '16px',
          },
          description: {
            color: 'info',
          },
        },
        warning: {
          container: {
            px: '8px',
            bgColor: 'warningBackground',
            borderColor: 'warning',
          },
          icon: {
            mr: '8px',
            color: 'warning',
            boxSize: '16px',
          },
          description: {
            fontWeight: 600,
            color: 'warning',
          },
        },
        subtle: {
          container: {
            bgColor: 'primaryLight',
          },
          icon: {
            color: 'primary',
            boxSize: '24px',
          },
        },
        tip: {
          container: {
            px: 'auto',
            py: '4px',
            bgColor: 'infoBackground',
          },
          icon: {
            mx: '4px',
            color: 'info',
            boxSize: '16px',
          },
          description: {
            fontWeight: 600,
            color: 'info',
          },
        },
      },
    },
    Menu: {
      parts: ['item', 'list'],
      baseStyle: {
        item: {
          color: 'text',
          p: '16px',
          fontSize: '14px',
          _focus: {
            color: '#000',
            bgColor: 'background',
            fontSize: '14px',
            fontWeight: 600,
          },
          _active: {
            color: '#000',
            bgColor: 'background',
            fontSize: '14px',
            fontWeight: 600,
          },
        },
        list: {
          zIndex: 'dropdown',
          border: 'none',
          boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
        },
      },
      variants: {
        editor: {
          item: {
            p: '8px',
            fontSize: '12px',
            fontWeight: 600,
            _checked: {
              color: 'primary',
            },
            _focus: {
              color: '#000',
              bgColor: 'background',
              fontSize: '12px',
              fontWeight: 600,
            },
            _active: {
              color: '#000',
              bgColor: 'background',
              fontSize: '12px',
              fontWeight: 600,
            },
          },
          list: {
            minW: '80px',
            maxH: '150px',
            paddingTop: '4px',
            paddingBottom: '4px',
            overflow: 'auto',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        _loading: {
          cursor: 'progress',
          _hover: {
            bgColor: 'primaryHover',
          },
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        solid: {
          bgColor: 'primary',
          color: 'white',
          _hover: {
            bgColor: 'primaryHover',
            _disabled: {
              bgColor: 'primaryHover',
            },
          },
          _active: {
            bgColor: 'primaryDark',
          },
          _loading: {
            opacity: 0.5,
          },
        },
        outline: {
          color: '#000',
          borderColor: '#000',
          _hover: {
            bgColor: 'rgba(0, 0, 0, 0.08)',
          },
          _active: {
            color: 'white',
            bgColor: '#000',
            '& > svg': {
              color: 'white',
            },
          },
          _loading: {
            _hover: {
              bgColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
        solidSecondary: {
          bgColor: 'secondary',
          color: 'white',
          _hover: {
            bgColor: 'secondaryHover',
            _disabled: {
              bgColor: 'secondaryHover',
            },
          },
          _active: {
            bgColor: 'secondaryDark',
          },
          _loading: {
            opacity: 0.5,
          },
        },
        normal: {
          color: '#000',
          _hover: {
            bgColor: 'transparent',
          },
          _active: { bgColor: 'transparent' },
          cursor: 'default',
        },
        link: {
          width: '32px',
          height: '32px',
          fontSize: '24px',
          minW: 'auto',
          color: 'icon',
          _loading: {
            _hover: {
              color: 'primaryHover',
              bgColor: 'transparent',
            },
          },
          _hover: {
            color: 'primaryHover',
          },
          _active: {
            color: 'primary',
          },
        },
        icon: {
          color: 'text',
          _hover: {
            color: 'primary',
            _disabled: {
              color: 'text',
            },
          },
          _active: {
            color: 'primary',
            bgColor: 'activeButtonBackground',
          },
          _disabled: {
            color: 'icon',
          },
        },
        ghost: {
          color: 'primary',
          _hover: {
            color: 'primaryHover',
            bg: 'none',
          },
          _active: {
            color: 'primary',
            bg: 'none',
          },
        },
        dataPicker: {
          color: 'text',
          _hover: {
            color: 'primaryHover',
            bg: 'none',
            _disabled: {
              color: 'text',
            },
          },
          _active: {
            color: 'white',
            bgColor: 'primary',
          },
        },
        iconPicker: {
          width: '28px',
          height: '28px',
          fontSize: '24px',
          minW: 'auto',
          color: 'black',
          _hover: {
            border: '1px solid',
            borderColor: 'primary',
          },
          _active: {
            border: '1px solid',
            borderColor: 'primary',
          },
        },
        editor: (props) => ({
          paddingInlineStart: '2px',
          paddingInlineEnd: '2px',
          minWidth: 'auto',
          w: '20px',
          h: '20px',
          mr: '8px',
          mb: '8px',
          lineHeight: '20px',
          fontSize: '16px',
          borderRadius: '2px',
          color: props.active ? 'primary' : '#000',
          bgColor: props.active ? 'primaryLight' : 'transparent',
          _hover: {
            bgColor: 'background',
          },
          _active: { bgColor: 'border' },
        }),
        image: {
          w: '164px',
          h: '200px',
          color: 'line',
          _hover: {
            color: 'primaryHover',
            _disabled: {
              color: 'line',
            },
          },
          _active: {
            color: 'primary',
          },
          _disabled: {
            opacity: 1,
          },
        },
        close: {
          width: '28px',
          height: '28px',
          fontSize: '24px',
          minW: 'auto',
          pos: 'absolute',
          top: '10px',
          right: '16px',
          color: '#000',
          _focus: {
            boxShadow: 'none',
          },
        },
      },
      sizes: {
        sm: {
          h: '32px',
          minW: 'auto',
          fontSize: '12px',
          lineHeight: '32px',
          paddingInlineStart: '16px',
          paddingInlineEnd: '16px',
        },
        md: {
          h: '42px',
          minW: 'auto',
          fontSize: '14px',
          lineHeight: '42px',
          paddingInlineStart: '24px',
          paddingInlineEnd: '24px',
        },
        'icon-xs': {
          boxSize: '20px',
          fontSize: '16px',
        },
        'icon-sm': {
          boxSize: '28px',
          fontSize: '20px',
        },
        'icon-md': {
          boxSize: '32px',
          fontSize: '24px',
        },
      },
    },
    Icon: {
      defaultProps: {
        fontSize: '20px',
        color: 'icon',
      },
    },
    Tabs: {
      parts: ['root', 'tablist', 'tab', 'tabpanel'],
      baseStyle: {
        root: { borderColor: 'line' },
        tabpanel: {
          p: 0,
        },
      },
      variants: {
        line: {
          tablist: { borderBottomWidth: '1px' },
          tab: {
            paddingBottom: '5px',
            alignItems: 'flex-end',
            fontSize: '14px',
            color: 'text',
            borderBottomWidth: '3px',
            marginBottom: '-1px',
            _selected: {
              color: 'primary',
              fontWeight: 600,
              borderColor: 'primary',
            },
            _active: {
              bgColor: 'transparent',
            },
            _focus: {
              boxShadow: 'none',
            },
          },
        },
        card: {
          tab: {
            borderWidth: '1px',
            borderColor: 'border',
            borderRightWidth: 0,
            height: '42px',
            fontSize: '12px',
            fontWeight: 500,
            color: 'text',
            _first: {
              borderRadius: '4px 0 0 4px',
            },
            _last: {
              borderRightWidth: '1px',
              borderRadius: '0 4px 4px 0',
            },
            _selected: {
              color: 'white',
              bg: 'primary',
              borderColor: 'primary',
            },
            _focus: {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        primary: {
          color: 'primary',
          textDecoration: 'underline',
        },
      },
    },
    Skeleton: {
      defaultProps: {
        startColor: '#dedede',
        endColor: '#cdcdcd',
      },
    },
    Modal: {
      parts: ['header', 'body', 'footer'],
      baseStyle: {
        header: {
          px: '32px',
          py: '30px',
          fontSize: '18px',
          fontWieght: 'bold',
          color: 'black',
        },
        body: {
          px: '32px',
          pt: 0,
          pb: '32px',
        },
        footer: {
          px: '32px',
          py: '32px',
          borderTop: '1px solid',
          borderColor: 'border',
          Button: {
            _first: {
              mr: 'auto',
            },
          },
        },
      },
      variants: {
        editor: {
          header: {
            py: '30px',
            fontSize: '18px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          body: {
            p: '0px 32px 32px',
          },
          footer: {
            justifyContent: 'space-between',
            padding: '24px 32px',
            Button: {
              w: 'auto',
              m: 0,
            },
          },
        },
      },
    },
    Section: {
      baseStyle: {
        width: '1400px',
      },
    },
    ModalPageCardLoading: {
      parts: ['cover', 'info', 'title'],
      baseStyle: {
        cover: {
          w: '230px',
          h: '160px',
        },
        info: {
          w: '100%',
          h: '15px',
        },
        title: {
          w: '132px',
          h: '15px',
        },
      },
    },
    ModalPageCard: {
      parts: [
        'container',
        'coverContainer',
        'cover',
        'title',
        'version',
        'type',
        'button',
      ],
      baseStyle: (props) => {
        return {
          container: {
            w: '230px',
            h: '265px',
            alignItems: 'flex-start',
          },
          coverContainer: {
            position: 'relative',
            border: '1px solid',
            borderColor: 'line',
            borderRadius: '4px',
            overflow: 'hidden',
          },
          cover: {
            width: '230px',
            height: '160px',
            objectFit: 'cover',
          },
          title: {
            w: '100%',
            color: '#000',
            fontSize: '14px',
            fontWeight: 600,
          },
          version: {
            fontSize: '12px',
            color: 'text',
          },
          type: {
            bgColor: rgba('#585858', 0.1),
            color: 'text',
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '4px',
            p: '4px 12px',
            textTransform: 'capitalize',
          },
          layer: {
            display: props.active ? 'flex' : 'none',
            position: 'absolute',
            bgColor: rgba('#f65e4e', 0.3),
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            alignItems: 'center',
            justifyContent: 'center',
            _groupHover: { display: 'flex' },
            Button: {
              fontSize: '12px',
              paddingLeft: '16px',
              paddingRight: '16px',
              _loading: {
                cursor: 'progress',
                opacity: 1,
                bgColor: 'white',
                color: 'primary',
              },
            },
          },
        };
      },
    },
    PageCardLoading: {
      parts: ['cover', 'title', 'description'],
      baseStyle: {
        title: {
          mb: '16px',
          h: '15px',
        },
        description: {
          w: '300px',
          h: '15px',
        },
      },
      variants: {
        list: {
          cover: {
            ml: '8px',
          },
          title: {
            w: '400px',
          },
          description: {
            w: '300px',
          },
        },
        card: {
          cover: {
            ml: 0,
          },
          title: {
            w: '226px',
          },
          description: {
            w: '132px',
          },
        },
      },
    },
    PageCard: {
      parts: [
        'container',
        'markIcon',
        'option',
        'optionButton',
        'cover',
        'title',
        'description',
        'footer',
        'info',
      ],
      baseStyle: {
        container: {
          pos: 'relative',
          bgColor: 'white',
          borderRadius: 4,
          transition: 'box-shadow 0.3s',
          overflow: 'hidden',
          _hover: {
            zIndex: 'docked',
            boxShadow:
              '0 12px 10px -8px rgba(0, 0, 0, 0.05), 0 24px 24px -8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
          },
        },
        markIcon: {
          boxSize: '32px',
          borderRadius: 4,
          p: '4px',
        },
        option: {
          pos: 'absolute',
        },
        optionButton: {
          visibility: 'hidden',
          minW: 'auto',
          borderRadius: 4,
          bgColor: 'white',
          _groupHover: { visibility: 'visible' },
          _active: {
            visibility: 'visible',
            color: 'primary',
            bgColor: 'activeButtonBackground',
          },
        },
        title: {
          fontWeight: 600,
        },
        description: {
          fontSize: '12px',
        },
        footer: {
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        info: {
          fontSize: '12px',
        },
      },
      variants: {
        list: {
          container: {
            flexDirection: 'row',
            alignItems: 'center',
            h: '116px',
          },
          markIcon: {
            ml: '26px',
            mr: '18px',
            color: 'primary',
            bgColor: 'white',
          },
          option: {
            right: '26px',
          },
          cover: {
            mr: '24px',
            width: '129px',
            height: '100px',
            objectFit: 'cover',
            borderRadius: 4,
            border: '1px solid',
            borderColor: 'border',
          },
          title: {
            w: '450px',
            mr: '24px',
            mb: '8px',
          },
          description: {
            display: 'inline-block',
            w: '450px',
          },
          footer: {
            w: '325px',
            paddingRight: '56px',
          },
        },
        card: {
          container: {
            flexDirection: 'column',
            w: '333px',
            h: '400px',
          },
          markIcon: {
            pos: 'absolute',
            top: '8px',
            left: '8px',
            color: 'white',
            bgColor: 'primary',
          },
          option: {
            top: '8px',
            right: '8px',
          },
          cover: {
            width: '333px',
            height: '258px',
            borderBottom: '1px solid',
            borderColor: 'border',
          },
          title: {
            h: '48px',
            m: '16px',
          },
          description: {
            display: 'none',
          },
          footer: {
            m: '0 16px',
          },
        },
      },
    },
    SupportCard: {
      parts: [
        'container',
        'title',
        'itemContainer',
        'textContainer',
        'icon',
        'text',
        'link',
      ],
      baseStyle: {
        container: {
          borderRadius: 8,
          boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
          bgColor: 'white',
        },
        title: {
          color: '#000',
        },
        textContainer: {
          flexDirection: 'column',
        },
        text: {
          fontWeight: 600,
          color: '#000',
        },
        link: {
          fontSize: '14px',
          color: 'primary',
          textDecoration: 'underline',
          _hover: {
            textDecoration: 'underline',
          },
        },
      },
      variants: {
        horizontal: {
          container: {
            width: '806px',
            height: '240px',
            padding: '32px 32px 34px',
          },
          title: {
            fontSize: '24px',
            fontWeight: 'bold',
            mb: '24px',
          },
          itemContainer: {
            flexDirection: 'column',
          },
          textContainer: {
            ml: '0',
          },
          icon: {
            '& > svg': {
              height: '36px',
              width: '36px',
            },
          },
          text: {
            fontSize: '16px',
            mt: '8px',
          },
          link: {
            fontWeight: 600,
            mt: '4px',
          },
        },
        vertical: {
          container: {
            width: '320px',
            padding: '24px 24px 32px',
          },
          title: {
            fontSize: '18px',
            fontWeight: 600,
            mb: '16px',
          },
          itemContainer: {
            flexDirection: 'row',
          },
          textContainer: {
            ml: '16px',
          },
          icon: {
            '& > svg': {
              height: '30px',
              width: '30px',
            },
          },
          text: {
            fontSize: '14px',
            mt: '5px',
          },
          link: {
            fontWeight: 400,
            mt: '3px',
          },
        },
      },
    },
    RequestPageBuildCard: {
      parts: [
        'container',
        'title',
        'highlight',
        'description',
        'button',
        'image',
      ],
      baseStyle: {
        container: {
          borderRadius: '8px',
          boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
          background: '#fff',
          position: 'relative',
        },
        title: {
          color: 'black',
        },
        highlight: {
          color: 'primary',
        },
        description: {
          marginTop: '8px',
        },
        button: {
          marginTop: '16px',
          color: 'black',
        },
        image: {
          position: 'absolute',
          bottom: '0',
        },
      },
      variants: {
        horizontal: {
          container: {
            width: '570px',
            height: '240px',
            padding: '32px',
          },
          title: {
            fontSize: '24px',
            fontWeight: 'bold',
          },
          highlight: {
            fontSize: '24px',
            fontWeight: 'bold',
          },
          description: {
            fontSize: '14px',
            fontWeight: 600,
          },
          button: {
            width: '105px',
            fontSize: '14px',
            fontWeight: 600,
          },
          image: {
            maxWidth: '220px',
            right: '38px',
          },
        },
        vertical: {
          container: {
            width: '320px',
            height: '180px',
            padding: '24px',
          },
          title: {
            fontSize: '18px',
            fontWeight: 600,
          },
          highlight: {
            fontSize: '18px',
            fontWeight: 600,
          },
          description: {
            fontSize: '12px',
          },
          button: {
            width: '80px',
            fontSize: '12px',
            fontWeight: 500,
            height: '32px',
          },
          image: {
            maxWidth: '150px',
            right: '16px',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '12px',
        fontWeight: 600,
        color: 'text',
      },
    },
    FormError: {
      baseStyle: {
        text: {
          fontSize: '12px',
          color: 'danger',
          mt: '4px',
        },
      },
    },
    Form: {
      baseStyle: {
        helperText: {
          mt: '4px',
          fontSize: '12px',
          lineHeight: 1.5,
          color: 'text',
        },
      },
    },
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: props.active ? 'primary' : '#e5e5e5',
            bgColor: 'white',
            _hover: {
              borderColor: props.active ? 'primary' : 'hoveredBorder',
            },
            _focus: {
              borderColor: props.active ? '#e5e5e5' : 'primary',
              boxShadow: 'none',
            },
            _disabled: {
              opacity: 1,
              color: '#d0d0d0',
              bgColor: '#eeeeee',
              borderColor: '#e5e5e5',
            },
            _invalid: {
              bgColor: 'rgba(255, 62, 62, 0.1)',
              borderColor: 'danger',
              boxShadow: 'none',
            },
            _placeholder: {
              color: 'placeholder',
            },
          },
          addon: {
            borderColor: 'border',
            px: '12px',
            height: '42px',
            bgColor: 'background',
            fontSize: '14px',
            lineHeight: '42px',
          },
        }),
        editor: (props) => ({
          field: {
            p: '4px',
            height: '20px',
            fontSize: '12px',
            border: '1px solid',
            borderColor: props.active ? 'primary' : 'transparent',
            pointerEvents: 'auto',
            _hover: {
              bgColor: 'background',
              _focus: {
                bgColor: 'transparent',
              },
            },
            _focus: {
              borderColor: 'primary',
            },
          },
        }),
      },
      sizes: {
        xs: {
          field: {
            px: '4px',
            height: '26px',
            fontSize: '12px',
            lineHeight: '26px',
          },
          addon: {
            height: '26px',
            fontSize: '12px',
            lineHeight: '26px',
          },
        },
        sm: {
          field: {
            px: '6px',
            height: '32px',
            fontSize: '12px',
            lineHeight: '32px',
          },
          addon: {
            height: '32px',
            fontSize: '12px',
            lineHeight: '32px',
          },
        },
        md: {
          field: {
            px: '12px',
            height: '42px',
            fontSize: '14px',
            lineHeight: '42px',
          },
          addon: {
            height: '42px',
            fontSize: '14px',
            lineHeight: '42px',
          },
        },
      },
    },
    Select: {
      parts: ['field', 'icon'],
      baseStyle: {
        field: {
          ':invalid': {
            color: 'placeholder',
          },
        },
        icon: {
          color: 'placeholder',
        },
      },
      variants: {
        outline: {
          field: {
            fontSize: '14px',
            borderColor: 'border',
            _focusVisible: {
              borderColor: 'border',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: (props) => ({
          borderColor: props.active ? 'primary' : 'border',
          bgColor: 'white',
          paddingLeft: '12px',
          minH: '96px',
          fontSize: '14px',
          lineHeight: 1.5,
          _hover: {
            borderColor: props.active ? 'primary' : 'hoveredBorder',
          },
          _focus: {
            borderColor: props.active ? 'border' : 'primary',
            boxShadow: 'none',
          },
        }),
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bgColor: 'primary',
            borderColor: 'primary',
          },
        },
      },
    },
    Popover: {
      parts: ['popper', 'content'],
      baseStyle: {
        popper: {
          zIndex: 'popover',
          _focusVisible: { outline: 'none' },
        },
        content: {
          border: 'none',
          boxShadow: 'none',
          width: 'auto',
          _focus: {
            boxShadow: 'none',
          },
          _focusVisible: { outline: 'none' },
        },
        footer: {
          borderColor: 'border',
        },
      },
    },
    StyledSelect: {
      parts: [
        'popoverContent',
        'popoverBody',
        'list',
        'option',
        'checkbox',
        'input',
        'icon',
      ],
      baseStyle: (props) => ({
        popoverContent: {
          w: 'inherit',
          overflow: 'hidden',
          boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'border',
          _focusVisible: { outline: 'none' },
          _focus: {
            boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)',
          },
        },
        popoverBody: {
          p: 0,
          maxH: '325px',
        },
        list: {
          py: '8px',
          maxH: '325px',
          overflow: 'auto',
          '[aria-selected="true"]': {
            bgColor: 'background',
            fontWeight: 600,
            color: 'black',
          },
        },
        option: {
          bgColor: 'white',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'text',
          cursor: 'pointer',
          px: '16px',
          userSelect: 'none',
          minH: '42px',
          py: '10px',
          alignItems: 'center',
          wordBreak: 'break-word',
        },
        checkbox: {
          mr: '16px',
        },
        input: {
          w: 'full',
          cursor: 'pointer',
          _selection: {
            bgColor: 'transparent',
          },
        },
        icon: {
          '& > svg': {
            height: '28px',
            width: '28px',
            color: props.active ? 'black' : 'icon',
          },
        },
      }),
      variants: {
        'no-scroll': {
          popoverBody: {
            maxH: 'none',
          },
        },
      },
    },
    Toast: {
      parts: ['container', 'title', 'icon', 'closeButton'],
      baseStyle: {
        container: {
          minW: '376px',
          p: '16px',
          border: '1px solid',
          borderRadius: '8px',
        },
        title: {
          pr: '44px',
          fontSize: '16px',
          fontWeight: 600,
          color: 'black',
        },
        icon: {
          boxSize: '32px',
        },
        closeButton: {
          top: 'auto',
          right: '16px',
        },
      },
      variants: {
        info: {
          container: {
            bgColor: '#e8eeff',
            borderColor: 'info',
          },
          icon: {
            color: 'info',
          },
        },
        success: {
          container: {
            bgColor: '#e2f7e9',
            borderColor: 'success',
          },
          icon: {
            color: 'success',
          },
        },
        warning: {
          container: {
            bgColor: 'warningBackground',
            borderColor: 'warning',
          },
          icon: {
            color: 'warning',
          },
        },
        error: {
          container: {
            bgColor: '#ffe7e7',
            borderColor: 'danger',
          },
          icon: {
            color: 'danger',
          },
        },
      },
    },
    Accordion: {
      variants: {
        'without-border': {
          button: {
            h: '60px',
            fontSize: '14px',
            fontWeight: 600,
            _focus: {
              boxShadow: 'none',
            },
          },
          icon: {
            boxSize: '20px',
            color: 'icon',
          },
          container: {
            borderTopWidth: '0',
            _last: { borderBottomWidth: '0' },
          },
          panel: {
            pt: 0,
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: 'icon',
          _focus: {
            boxShadow: 'none',
          },
          _checked: {
            bg: 'primary',
          },
        },
      },
    },
    Slider: {
      baseStyle: {
        track: {
          bg: 'line',
        },
        filledTrack: {
          bg: 'primary',
        },
        thumb: {
          _focus: {
            boxShadow: 'base',
          },
        },
      },
    },
    Drawer: {
      parts: ['dialog'],
      baseStyle: {
        dialog: {
          maxW: '400px',
        },
        header: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '30px 32px',
          fontSize: '18px',
          fontWeight: 'semibold',
        },
        footer: {
          borderTop: '1px solid',
          borderColor: 'border',
          py: '24px',
          px: '32px',
        },
      },
      sizes: null as any,
    },
    Breadcrumb: {
      parts: ['item'],
      variants: {
        layoutbase: {
          item: {
            fontSize: '12px',
            color: 'text',
            lineHeight: 1.5,
          },
        },
      },
    },
    NumberInput: {
      parts: ['field', 'stepper'],
      baseStyle: {
        stepper: {
          color: 'icon',
          _hover: { color: 'primary', _disabled: { color: 'icon' } },
          _disabled: { opacity: 1, svg: { opacity: 0.4 } },
        },
      },
      sizes: {
        md: {
          stepper: {
            fontSize: '18px',
          },
        },
      },
      variants: {
        outline: {
          field: {
            _focus: {
              borderColor: 'primary',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          borderColor: 'border',
          _checked: {
            backgroundColor: 'primary',
            borderColor: 'primary',
            _hover: {
              backgroundColor: 'primaryHover',
              borderColor: 'primaryHover',
            },
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      },
      sizes: {
        md: {
          control: {
            w: '20px',
            h: '20px',
          },
          label: {
            fontSize: '14px',
            lineHeight: '20px',
            color: 'black',
          },
        },
      },
    },
  },
});

export default theme;
