import { useActor } from '@xstate/react';
import { createContext, useContext } from 'react';
import {
  interpret,
  createMachine,
  assign,
  DoneInvokeEvent,
  AnyEventObject,
} from 'xstate';
import { sdk } from '../apollo/client';
import { UserQuery } from '../apollo/operations.generate';
import { history } from '../history';
import { Location, createLocation } from 'history';
import { User } from '../apollo/types.generate';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import {
  GTM_AUTH,
  GTM_ID,
  GTM_PREVIEW,
  HOTJAR_ID,
  MIXPANEL_TOKEN,
} from '../constants';

enum S {
  CHECKING_IS_LOGGED_IN = 'CHECKING_IS_LOGGED_IN',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  CHECKING_CHARGE = 'CHECKING_CHARGE',
  LOADING_PERMISSION = 'LOADING_PERMISSION',
  IDLE = 'IDLE',
  UNKNOWN = 'UNKNOWN',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

enum E {
  LOGOUT = 'LOGOUT',
  LOGIN = 'LOGIN',
  RESET_LOCATION = 'RESET_LOCATION',
}

type EventType =
  | { type: E.LOGOUT }
  | { type: E.LOGIN }
  | { type: E.RESET_LOCATION }
  | DoneInvokeEvent<UserQuery>;

type ContextType = {
  user?: User;
  location: Location<unknown>;
  isCharged: boolean;
  hasDevelopPermission: boolean;
  hasAdminPermission: boolean;
  isActive: boolean;
  restTrialDays: number;
};

const authMachine = createMachine<ContextType, EventType>(
  {
    context: {
      user: undefined,
      location: createLocation('/'),
      isCharged: true,
      hasDevelopPermission: false,
      hasAdminPermission: false,
      isActive: false,
      restTrialDays: 0,
    },
    initial: S.UNKNOWN,
    states: {
      [S.UNKNOWN]: {
        always: [
          { cond: 'isInShopifyLoginOrRedirectPage', target: S.IDLE },
          { target: S.CHECKING_IS_LOGGED_IN },
        ],
      },
      [S.IDLE]: {},
      [S.CHECKING_IS_LOGGED_IN]: {
        invoke: {
          src: 'getUser',
          onDone: {
            target: S.LOGGED_IN,
            actions: ['assignUser'],
          },
          onError: {
            target: S.LOGGED_OUT,
          },
        },
      },
      [S.LOGGED_IN]: {
        entry: ['initalizeHotjar', 'initializeMixpanel', 'initializeGtm'],
        initial: S.LOADING_PERMISSION,
        states: {
          [S.LOADING_PERMISSION]: {
            invoke: {
              src: 'getPermission',
              onDone: {
                actions: ['assignPermission'],
                target: S.ENABLED,
              },
              onError: {
                target: S.ENABLED,
              },
            },
          },
          // [S.CHECKING_CHARGE]: {
          //   invoke: {
          //     src: 'checkCharge',
          //     onDone: [
          //       {
          //         cond: ({ hasAdminPermission }, event) =>
          //           hasAdminPermission ||
          //           event.data.restTrialDays > 0 ||
          //           event.data.isActive,
          //         target: S.ENABLED,
          //         actions: ['assignChargeInfo'],
          //       },
          //       {
          //         target: S.DISABLED,
          //         actions: ['assignChargeInfo'],
          //       },
          //     ],
          //     onError: {
          //       actions: ['redirectToPlanPage'],
          //     },
          //   },
          // },
          [S.DISABLED]: {},
          [S.ENABLED]: {},
        },
        on: {
          [E.LOGOUT]: {
            target: S.LOGGED_OUT,
          },
        },
      },
      [S.LOGGED_OUT]: {
        entry: ['assignCurrentPath'],
        on: {
          [E.LOGIN]: S.CHECKING_IS_LOGGED_IN,
          [E.RESET_LOCATION]: { actions: ['resetLocation'] },
        },
      },
    },
  },
  {
    guards: {
      isInShopifyLoginOrRedirectPage: () => {
        return (
          history.location.pathname.startsWith('/shopify/login') ||
          history.location.pathname.startsWith('/shopify/redirect')
        );
      },
    },
    services: {
      getUser: () => sdk.user(),
      checkCharge: () =>
        sdk.checkCharge().then((r) => r.checkShopifyRecurringCharge),
      getPermission: () => sdk.permission().then((r) => r.permission),
    },
    actions: {
      initalizeHotjar: (ctx) => {
        if (process.env.NODE_ENV !== 'development') {
          hotjar.initialize(Number(HOTJAR_ID), 6);
          if (ctx.user) {
            hotjar.identify(ctx.user.name, ctx.user);
          }
        }
      },
      initializeMixpanel: (ctx) => {
        if (process.env.NODE_ENV !== 'development') {
          mixpanel.init(MIXPANEL_TOKEN);
        }
      },
      initializeGtm: () => {
        if (process.env.NODE_ENV !== 'development') {
          TagManager.initialize({
            gtmId: GTM_ID,
            auth: GTM_AUTH,
            preview: GTM_PREVIEW,
          });
        }
      },
      assignUser: assign<ContextType, DoneInvokeEvent<UserQuery>>(
        (_, event) => ({
          user: event.data.user,
        })
      ),
      assignCurrentPath: assign({
        location: () => history.location,
      }),
      resetLocation: assign({
        location: () => createLocation('/'),
      }),
      redirectToPlanPage: () => {
        history.push('/shopify-charge');
      },
      assignChargeInfo: assign((_, event: AnyEventObject) => ({
        isActive: event.data.isActive,
        restTrialDays: event.data.restTrialDays,
      })),
      assignPermission: assign((_, event: AnyEventObject) => ({
        hasDevelopPermission: event.data.development,
        hasAdminPermission: event.data.admin,
      })),
    },
  }
);

const authService = interpret(authMachine);

export const logout = () => {
  authService.send({ type: E.LOGOUT });
};
export { S as AUTH_STATE, E as AUTH_EVENT };

type AuthContextType = [typeof authService.state, typeof authService.send];
const AuthContext = createContext<AuthContextType>([
  authService.state,
  authService.send,
]);

export const AuthProvider = function ({ children }) {
  authService.start();
  const service = useActor(authService) as AuthContextType;
  return (
    <AuthContext.Provider value={service}>{children}</AuthContext.Provider>
  );
};
export const useAuth = function () {
  return useContext(AuthContext);
};
