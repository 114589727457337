import { ComponentType, DragItemType } from '../pages/editor/types';
import * as R from 'ramda';
import { ComponentData } from '../pages/editor/CDContext';
import { POPUP_MAXIMUM } from '../pages/editor/constants';

export type DropResult =
  | {
      result: DROP_ELEMENT_RESULT.SUCCESS;
    }
  | undefined;

export enum DROP_ELEMENT_RESULT {
  SUCCESS = 'SUCCESS',
  NOT_ACCEPTED_BY_IMAGE = 'NOT_ACCEPTED_BY_IMAGE',
  NOT_ACCEPTED_BY_PRODUCT_IMAGE = 'NOT_ACCEPTED_BY_PRODUCT_IMAGE',
  NOT_ACCEPTED_BY_COLLECTION_IMAGE = 'NOT_ACCEPTED_BY_COLLECTION_IMAGE',
  PRODUCT_ELEMENT_OUTSIDE_PRODUCT_BOX = 'PRODUCT_ELEMENT_OUTSIDE_PRODUCT_BOX',
  OVERLAPPED_PRODUCT_BOX = 'OVERLAPPED_PRODUCT_BOX',
  PRODUCT_DETAILS_INCOMPATIBLE_WITH_PRODUCT_BOX = 'PRODUCT_DETAILS_INCOMPATIBLE_WITH_PRODUCT_BOX',
  PRODUCT_BOX_OVERLAP_WITH_COLLECTION_BOX = 'PRODUCT_BOX_OVERLAP_WITH_COLLECTION_BOX',
  COLLECTION_ELEMENT_OUTSIDE_COLLECTION_BOX = 'COLLECTION_ELEMENT_OUTSIDE_COLLECTION_BOX',
  COLLECTION_DETAILS_INCOMPATIBLE_WITH_COLLECTION_BOX = 'COLLECTION_DETAILS_INCOMPATIBLE_WITH_COLLECTION_BOX',
  OVERLAPPED_COLLECTION_BOX = 'OVERLAPPED_COLLECTION_BOX',
  COLLECTION_BOX_OVERLAP_WITH_PRODUCT_BOX = 'COLLECTION_BOX_OVERLAP_WITH_PRODUCT_BOX',
  EXCEED_MAXIMUM_OF_GRIDS_LAYERS = 'EXCEED_MAXIMUM_OF_GRIDS_LAYERS',
  EXCEED_MAXIMUM_OF_POPUP = 'EXCEED_MAXIMUM_OF_POPUP',
  OVERLAPPED_CUSTOMER_FORM = 'OVERLAPPED_CUSTOMER_FORM',
  OVERLAPPED_CONTACT_FORM = 'OVERLAPPED_CONTACT_FORM',
  FORM_ELEMENT_OUTSIDE_FORM_BOX = 'FORM_ELEMENT_OUTSIDE_FORM_BOX',
}

type ValidateDroppingItemProps = {
  item: DragItemType;
  isInProductBox: boolean;
  isInCollectionBox: boolean;
  isInCustomerForm: boolean;
  isInContactForm: boolean;
  rootChildren: ComponentData[];
  validator?: (item: DragItemType) => DROP_ELEMENT_RESULT;
};

export const validateDroppingElement = ({
  item,
  isInProductBox,
  isInCollectionBox,
  isInCustomerForm,
  isInContactForm,
  rootChildren,
  validator,
}: ValidateDroppingItemProps): DROP_ELEMENT_RESULT => {
  if (
    !isInProductBox &&
    item.componentType === ComponentType.shopify_product_component
  ) {
    return DROP_ELEMENT_RESULT.PRODUCT_ELEMENT_OUTSIDE_PRODUCT_BOX;
  }
  if (isInProductBox && item.componentId === 'productBox') {
    return DROP_ELEMENT_RESULT.OVERLAPPED_PRODUCT_BOX;
  }
  if (
    isInProductBox &&
    (item.componentId === 'productDetails' ||
      item.componentId === 'productDefaultDetails')
  ) {
    return DROP_ELEMENT_RESULT.PRODUCT_DETAILS_INCOMPATIBLE_WITH_PRODUCT_BOX;
  }

  if (
    !isInCollectionBox &&
    item.componentType === ComponentType.shopify_collection_component
  ) {
    return DROP_ELEMENT_RESULT.COLLECTION_ELEMENT_OUTSIDE_COLLECTION_BOX;
  }
  if (isInCollectionBox && item.componentId === 'collectionBox') {
    return DROP_ELEMENT_RESULT.OVERLAPPED_COLLECTION_BOX;
  }

  if (
    isInCollectionBox &&
    (item.componentId === 'collectionDetails' ||
      item.componentId === 'collectionDefaultDetails')
  ) {
    return DROP_ELEMENT_RESULT.COLLECTION_DETAILS_INCOMPATIBLE_WITH_COLLECTION_BOX;
  }

  if (isInCollectionBox && item.componentId === 'productBox') {
    return DROP_ELEMENT_RESULT.PRODUCT_BOX_OVERLAP_WITH_COLLECTION_BOX;
  }
  if (isInProductBox && item.componentId === 'collectionBox') {
    return DROP_ELEMENT_RESULT.COLLECTION_BOX_OVERLAP_WITH_PRODUCT_BOX;
  }

  if (item.componentId === 'popup' && reachPopupMaximum(rootChildren)) {
    return DROP_ELEMENT_RESULT.EXCEED_MAXIMUM_OF_POPUP;
  }

  if (isInCustomerForm && item.componentId === 'customerForm') {
    return DROP_ELEMENT_RESULT.OVERLAPPED_CUSTOMER_FORM;
  }
  if (
    !isInCustomerForm &&
    item.componentType === ComponentType.shopify_customer_form_component
  ) {
    return DROP_ELEMENT_RESULT.FORM_ELEMENT_OUTSIDE_FORM_BOX;
  }

  if (isInContactForm && item.componentId === 'contactForm') {
    return DROP_ELEMENT_RESULT.OVERLAPPED_CONTACT_FORM;
  }
  if (
    !isInContactForm &&
    item.componentType === ComponentType.shopify_contact_form_component
  ) {
    return DROP_ELEMENT_RESULT.FORM_ELEMENT_OUTSIDE_FORM_BOX;
  }

  return R.isNil(validator) ? DROP_ELEMENT_RESULT.SUCCESS : validator(item);
};

export const isDropIntoSelf = (
  sourcePath: number[] | null,
  targetPath: number[]
) => {
  if (!sourcePath) return false;
  return targetPath.join(',').startsWith(sourcePath.join(','));
};

export const reachPopupMaximum = (rootChildren: ComponentData[] = []) =>
  R.filter(R.propEq('componentId', 'popup'), rootChildren).length >=
  POPUP_MAXIMUM;
