import { SUBMIT_FAIL_MESSAGE, SUBMIT_SUCCESS_MESSAGE } from '../constants';
import { useContext } from 'react';
import { createContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { DEFAULT_RWD_VALUES } from '../../../constants';

export const CUSTOMER_FORM_DOCUMENT_URL =
  'https://www.layoutbase.com/post/shopify-elements-customer-form';

export type CustomerFormProps = {
  submitSuccessMessage?: string;
  submitFailMessage?: string;
  borderColor?: string[];
};

export const getCustomerFormDefaultProps = <T extends CustomerFormProps>({
  submitSuccessMessage = SUBMIT_SUCCESS_MESSAGE,
  submitFailMessage = SUBMIT_FAIL_MESSAGE,
  borderColor = DEFAULT_RWD_VALUES.borderColor,
  ...restProps
}: T) => ({
  submitSuccessMessage,
  submitFailMessage,
  borderColor,
  ...restProps,
});

const CustomerFormContext = createContext<UseFormReturn | undefined>(undefined);
export const CustomerFormProvider = ({ children }) => {
  const form = useForm({ mode: 'onBlur' });

  return (
    <CustomerFormContext.Provider value={{ ...form }}>
      {children}
    </CustomerFormContext.Provider>
  );
};
export const useCustomerFormContext = () => useContext(CustomerFormContext);
