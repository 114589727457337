import { rgbToColorString } from 'polished';
import { RgbaColor } from './types';

export const TRANSPARENT_COLOR: RgbaColor = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 0,
};
export const TRANSPARENT_COLOR_STRING = rgbToColorString(TRANSPARENT_COLOR);

export const BLACK_COLOR: RgbaColor = {
  red: 0,
  green: 0,
  blue: 0,
  alpha: 1,
};
export const BLACK_COLOR_STRING = '#000000';

export const WHITE_COLOR: RgbaColor = {
  red: 255,
  green: 255,
  blue: 255,
  alpha: 1,
};
export const SHORT_WHITE_COLOR_STRING = '#FFF';
export const WHITE_COLOR_STRING = '#FFFFFF';
export const EDITOR_DEFAULT_COLOR_STRING = '#585858';

export const DEFAULT_FALLBACK_COLOR: RgbaColor = WHITE_COLOR;
export const DEFAULT_FALLBACK_COLOR_STRING = rgbToColorString(
  DEFAULT_FALLBACK_COLOR
);

export const COLOR_HISTORY_MAX_LENGTH = 10;

export const UNAVAILABLE_COLOR = BLACK_COLOR;
