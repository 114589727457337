import { assign, createMachine } from 'xstate';
import * as R from 'ramda';
import { Collection, PageType, Product } from '../../apollo/types.generate';
import { sdk } from '../../apollo/client';

enum S {
  LOADING_ITEMS_COUNT = 'LOADING_ITEMS_COUNT',
  LOADING = 'LOADING',
  TYPING = 'TYPING',
  EMPTY = 'EMPTY',
  LOADED = 'LOADED',
}

enum E {
  SEARCH = 'SEARCH',
}

type ShopifyItemListContext = {
  type?: PageType.Product | PageType.Collection;
  keyword?: string;
  itemsCount?: number;
  items: Array<Product> | Array<Collection>;
  item?: Product | Collection;
};

const shopifyItemListMachine = createMachine<ShopifyItemListContext>(
  {
    initial: S.LOADING_ITEMS_COUNT,
    states: {
      [S.LOADING_ITEMS_COUNT]: {
        invoke: {
          src: 'getShopifyItemsCount',
          onDone: {
            actions: ['assignItemsCount'],
            target: S.LOADING,
          },
        },
      },
      [S.LOADING]: {
        invoke: {
          src: 'getShopifyItems',
          onDone: [
            {
              cond: 'isEmpty',
              actions: ['assignShopifyItems'],
              target: S.EMPTY,
            },
            {
              actions: ['assignShopifyItems'],
              target: S.LOADED,
            },
          ],
        },
      },
      [S.TYPING]: {
        after: {
          300: S.LOADING,
        },
        on: {
          [E.SEARCH]: {
            actions: ['assignKeyword'],
            target: S.TYPING,
          },
        },
      },
      [S.LOADED]: {},
      [S.EMPTY]: {},
    },
    on: {
      [E.SEARCH]: {
        actions: ['assignKeyword'],
        target: `.${S.TYPING}`,
      },
    },
  },
  {
    guards: {
      isEmpty: (ctx, event) => R.isEmpty(event.data),
    },
    actions: {
      assignItemsCount: assign({
        itemsCount: (ctx, event) => R.pathOr(0, ['data'])(event),
      }),
      assignKeyword: assign({
        keyword: (ctx, event) =>
          R.pathOr(undefined, ['data', 'keyword'])(event),
      }),
      assignShopifyItems: assign({
        items: (ctx, event) => R.pathOr([], ['data'])(event),
      }),
    },
    services: {
      getShopifyItemsCount: (ctx) => {
        if (ctx.type === PageType.Product) {
          return sdk
            .shopifyProductsCount({}, { fetchPolicy: 'network-only' })
            .then((res) => res.shopifyProductsCount);
        }
        return sdk
          .shopifyCollectionsCount({}, { fetchPolicy: 'network-only' })
          .then((res) => res.shopifyCollectionsCount);
      },
      getShopifyItems: (ctx) => {
        if (ctx.type === PageType.Product) {
          return sdk
            .shopifyProducts(
              {
                input: {
                  keyword: ctx.keyword || '',
                },
              },
              { fetchPolicy: 'network-only' }
            )
            .then((res) => res.shopifyProducts);
        }
        return sdk
          .shopifyCollections({
            input: { keyword: ctx.keyword || '' },
          })
          .then((res) => res.shopifyCollections);
      },
    },
  }
);
export {
  shopifyItemListMachine,
  S as SHOPIFY_ITEM_LIST_STATE,
  E as SHOPIFY_ITEM_LIST_EVENT,
};
